/**
 * @see https://www.hl7.org/documentcenter/public/standards/vocabulary/vocabulary_tables/infrastructure/vocabulary/ReligiousAffiliation.html
 */
export const Religions: string[] = [
  'Agnostic',
  'Atheist',
  'Baptist',
  'Buddhist',
  'Catholic',
  'Christian',
  'Hindu',
  'Islamic',
  'Jewish',
  'Mormon',
  'No Religion',
  'Protestant',
  'Scientologist',
  'Sikhism',
  "Jehovah's Witness",
]

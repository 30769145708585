import { CommonModule, registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import localeAr from '@angular/common/locales/ar'
import localeEn from '@angular/common/locales/en'
import localeEs from '@angular/common/locales/es'
import localePl from '@angular/common/locales/pl'
import localeRu from '@angular/common/locales/ru'
import localeZhHK from '@angular/common/locales/zh-Hant-HK'
import { ErrorHandler, NgModule } from '@angular/core'
import { sentryErrorExtractor } from '@mmx/shared'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import * as Sentry from '@sentry/angular-ivy'
import { buildenv, environment } from 'environments'

import { MaterialModule } from '../shared/modules/material.module'
import { AuthGuard, ClinicExistsGuard, PermissionsGuard } from './guards'
import { AuthInterceptor } from './services/auth-interceptor.service'
import { LoadingInterceptor } from './services/loading.interceptor'
import { DynamoPaginationInterceptor } from './services/pagination-cache/dynamo-pagination.interceptor'

// const thirdPartyModules = [
//   CommonModule, // allows *ngIf and similar Angular utilities
//   FormsModule, // allows binding of [(ngModel)]
//   ReactiveFormsModule, // allows binding of [formGroup]
//   FlexLayoutModule, // allows fxLayout and fxFlex binding
// ]
if (environment.production) {
  Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.env,
    release: buildenv.release,
    ignoreErrors: [
      '401 Unauthorized',
      'Non-Error exception captured',
    ],
    integrations: [
      new Sentry.Replay(),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}

registerLocaleData(localeEn, 'en')
registerLocaleData(localeEs, 'es')
registerLocaleData(localePl, 'pl')
registerLocaleData(localeRu, 'ru')
registerLocaleData(localeAr, 'ar')
registerLocaleData(localeZhHK, 'zh-hk')

/**
 * The CoreModule should contain singleton services and other
 * features where there’s only once instance per application.
 */
@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json')
        },
        deps: [HttpClient],
      },
    }),
    MaterialModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DynamoPaginationInterceptor, multi: true },
    ClinicExistsGuard,
    AuthGuard,
    PermissionsGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        extractor: sentryErrorExtractor,
      }),
    },
  ],
})
export class CoreModule {}

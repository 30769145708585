import { isNil, isObject } from 'lodash'

export const truthyValues = (obj: Record<string, any>): Record<string, any> => {
  if (Array.isArray(obj)) {
    return obj.filter(Boolean)
  }

  if (!isObject(obj)) {
    return obj
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (isNil(value)) {
      return acc
    }
    const isObjectOrArray = isObject(value) || Array.isArray(value)

    return {
      ...acc,
      [key]: isObjectOrArray ? truthyValues(value) : value,
    }
  }, {})
}

import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { StripeElementLocale } from '@stripe/stripe-js/types/stripe-js/elements-group'
import { uniq } from 'lodash'
import * as moment from 'moment'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { distinctUntilKeyChanged, filter, take } from 'rxjs/operators'

import { Clinic } from '../models'
import { ClinicService } from './clinic.service'
import { TranslationService } from './translation.service'

const LANGUAGE_STORAGE_CODE = 'ppc-language'

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  public onLocaleChange: Observable<StripeElementLocale>
  public availableLangs: string[] = ['en']
  public currentLang: StripeElementLocale
  public localeSubject: BehaviorSubject<StripeElementLocale> = new BehaviorSubject('en')

  private loadedTranslations: { [key: string]: boolean } = {}
  private clinic: Clinic

  get languageCode() {
    return localStorage.getItem(LANGUAGE_STORAGE_CODE) as StripeElementLocale
  }

  set languageCode(value: StripeElementLocale) {
    localStorage.setItem(LANGUAGE_STORAGE_CODE, value)
  }

  constructor(
    private clinicService: ClinicService,
    private translationService: TranslationService,
    private translateService: TranslateService,
  ) {
    this.onLocaleChange = this.localeSubject.asObservable()

    this.clinicService.clinic$.pipe(
      distinctUntilKeyChanged('id'),
      filter(Boolean),
    ).subscribe((clinic) => {
      this.clinic = clinic
      this.onClinicChange()
    })
  }

  setLang(lang: StripeElementLocale) {
    const setLang = () => {
      this.currentLang = lang
      this.translateService.use(lang)
      // this.translateService.setDefaultLang(this.currentLang)
      this.languageCode = lang
      moment.locale(lang)
      this.localeSubject.next(lang)
    }

    if (this.loadedTranslations[lang]) {
      setLang()
    } else {
      combineLatest([
        this.translationService.getTranslations(lang),
        this.translateService.getTranslation(lang).pipe(take(1)),
      ]).subscribe(([clinicTranslations, staticTranslations]) => {
        this.translateService.setTranslation(
          lang,
          {
            ...staticTranslations,
            ...clinicTranslations.data,
          },
          true,
        )
        this.loadedTranslations[lang] = true
        setLang()
      })
    }
  }

  private onClinicChange() {
    this.setDefaultLanguage()
    this.setLang(this.currentLang)
  }

  private setDefaultLanguage() {
    if (this.clinic.languages?.length > 1) {
      this.availableLangs = uniq(
        this.availableLangs.concat(this.clinic.languages),
      )
    }

    if (this.languageCode) {
      // if we have a preferred language set on local storage, use that
      this.currentLang = this.languageCode
    } else if (
      navigator.language !== 'en' &&
      this.availableLangs.includes(navigator.language)
    ) {
      // if the device's language is set to a language the clinic supports, use that
      this.currentLang = navigator.language as StripeElementLocale
    } else {
      // fallback to English as the language
      this.currentLang = 'en'
    }
    this.languageCode = this.currentLang
  }
}

import { COLOR } from '../data'
import { Appointment } from '../models'

export function eligibilityDisplayStatus(status: Appointment.ELIGIBILITY_STATUS): string {
  switch (status) {
    case Appointment.ELIGIBILITY_STATUS.ACTIVE:
      return 'appointment.eligibility-status.active'
    case Appointment.ELIGIBILITY_STATUS.INVALID:
      return 'appointment.eligibility-status.invalid'
    case Appointment.ELIGIBILITY_STATUS.AVOIDED:
      return 'appointment.eligibility-status.avoided'
    case Appointment.ELIGIBILITY_STATUS.SOFT_FAIL:
      return 'appointment.eligibility-status.soft-fail'
    case Appointment.ELIGIBILITY_STATUS.HARD_FAIL:
      return 'appointment.eligibility-status.hard-fail'
    case Appointment.ELIGIBILITY_STATUS.INACTIVE:
      return 'appointment.eligibility-status.inactive'
    default:
      return 'common.none'
  }
}

export function getEligibilityStatusText(status: Appointment.ELIGIBILITY_STATUS): string {
  switch (status) {
    case Appointment.ELIGIBILITY_STATUS.NONE:
      return 'appointment.eligibility-status-text.none'
    case Appointment.ELIGIBILITY_STATUS.HARD_FAIL:
      return 'appointment.eligibility-status-text.hard-fail'
    case Appointment.ELIGIBILITY_STATUS.SOFT_FAIL:
      return 'appointment.eligibility-status-text.soft-fail'
    case Appointment.ELIGIBILITY_STATUS.INVALID:
      return 'appointment.eligibility-status-text.invalid'
    case Appointment.ELIGIBILITY_STATUS.INACTIVE:
      return 'appointment.eligibility-status-text.inactive'
    case Appointment.ELIGIBILITY_STATUS.ACTIVE:
      return 'appointment.eligibility-status-text.active'
    case Appointment.ELIGIBILITY_STATUS.AVOIDED:
      return 'appointment.eligibility-status-text.avoided'
    default:
      return 'appointment.eligibility-status-text.default'
  }
}

export function eligibilityStatusColor(status: Appointment.ELIGIBILITY_STATUS): string {
  switch (status) {
    case Appointment.ELIGIBILITY_STATUS.NONE:
    case Appointment.ELIGIBILITY_STATUS.HARD_FAIL:
    case Appointment.ELIGIBILITY_STATUS.SOFT_FAIL:
      return COLOR.DEFAULT
    case Appointment.ELIGIBILITY_STATUS.INVALID:
      return COLOR.RED
    case Appointment.ELIGIBILITY_STATUS.INACTIVE:
      return COLOR.YELLOW
    case Appointment.ELIGIBILITY_STATUS.ACTIVE:
    case Appointment.ELIGIBILITY_STATUS.AVOIDED:
      return COLOR.GREEN
    default:
      return COLOR.RED
  }
}


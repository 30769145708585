import { INSURANCE_PROVIDER_LOOKUP_TYPE, SelectOption } from '@mmx/shared'

export const INSURANCE_TYPES: SelectOption<INSURANCE_PROVIDER_LOOKUP_TYPE>[] = [
  {
    value: INSURANCE_PROVIDER_LOOKUP_TYPE.MEDICAL_INSURANCE,
    viewValue: 'Medical Insurance',
  },
  {
    value: INSURANCE_PROVIDER_LOOKUP_TYPE.DENTAL_INSURANCE,
    viewValue: 'Dental Insurance',
  },
  { value: INSURANCE_PROVIDER_LOOKUP_TYPE.SELF_PAY, viewValue: 'Self Pay' },
  { value: INSURANCE_PROVIDER_LOOKUP_TYPE.LIEN, viewValue: 'Lien / Attorney' },
  {
    value: INSURANCE_PROVIDER_LOOKUP_TYPE.AUTO_INSURANCE,
    viewValue: 'Auto Insurance',
  },
  {
    value: INSURANCE_PROVIDER_LOOKUP_TYPE.WORKERS_COMP_INSURANCE,
    viewValue: `Workers' Compensation Insurance`,
  },
  { value: INSURANCE_PROVIDER_LOOKUP_TYPE.RESEARCH, viewValue: `Research` },
]

import {
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { toData, truthyValues } from '@mmx/shared'
import {
  BehaviorSubject,
  catchError,
  concatMap,
  exhaustMap,
  filter,
  finalize,
  map,
  of,
  ReplaySubject,
  share, withLatestFrom,
} from 'rxjs'

import { AdvancedTableApiDeletionService } from '../../advanced-table/advanced-table-api-service'
import {
  ProductModel,
  ShoppableServiceModel,
  ShoppableServiceProductsResponse,
} from '../models'
import { AuthenticatedRestService } from './auth-rest.service'

@Injectable()
export class ShoppableServicesService
  extends AuthenticatedRestService
  implements AdvancedTableApiDeletionService<ShoppableServiceModel>
{
  route = '/shoppable-services'
  collectionParams = new ReplaySubject<Record<string, any>>(1)
  updateParams = new ReplaySubject<Record<string, any>>(1)
  deleteParams = new ReplaySubject<string>(1)
  productsParams = new ReplaySubject<string | undefined>(1)
  bust$ = new BehaviorSubject(false)

  private header$ = this.bust$.pipe(
    map((bust) => (bust ? new HttpHeaders({ 'x-refresh': 'true' }) : null)),
  )

  collectionResponse$ = this.collectionParams.pipe(
    map(truthyValues),
    map((object) => new HttpParams({ fromObject: object })),
    withLatestFrom(this.header$),
    concatMap(([params, headers]) =>
      this.get<ShoppableServiceModel[]>(this.route, params, { headers }).pipe(
        catchError((error: HttpErrorResponse) => of(error)),
        finalize(() => this.bust$.next(false)),
      ),
    ),
    share(),
  )

  updateResponse$ = this.updateParams.pipe(
    map(truthyValues),
    exhaustMap((params) =>
      this.putOrPost(params).pipe(
        catchError((error: HttpErrorResponse) => of(error)),
        finalize(() => this.bust$.next(true)),
      ),
    ),
    share(),
  )

  deleteResponse$ = this.deleteParams.pipe(
    filter((id) => !!id),
    exhaustMap((id) =>
      this.delete<ShoppableServiceModel>(`${this.route}/${id}`).pipe(
        catchError((error: HttpErrorResponse) => of(error)),
        finalize(() => this.bust$.next(true)),
      ),
    ),
    share(),
  )

  productsResponse$ = this.productsParams.pipe(
    concatMap((id) => {
      const params = !!id && new HttpParams({ fromObject: { id } })

      return this.get<ShoppableServiceProductsResponse[]>(`${this.route}/products`, params).pipe(
        catchError((error: HttpErrorResponse) => of(error)),
      )
    }),
    map(toData),
    map((data) => data.map(({ product, shoppableService }) => ({
      product: new ProductModel(product),
      shoppableService,
    }))),
    share(),
  )

  private putOrPost = (params: Record<string, any>) => {
    if (params.id) {
      return this.put<ShoppableServiceModel>(`${this.route}/${params.id}`, params)
    } else {
      return this.post<ShoppableServiceModel>(this.route, params)
    }
  }
}

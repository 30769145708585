import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  Clinician,
  ClinicianData,
  Facility,
  FacilityData,
  PagedRestResponse,
  RestResponse,
  RestService,
  SelectOption,
} from '@mmx/shared'
import { IPayout } from 'app/shared'
import { environment } from 'environments/environment'
import { isEmpty, map as _map, orderBy, sortBy } from 'lodash'
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs'
import { catchError, filter, map, shareReplay, switchMap } from 'rxjs/operators'

import { AppointmentStatuses } from '../data'
import { NOT_FOUND } from '../data/http-status-codes'
import {
  Appointment,
  Clinic,
  ClinicData,
  ClinicPaymentProvider,
  IntegrationLog,
  ProductData,
  ProductModel,
} from '../models'

type StatusMap = SelectOption<Appointment.STATUS | string>[]

@Injectable({
  providedIn: 'root',
})
export class ClinicService {
  static clinic: Clinic
  static clinicId: string

  get clinic() {
    return ClinicService.clinic
  }

  get clinicId() {
    if (ClinicService.clinic) {
      return ClinicService.clinic.id
    } else {
      return localStorage.getItem('ppal-last-clinic')
    }
  }

  private clinicSlug$ = new BehaviorSubject<string>(null)

  refresh$ = new Subject<void>()

  clinicId$ = new BehaviorSubject<string>(null)
  clinic$: Observable<Clinic | null> = this.clinicSlug$.pipe(
    filter(Boolean),
    switchMap(slug => this.restService.get<ClinicData>(`/clinics/${slug}`)),
    map(result => {
      ClinicService.clinic = new Clinic(result.data)
      ClinicService.clinicId = result.data.id
      localStorage.setItem('ppal-last-clinic', ClinicService.clinic.id)
      this.clinicId$.next(ClinicService.clinicId)
      return ClinicService.clinic
    }),
    catchError((err: HttpErrorResponse) => {
      // if the clinic request returned a 404, then show an error to the user
      if (err.status === NOT_FOUND) {
        window.location.href = 'https://www.patientpal.com/login.html?clinic-not-found=1'
        return of(null)
      } else {
        return throwError(() => err)
      }
    }),
    shareReplay(1),
  )

  private cache: {
    facilities: Facility[] | null
    userFacilities: Facility[] | null
  } = {
      facilities: null,
      userFacilities: null,
    }

  constructor(protected restService: RestService) {
    let idOrSlug = environment['clinicId']

    if (localStorage.getItem('ppal-last-clinic')) {
      idOrSlug = localStorage.getItem('ppal-last-clinic')
      ClinicService.clinicId = idOrSlug
      this.clinicId$.next(idOrSlug)
    }

    if (!idOrSlug) {
      const host = location.hostname
      const slug = host.split('.')[0]
      const blacklistedHostNames = ['console', 'console-staging']

      if (!blacklistedHostNames.includes(slug)) {
        idOrSlug = host
      }
    }

    this.clinicSlug$.next(idOrSlug)
  }

  /**
   * Appointment statuses for the dropdown filter
   * If a clinic has a custom status map, uses that instead
   */
  appointmentStatuses$: Observable<StatusMap> = this.clinic$.pipe(
    map(clinic => {
      if (isEmpty(clinic.settings?.emrStatusMap)) {
        return AppointmentStatuses
      } else {
        return orderBy(Object.keys(clinic.settings.emrStatusMap).map((status) => ({
          value: status,
          viewValue: status,
        })), 'viewValue')
      }
    }),
  )

  getPaymentProvider(): Observable<ClinicPaymentProvider | undefined> {
    const route = `/clinics/${this.clinicId}/payment-provider`
    return this.restService.get<ClinicPaymentProvider>(route).pipe(
      map(response => response.data),
      catchError((err: HttpErrorResponse) => {
        // just return void if we get a 404, this means there is no payment provider setup for the clinic yet
        if (err.status === NOT_FOUND) {
          return of()
        } else {
          return throwError(() => err)
        }
      }),
    )
  }

  updatePaymentProvider(paymentProvider: ClinicPaymentProvider) {
    return this.restService.patch<void>(
      `/clinics/${this.clinicId}/payment-provider`,
      paymentProvider,
    )
  }

  getClinicians(): Observable<Clinician[]> {
    const route = '/clinicians'
    return this.restService.get<ClinicianData[]>(route).pipe(
      map((response) => {
        const clinicians: Clinician[] = _map(
          response.data,
          (clinicianData) => new Clinician(clinicianData),
        )
        return clinicians
      }),
    )
  }

  getProducts(input?: {
    prices?: boolean
    ids?: string | string[]
    carrierLookup?: string
  }): Observable<ProductModel[]> {
    const route = `/clinics/${this.clinicId}/products`
    const params = this.restService.convertToParams(input)
    return this.restService.get<ProductData[]>(route, params).pipe(
      map((resp) => {
        if (resp.success) {
          return sortBy(
            _map(resp.data, (productData) => new ProductModel(productData)),
            'name',
          )
        } else {
          return []
        }
      }),
    )
  }

  getIntegrationLogs(
    input: any,
  ): Observable<PagedRestResponse<IntegrationLog[]>> {
    const route = `/integration/logs`
    const params = this.restService.convertToParams(input)
    return this.restService.get<any[]>(route, params)
  }

  getIntegrationQueue(input: {
    limit?: number
    startAt?: string
  }): Observable<RestResponse<any>> {
    const route = `/integration/queue`
    const params = this.restService.convertToParams(input)
    return this.restService.get<any[]>(route, params)
  }

  getLocations(
    input?: {
      visibleForClinician?: boolean
      includeDisabled?: boolean
    },
    bustCache = false,
  ): Observable<Facility[]> {
    if (
      input?.visibleForClinician &&
      this.cache.userFacilities != null &&
      !bustCache
    ) {
      return of(this.cache.userFacilities)
    } else if (
      !input?.visibleForClinician &&
      this.cache.facilities != null &&
      !bustCache
    ) {
      return of(this.cache.facilities)
    }
    const route = `/clinics/${this.clinicId}/locations`
    const params = this.restService.convertToParams(input)
    return this.restService.get<FacilityData[]>(route, params).pipe(
      map((response) => {
        const locations = _map(
          response.data,
          (location) => new Facility(location),
        )
        if (input?.visibleForClinician) {
          this.cache.userFacilities = locations
        } else {
          this.cache.facilities = locations
        }
        return locations
      }),
    )
  }

  getMetadataFields(): Observable<string[]> {
    const route = `/clinics/${this.clinicId}/metadata-fields`
    return this.restService.get<string[]>(route).pipe(map((resp) => resp.data))
  }

  getRecordLabels(): Observable<Record<string, string>> {
    const route = `/clinics/${this.clinicId}/record-labels`
    return this.restService.get<Record<string, string>>(route).pipe(map((resp) => resp.data))
  }

  getPayouts(input?: { arrivalDate: string }) {
    const route = `/payments/payouts`
    const params = this.restService.convertToParams(input)
    return this.restService.get<IPayout[]>(route, params)
  }
}

import { Injectable, isDevMode } from '@angular/core'
import { Clinician } from '@mmx/shared'
import { BehaviorSubject, map, Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private readonly adminEmails = [
    '@patientpal.com',
    '@med-metrix.com',
  ]

  private user$ = new BehaviorSubject<Clinician | null>(null)
  private permissions$ = new BehaviorSubject<string[]>([])

  public permissionsChanges$ = this.permissions$.asObservable()

  public isMMXEmployee$ = this.user$.pipe(
    map(() => this.isMMXEmployee()),
  )

  public setPermissions(permissions: string[]): PermissionsService {
    this.permissions$.next(permissions)

    return this
  }

  public setUser(user: Clinician): PermissionsService {
    this.user$.next(user)

    return this
  }

  public hasPermission(permission: string | string[], operator: 'AND' | 'IN' | 'OR' = 'IN'): Observable<boolean> {
    const permissions = Array.isArray(permission) ? permission : [permission]

    // if no permissions were requested
    if (!permissions || permissions.length === 0) {
      return of(true)
    }

    return this.permissions$
      .pipe(
        map((allPermissions) => {
          if (operator === 'AND') {
            return permissions.every((p) => allPermissions.includes(p))
          } else {
            return permissions.some((p) => allPermissions.includes(p))
          }
        }),
      )
  }

  public instant(permission: string): boolean {
    return this.permissions$.getValue().includes(permission)
  }

  public getAllPermissions(): string[] {
    return this.permissions$.getValue()
  }

  /**
   * Is the user a Med-Metrix employee
   */
  public isMMXEmployee(): boolean {
    const clinician = this.user$.getValue()

    if (isDevMode()) {
      return true
    }

    if (!clinician) {
      return false
    }

    return this.adminEmails.some((email) => clinician.email.endsWith(email))
  }
}

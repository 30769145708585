import { UntypedFormGroup } from '@angular/forms'
import { filter, pipe } from 'rxjs'
import { withLatestFrom } from 'rxjs/operators'

export function mapToValidFormValues(form: UntypedFormGroup) {
  return pipe(
    withLatestFrom(form.statusChanges, (_, status) => status),
    filter(status => status === 'VALID'),
    withLatestFrom(form.valueChanges, (_, params) => params),
  )
}

import { SelectOption } from '@mmx/shared'

/**
 * @see http://hl7.org/fhir/v2/0344
 */

export const InsuranceIsActive: SelectOption<string>[] = [
  { value: 'copy', viewValue: 'Yes' },
  { value: 'new', viewValue: 'No, I have new insurance' },
  { value: 'no', viewValue: 'No, I don’t have insurance' },
]

export const InsuranceRelationship: SelectOption<string>[] = [
  // { value: '01', viewValue: 'Patient is insured' },
  { value: '02', viewValue: 'Spouse' },
  { value: '03', viewValue: 'Child' },
  // { value: '03', viewValue: 'Natural child / insured financial responsibility' },
  // { value: '04', viewValue: 'Natural child / Insured does not have financial responsibility' },
  // { value: '05', viewValue: 'Step child' },
  // { value: '06', viewValue: 'Foster child' },
  { value: '07', viewValue: 'Ward of the court' },
  // { value: '08', viewValue: 'Employee' },
  // { value: '09', viewValue: 'Unknown' },
  // { value: '10', viewValue: 'Handicapped dependent' },
  // { value: '11', viewValue: 'Organ donor' },
  // { value: '12', viewValue: 'Cadaver donor' },
  { value: '13', viewValue: 'Grandchild' },
  // { value: '14', viewValue: 'Niece / nephew' },
  { value: '15', viewValue: 'Injured plaintiff' },
  // { value: '16', viewValue: 'Sponsored dependent' },
  // { value: '17', viewValue: 'Minor dependent of a minor dependent' },
  { value: '18', viewValue: 'Parent' },
  { value: '19', viewValue: 'Grandparent' },
]

/**
 * @see http://hl7-definition.caristix.com:9010/Default.aspx?version=HL7%20v2.5.1&table=0063
 */

export const GuarantorRelationships: SelectOption<string>[] = [
  // { value: 'SEL', viewValue: 'Self' },
  { value: 'MTH', viewValue: 'Mother' },
  { value: 'FTH', viewValue: 'Father' },
  { value: 'GRD', viewValue: 'Guardian' },
  { value: 'SPO', viewValue: 'Spouse' },
  { value: 'DOM', viewValue: 'Life partner' },
  { value: 'CHD', viewValue: 'Child' },
  { value: 'GCH', viewValue: 'Grandchild' },
  { value: 'NCH', viewValue: 'Natural child' },
  { value: 'SCH', viewValue: 'Stepchild' },
  { value: 'FCH', viewValue: 'Foster child' },
  { value: 'DEP', viewValue: 'Handicapped dependent' },
  { value: 'WRD', viewValue: 'Ward of court' },
  { value: 'PAR', viewValue: 'Parent' },
  { value: 'CGV', viewValue: 'Care giver' },
  { value: 'GRP', viewValue: 'Grandparent' },
  { value: 'EXF', viewValue: 'Extended family' },
  { value: 'SIB', viewValue: 'Sibling' },
  { value: 'BRO', viewValue: 'Brother' },
  { value: 'SIS', viewValue: 'Sister' },
  { value: 'FND', viewValue: 'Friend' },
  // { value: 'OAD', viewValue: 'Other adult' },
  { value: 'EME', viewValue: 'Employee' },
  { value: 'EMR', viewValue: 'Employer' },
  { value: 'ASC', viewValue: 'Associate' },
  // { value: 'EMC', viewValue: 'Emergency contact' },
  // { value: 'OWN', viewValue: 'Owner' },
  { value: 'TRA', viewValue: 'Trainer' },
  { value: 'MGR', viewValue: 'Manager' },
  // { value: 'NON', viewValue: 'None' },
  // { value: 'UNK', viewValue: 'Unknown' },
  { value: 'OTH', viewValue: 'Other' },
]

import { AbstractControl, ValidatorFn } from '@angular/forms'
import { includes, isObject } from 'lodash'

export function oneOfValidator(options: Array<string | { value: string }>): ValidatorFn {
  const optionValues = options.map((option) => typeof option === 'string' ? option : option.value)
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (isObject(control.value)) {
      const obj: any = control.value
      return includes(optionValues, obj.value)
        ? null
        : { oneOf: { value: obj.value } }
    } else {
      return includes(optionValues, control.value)
        ? null
        : { oneOf: { value: control.value } }
    }
  }
}

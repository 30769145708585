import { MessageTemplateModel, Workflow } from '../models'

export interface TemplateToken {
  value: string
  label: string
}

interface IEventTemplate {
  type: Workflow.TYPE
  data?: MessageTemplateModel
  tokens: TemplateToken[]
}

export const tokens = {
  affirmativeConfirmLink: {
    value: 'shortAffirmativeConfirmLink',
    label: 'Affirmative confirmation link',
  },
  appointmentDate: { value: 'calendarDate', label: 'Appointment date (only)' },
  appointmentDateTime: {
    value: 'dateTime',
    label: 'Appointment date and time',
  },
  appointmentLink: { value: 'shortIntakeLink', label: 'Intake link' },
  scheduledProcedures: { value: 'procedures', label: 'Scheduled procedures' },
  scheduledProceduresPrices: { value: 'procedurePrices', label: 'Scheduled procedures Prices' },
  scheduledProcedureCodes: {
    value: 'procedureCodes',
    label: 'Procedure codes',
  },
  clinicName: { value: 'clinicName', label: 'Business name' },
  clinicPhone: { value: 'clinicPhone', label: 'Business main phone' },
  confirmLink: { value: 'confirmLink', label: 'Confirmation link' },
  facilityAddress: { value: 'facilityAddress', label: 'Facility address' },
  facilityName: { value: 'facilityName', label: 'Facility name' },
  facilityPhone: { value: 'facilityPhone', label: 'Facility phone' },
  intakeLink: { value: 'shortIntakeLink', label: 'Intake link' },
  invoiceAmountRemaining: {
    value: 'remainingAmount',
    label: 'Remaining amount',
  },
  invoiceLineItems: { value: 'lineItems', label: 'Invoice line items' },
  invoiceLink: { value: 'shortLink', label: 'Invoice link' }, // todo get rid of shortLink
  invoiceTotal: { value: 'totalAmount', label: 'Total billed amount' },
  patientGivenName: { value: 'patientGivenName', label: 'Patient given name' },
  patientNickName: { value: 'patientNickName', label: 'Patient preferred name' },
  patientFamilyName: {
    value: 'patientFamilyName',
    label: 'Patient family name',
  },
  patientFullName: { value: 'patientFullName', label: 'Patient full name' },
  patientVerifyCode: { value: 'code', label: 'Patient verification code' },
  negativeConfirmLink: {
    value: 'shortNegativeConfirmLink',
    label: 'Negative confirmation link',
  },
  scheduleLink: { value: 'shortScheduleLink', label: 'Schedule link' },
  waitlistLink: { value: 'shortWaitlistLink', label: 'Wait List link' },
  servicePhone: { value: 'servicePhone', label: 'Service phone' },
  resultsLink: { value: 'shortLink', label: 'Results link' }, // todo get rid of shortLink
  facilityAddressLink: {
    value: 'facilityAddressLink',
    label: 'Directions link',
  },
  surveyLink: { value: 'shortLink', label: 'Survey link' }, // todo get rid of shortLink
  transactionLink: { value: 'shortTransactionLink', label: 'Transaction Link' },
}

export const internalTokens: TemplateToken[] = [
  tokens.clinicName,
  tokens.clinicPhone,
  tokens.facilityName,
  tokens.facilityAddress,
  tokens.facilityAddressLink,
  tokens.facilityPhone,
  tokens.servicePhone,
]

export const genericTokens: TemplateToken[] = [
  ...internalTokens,
  tokens.patientGivenName,
  tokens.patientFamilyName,
  tokens.patientFullName,
]

export const appointmentTokens: TemplateToken[] = [
  tokens.appointmentDate,
  tokens.appointmentDateTime,
  tokens.scheduledProcedures,
  tokens.scheduledProcedureCodes,
  tokens.scheduleLink,
  tokens.waitlistLink,
]

export const orderTokens: TemplateToken[] = [
  tokens.scheduledProcedures,
  tokens.scheduledProcedureCodes,
  tokens.scheduleLink,
]

export const intakeTokens: TemplateToken[] = [
  ...appointmentTokens,
  tokens.scheduledProceduresPrices,
]

const invoiceTokens: TemplateToken[] = [
  tokens.appointmentDate,
  tokens.appointmentDateTime,
  tokens.invoiceLink,
  tokens.invoiceTotal,
  tokens.invoiceAmountRemaining,
  tokens.invoiceLineItems,
]

const surveyTokens: TemplateToken[] = [
  tokens.surveyLink,
]

const waitListTokens: TemplateToken[] = [
  tokens.scheduleLink,
]

const transactionTokens: TemplateToken[] = [
  tokens.transactionLink,
]

export const eventTemplates: IEventTemplate[] = [
  {
    type: Workflow.TYPE.APPOINTMENT_CONFIRMATION,
    tokens: [
      ...genericTokens,
      ...appointmentTokens,
      tokens.confirmLink,
      tokens.affirmativeConfirmLink,
      tokens.negativeConfirmLink,
    ],
  },
  {
    type: Workflow.TYPE.APPOINTMENT_RESCHEDULED,
    tokens: [...genericTokens, ...appointmentTokens, tokens.appointmentLink],
  },
  {
    type: Workflow.TYPE.APPOINTMENT_COMPLETED,
    tokens: [...genericTokens, ...appointmentTokens, tokens.appointmentLink],
  },
  {
    type: Workflow.TYPE.APPOINTMENT_CANCELLED,
    tokens: [...genericTokens, ...appointmentTokens, tokens.appointmentLink],
  },
  {
    type: Workflow.TYPE.APPOINTMENT_NEW,
    tokens: [...genericTokens, ...appointmentTokens, tokens.appointmentLink],
  },
  {
    type: Workflow.TYPE.APPOINTMENT_REMINDER,
    tokens: [...genericTokens, ...appointmentTokens, tokens.appointmentLink],
  },
  {
    type: Workflow.TYPE.APPOINTMENT_NEEDS_RESCHEDULE,
    tokens: [...genericTokens, ...appointmentTokens],
  },
  {
    type: Workflow.TYPE.INTAKE_REMINDER,
    tokens: [...genericTokens, ...appointmentTokens, tokens.intakeLink],
  },
  {
    type: Workflow.TYPE.INTAKE_CONFIRMED,
    tokens: [...genericTokens, ...appointmentTokens],
  },
  {
    type: Workflow.TYPE.INVOICE_CONFIRMED,
    tokens: [...genericTokens, ...invoiceTokens],
  },
  {
    type: Workflow.TYPE.INVOICE_REMINDER,
    tokens: [...genericTokens, ...invoiceTokens],
  },
  {
    type: Workflow.TYPE.INVOICE_PAST_DUE,
    tokens: [...genericTokens, ...invoiceTokens],
  },
  {
    type: Workflow.TYPE.INVOICE_PAYMENT,
    tokens: [...genericTokens, ...invoiceTokens],
  },
  {
    type: Workflow.TYPE.INVOICE_PAID,
    tokens: [...genericTokens, ...invoiceTokens],
  },
  {
    type: Workflow.TYPE.APPOINTMENT_REQUEST_CREATED,
    tokens: [...genericTokens, tokens.scheduleLink],
  },
  {
    type: Workflow.TYPE.APPOINTMENT_REQUEST_REMINDER,
    tokens: [...genericTokens, tokens.scheduleLink],
  },
  {
    type: Workflow.TYPE.APPOINTMENT_REQUEST_SUBMITTED,
    tokens: [...genericTokens, tokens.scheduleLink],
  },
  {
    type: Workflow.TYPE.RESULTS_AVAILABLE,
    tokens: [...genericTokens, tokens.resultsLink],
  },
  {
    type: Workflow.TYPE.POST_APPOINTMENT_SURVEY,
    tokens: [...genericTokens, ...surveyTokens],
  },
  {
    type: Workflow.TYPE.TRANSACTION_CREATED,
    tokens: [...genericTokens, ...transactionTokens],
  },
  {
    type: Workflow.TYPE.WAIT_LIST_REACH_OUT,
    tokens: [...genericTokens, ...waitListTokens],
  },
  {
    type: Workflow.TYPE.ORDER_CREATED,
    tokens: [...genericTokens, ...orderTokens],
  },
]

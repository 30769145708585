import { COLOR } from '../data'
import { Appointment } from '../models'

export function intakeDisplayStatus(status: Appointment.INTAKE_STATUS): string {
  switch (status) {
    case Appointment.INTAKE_STATUS.PENDING:
      return 'common.pending'
    case Appointment.INTAKE_STATUS.DELIVERED:
      return 'common.delivered'
    case Appointment.INTAKE_STATUS.SUBMITTED:
      return 'common.submitted'
    case Appointment.INTAKE_STATUS.CONFIRMED:
      return 'common.confirmed'
    case Appointment.INTAKE_STATUS.TECH_CONFIRMED:
      return 'common.tech-confirmed'
    case Appointment.INTAKE_STATUS.TECH_REVIEW:
      return 'common.tech-review'
    case Appointment.INTAKE_STATUS.SYNCING:
      return 'common.syncing'
    case Appointment.INTAKE_STATUS.SYNCED:
      return 'common.synced'
    case Appointment.INTAKE_STATUS.NOT_REQUIRED:
      return 'common.not-required'
    case Appointment.INTAKE_STATUS.ACKNOWLEDGED:
      return 'common.acknowledged'
    default:
      return 'common.none'
  }
}

export function intakeStatusText(status: Appointment.INTAKE_STATUS): string {
  switch (status) {
    case Appointment.INTAKE_STATUS.NONE:
      return 'appointment.intake-status.none'
    case Appointment.INTAKE_STATUS.ACKNOWLEDGED:
      return 'appointment.intake-status.acknowledged'
    case Appointment.INTAKE_STATUS.DELIVERED:
      return 'appointment.intake-status.delivered'
    case Appointment.INTAKE_STATUS.PENDING:
      return 'appointment.intake-status.pending'
    case Appointment.INTAKE_STATUS.SUBMITTED:
      return 'appointment.intake-status.submitted'
    case Appointment.INTAKE_STATUS.CONFIRMED:
      return 'appointment.intake-status.confirmed'
    case Appointment.INTAKE_STATUS.TECH_CONFIRMED:
      return 'appointment.intake-status.tech-confirmed'
    case Appointment.INTAKE_STATUS.TECH_REVIEW:
      return 'appointment.intake-status.tech-review'
    case Appointment.INTAKE_STATUS.SYNCING:
      return 'appointment.intake-status.syncing'
    case Appointment.INTAKE_STATUS.SYNCED:
      return 'appointment.intake-status.synced'
    case Appointment.INTAKE_STATUS.NOT_REQUIRED:
      return 'appointment.intake-status.not-required'
    default:
      return 'appointment.intake-status.default'
  }
}

export function intakeStatusColor(status: Appointment.INTAKE_STATUS): string {
  switch (status) {
    case Appointment.INTAKE_STATUS.NONE:
      return COLOR.RED
    case Appointment.INTAKE_STATUS.ACKNOWLEDGED:
      return COLOR.RED
    case Appointment.INTAKE_STATUS.DELIVERED:
      return COLOR.YELLOW
    case Appointment.INTAKE_STATUS.PENDING:
      return COLOR.FLASHING_YELLOW
    case Appointment.INTAKE_STATUS.SUBMITTED:
      return COLOR.FLASHING_GREEN
    case Appointment.INTAKE_STATUS.CONFIRMED:
      return COLOR.GREEN
    case Appointment.INTAKE_STATUS.TECH_CONFIRMED:
      return COLOR.GREEN
    case Appointment.INTAKE_STATUS.TECH_REVIEW:
      return COLOR.FLASHING_GREEN
    case Appointment.INTAKE_STATUS.SYNCING:
      return COLOR.GREEN
    case Appointment.INTAKE_STATUS.SYNCED:
      return COLOR.GREEN
    case Appointment.INTAKE_STATUS.NOT_REQUIRED:
      return COLOR.GREEN
    default:
      return COLOR.RED
  }
}

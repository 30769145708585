import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { PatientPalRestError } from '@mmx/shared'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { NOT_FOUND } from '../data'
import { ClinicService } from '../services/clinic.service'

@Injectable()
export class ClinicExistsGuard {
  constructor(private router: Router, private clinicService: ClinicService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.clinicService.clinic$.pipe(
      map(clinic => Boolean(clinic)),
      catchError((err: PatientPalRestError) => {
        if (err.status === NOT_FOUND) {
          this.router.navigate(['/clinic-not-found'], {
            queryParams: {
              redirectTo: state.url,
            },
          })
        } else if (err.status === 0) {
          alert(
            'It appears your Internet is not working, please check your connection and try reloading the page.',
          )
        } else {
          this.router.navigate(['/outage'], {
            queryParams: {
              redirectTo: state.url,
            },
          })
        }
        return of(false)
      }),
    )
  }
}

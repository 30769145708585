import { COLOR } from '../data'
import { Appointment } from '../models'

export function paymentDisplayStatus(status: string): string {
  switch (status) {
    case Appointment.PAYMENT_STATUS.FULL:
      return 'appointment.payment-status.full'
    case Appointment.PAYMENT_STATUS.PARTIAL:
      return 'appointment.payment-status.partial'
    case Appointment.PAYMENT_STATUS.CONFIRMED:
      return 'appointment.payment-status.confirmed'
    case Appointment.PAYMENT_STATUS.ON_FILE:
      return 'appointment.payment-status.on-file'
    case Appointment.PAYMENT_STATUS.REFUND_DUE:
      return 'appointment.payment-status.refund-due'
    case Appointment.PAYMENT_STATUS.CHARGEABLE:
      return 'appointment.payment-status.chargeable'
    default:
      return 'common.none'
  }
}

export function paymentStatusText(status: string): string {
  switch (status) {
    case Appointment.PAYMENT_STATUS.NONE:
      return 'appointment.payment-status-text.none'
    case Appointment.PAYMENT_STATUS.CONFIRMED:
      return 'appointment.payment-status-text.confirmed'
    case Appointment.PAYMENT_STATUS.ON_FILE:
      return 'appointment.payment-status-text.on-file'
    case Appointment.PAYMENT_STATUS.CHARGEABLE:
      return 'appointment.payment-status-text.chargeable'
    case Appointment.PAYMENT_STATUS.PARTIAL:
      return 'appointment.payment-status-text.partial'
    case Appointment.PAYMENT_STATUS.FULL:
      return 'appointment.payment-status-text.full'
    case Appointment.PAYMENT_STATUS.REFUND_DUE:
      return 'appointment.payment-status-text.refund-due'
    default:
      return 'appointment.payment-status-text.default'
  }
}

export function paymentStatusColor(status: string): string {
  switch (status) {
    case Appointment.PAYMENT_STATUS.NONE:
      return COLOR.DEFAULT
    case Appointment.PAYMENT_STATUS.CONFIRMED:
      return COLOR.YELLOW
    case Appointment.PAYMENT_STATUS.ON_FILE:
      return COLOR.YELLOW
    case Appointment.PAYMENT_STATUS.CHARGEABLE:
      return COLOR.FLASHING_GREEN
    case Appointment.PAYMENT_STATUS.PARTIAL:
      return COLOR.FLASHING_GREEN
    case Appointment.PAYMENT_STATUS.FULL:
      return COLOR.GREEN
    case Appointment.PAYMENT_STATUS.REFUND_DUE:
      return COLOR.YELLOW
    default:
      return COLOR.RED
  }
}

export enum AUTHORIZATION_STATUS {
  NONE = 'N',
  NOT_KNOWN = 'NK',
  NOT_INITIATED = 'NI',
  NOT_FOUND = 'NF',
  INITIATED_CASE_MEDICAL = 'IM',
  INITIATED_CASE_INSURANCE = 'II',
  CONTACT_PAYER = 'CP',
  DENIED = 'D',
  PEER_REVIEW = 'PR',
  INELIGIBLE = 'I',
  APPROVED = 'A',
  PARTIALLY_APPROVED = 'A2',
  NOT_REQUIRED = 'NR',
  NO_ACTION_REQUIRED = 'NAR',
  CANCELLED = 'CA',
  COMPLETED = 'CO',
  EXPIRED = 'E',
  OUTDATED = 'O',
}

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { saveAs } from 'file-saver'
import { Observable } from 'rxjs'

import { PDFDownloadLink } from '../data/pdf-download-link'

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(public http: HttpClient) {}

  downloadFile(url: string): Observable<Blob> {
    const headers = new HttpHeaders().set('enctype', 'multipart/form-data')
    return this.http.get(url, { headers, responseType: 'blob' })
  }

  saveAs(pdfDownloadLink: PDFDownloadLink): void {
    if (typeof pdfDownloadLink === 'string') {
      const win = window.open('about:blank', 'documentWindow')
      if (win == null) {
        alert('A popup blocker prevented the display window from opening')
      } else {
        win.document.write(pdfDownloadLink)
      }
    } else {
      const url = pdfDownloadLink.url
      const fileName =
        pdfDownloadLink.pdfFileName || (pdfDownloadLink as any).fileName
      this.downloadFile(url).subscribe(
        (data) => saveAs(data, fileName),
        (err: HttpErrorResponse) => {
          if (err.status === 0) {
            // if we got a 0 status, it either means they lost Internet or there was a CORS request error
            // in either case, attempt to open the PDF in a new window
            const win = window.open(url, 'documentWindow')
            // if a popup blocker failed, then try to direct the web page to the PDF, it should cause a
            // download and not actually navigate the user… but this is all edge case
            if (win == null) {
              window.location.href = url
            }
          } else {
            alert(`Failed to download the requested file, please try again`)
          }
        },
      )
    }
  }
}

/**
 * @see http://hl7.org/fhir/us/core/stu1/ValueSet-detailed-ethnicity.html
 */
export const Ethnicities: string[] = [
  'Hispanic or Latino',
  'Not Hispanic or Latino',
  'Patient Declined',
  'Andalusian',
  'Argentinean',
  'Asturian',
  'Belearic Islander',
  'Bolivian',
  'Canal Zone',
  'Canarian',
  'Castillian',
  'Catalonian',
  'Central American Indian',
  'Central American',
  'Chicano',
  'Chilean',
  'Colombian',
  'Costa Rican',
  'Criollo',
  'Cuban',
  'Dominican',
  'Ecuadorian',
  'Gallego',
  'Guatemalan',
  'Honduran',
  'La Raza',
  'Latin American',
  'Mexican American Indian',
  'Mexican American',
  'Mexican',
  'Mexicano',
  'Nicaraguan',
  'Panamanian',
  'Paraguayan',
  'Peruvian',
  'Puerto Rican',
  'Salvadoran',
  'South American Indian',
  'South American',
  'Spaniard',
  'Spanish Basque',
  'Uruguayan',
  'Valencian',
  'Venezuelan',
]

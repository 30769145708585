export interface HBCharge {
  /**
   * Used for breaking down charges on the Pricer side. Use empty string by default.
   * Use unique id per charge or group of charges in order to enable breakdown.
   */
  ServiceLineId: string
  /**
   * cptCode from ChargeDescriptionMaster table
   */
  CPTCode: string
  /**
   * revCode from ChargeDescriptionMaster table. Use '000' for missing values
   */
  RevCode: string
  /**
   * Use Claim.AdmitDate by default.
   */
  ServiceFromDate: string
  /**
   * Use Claim.DischargeDate by default.
   */
  ServiceToDate: string
  /**
   * Number of units
   */
  Quantity: number
  /**
   * hcpcsCode from ChargeDescriptionMaster table or null for missing values
   */
  HCPCSCode: string | null
  /**
   * unitCharge from ChargeDescriptionMaster table
   */
  ChargeAmount: number
  /**
   * chargeCode from ChargeDescriptionMaster table
   */
  ChargeCode: string
  /**
   * Product modifier (see Product table). Use null by default
   * TODO consider this value later.
   */
  CPTModifierCode1: string | null
  /**
   * Product modifier (see Product table). Use null by default
   */
  CPTModifierCode2: string | null
  /**
   * Product modifier (see Product table). Use null by default
   */
  CPTModifierCode3: string | null
  /**
   * Product modifier (see Product table). Use null by default
   */
  CPTModifierCode4: string | null
  /**
   * Product modifier (see Product table). Use null by default
   */
  CPTModifierCode5: string | null
  /**
   * Use 'PIA' by default
   */
  SourceDB: string
  /**
   * Use false by default
   */
  PersistResult: boolean
}

export enum HBDiagnosisSequence {
  Y = 'Y',
  N = 'N',
  U = 'U',
}

export interface HBDiagnosis {
  /**
   * Use Y by default
   * todo possibly have to support all others for precision
   */
  Sequence: HBDiagnosisSequence
  /**
   * Appointment diagnosis codes. See diagnosisCodes from Appointment table.
   */
  Code: string
}

/**
 * String representation for in and out patient used in HB Pricer.
 */
export enum HBIOFlag {
  InPatient = 'I',
  OutPatient = 'O',
}

// TODO support of other specific types
export enum BillType {
  DefaultInPatient = '110',
  DefaultOutPatient = '131',
}

export interface HBClaim {
  /**
   * Appointment start date or current date in YYYY-MM-DD format
   */
  AdmitDate: string
  /**
   * Appointment end date or current date in YYYY-MM-DD format
   */
  DischargeDate: string
  /**
   * Use '01' by default
   */
  DischargeStatusCode: string
  /**
   * In or out patient. Use out patient by default
   */
  IPOPFlag: HBIOFlag
  /**
   * Use 'PIA' by default
   */
  SourceDb: string
  /**
   * Sum of all charges
   */
  TotalChargeAmount: number
  /**
   * Bill type. Use 131 for out patient and 110 for in patient.
   */
  TypeOfBill: BillType
  /**
   * Diagnosis list. Should be always defined for correct price.
   */
  DiagnosisList: HBDiagnosis[]
  /**
   * null by default. Interface and usage is unknown
   */
  ProceduresList: any[] | null
  /**
   * null by default. Interface and usage is unknown
   */
  ValueCodeList: any[] | null
  /**
   * Charge list
   */
  Charges: HBCharge[]
}

export interface HBPricerRequest {
  /**
   * Claim object
   */
  Claim: HBClaim
  /**
   * Use '<guid>_PIA'
   */
  CorrelationId: string
  /**
   * Pricer Clinic ID. See Clinic settings
   */
  ClientId: number
  /**
   * Equals to Clinic Carrier ID
   */
  PlanCode: string
  /**
   * Pricer Facility ID. Stored in Facility.mid property
   */
  FacilityId: string
  /**
   * Patient MRN
   */
  PatientNumber: string
  /**
   * Patient DOB in YYYY-MM-DD format
   */
  PatientDOB: string
  /**
   * null by default
   */
  PlanDescription: string | null
  /**
   * Use 'PIA' by default
   */
  SourceDB: string
  /**
   * Use false by default
   */
  PersistResult: boolean
}

export interface HBChargeLevelPriceItem {
  CPTCode: string
  RevCode: string
  ServiceFromDate: string | null
  ServiceToDate: string | null
  Quantity: number
  ChargeAmount: number
  ServiceName: string
  Price: number
  PriceAdjustment: string
  FinalPrice: number
  ServiceLineId: string
  ServiceDate: string | null
}

export interface HBPricerResponse {
  TraceURL: string
  CorrelationId: string
  TotalPrice: number
  ContractDetails: {
    Id: number
    Name: string
  }
  PricingResultDetails: {
    PatientNumber: string
    ClaimId: string
    ChargeLevelPriceItems: HBChargeLevelPriceItem[]
  }
}

import { REFERRAL_STATUS } from '@mmx/shared'

import { COLOR } from '../data'
import { getWorstStatus } from './get-worst-status'

export const referralStatusColors = {
  [REFERRAL_STATUS.NONE]: COLOR.DEFAULT,
  [REFERRAL_STATUS.NOT_KNOWN]: COLOR.DEFAULT,
  [REFERRAL_STATUS.REQUIRED]: COLOR.RED,
  [REFERRAL_STATUS.AWAITING_PATIENT]: COLOR.YELLOW,
  [REFERRAL_STATUS.AWAITING_REFERRER]: COLOR.YELLOW,
  [REFERRAL_STATUS.AWAITING_AUTHORIZATION]: COLOR.YELLOW,
  [REFERRAL_STATUS.AWAITING_REVIEW]: COLOR.FLASHING_YELLOW,
  [REFERRAL_STATUS.ACCEPTED]: COLOR.GREEN,
  [REFERRAL_STATUS.DECLINED]: COLOR.RED,
  [REFERRAL_STATUS.SCHEDULED]: COLOR.GREEN,
  [REFERRAL_STATUS.CANCELLED]: COLOR.GREEN,
  [REFERRAL_STATUS.COMPLETED]: COLOR.GREEN,
  [REFERRAL_STATUS.NOT_REQUIRED]: COLOR.GREEN,
}

export const getReferralStatusText = (status: REFERRAL_STATUS = REFERRAL_STATUS.NONE): string => {
  return `statuses.referrals.${status}`
}

export const getReferralStatusColor = (status: REFERRAL_STATUS = REFERRAL_STATUS.NONE): COLOR => {
  return referralStatusColors[status]
}

export const getWorstReferralStatusColor = (statuses: REFERRAL_STATUS[]): COLOR => {
  return getReferralStatusColor(
    getWorstStatus<REFERRAL_STATUS>(referralStatusColors, statuses),
  )
}

export const getWorstReferralStatusText = (statuses: REFERRAL_STATUS[]): string => {
  return getReferralStatusText(
    getWorstStatus<REFERRAL_STATUS>(referralStatusColors, statuses),
  )
}

import { Injectable } from '@angular/core'

import { PermissionsService } from './permissions.service'

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(
    private permissionsSvc: PermissionsService,
  ) { }

  public getFirstAuthenticatedRoute(redirectUrl?: string | void): string {
    if (redirectUrl) {
      return redirectUrl
    }

    if (this.permissionsSvc.instant('appointment.view')) {
      return '/dashboard'
    }

    if (this.permissionsSvc.instant('patient.view')) {
      return '/patients'
    }

    return '/profile'
  }
}

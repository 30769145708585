export enum Permission {
  API_KEYS_MANAGED = 'api-keys.manage',
  APPOINTMENT_CREATE = 'appointment.create',
  APPOINTMENT_EDIT = 'appointment.edit',
  APPOINTMENT_ESTIMATE_CREATE = 'appointment.estimate.create',
  APPOINTMENT_ESTIMATE_VIEW = 'appointment.estimate.view',
  APPOINTMENT_INTAKE_CREATE = 'appointment.intake.create',
  APPOINTMENT_INTAKE_EDIT = 'appointment.intake.edit',
  APPOINTMENT_INTAKE_VIEW = 'appointment.intake.view',
  APPOINTMENT_RECOMMENDATION_CREATE = 'appointment.recommendation.create',
  APPOINTMENT_RECOMMENDATION_DELETE = 'appointment.recommendation.delete',
  APPOINTMENT_RECOMMENDATION_EDIT = 'appointment.recommendation.edit',
  APPOINTMENT_RECOMMENDATION_VIEW = 'appointment.recommendation.view',
  APPOINTMENT_REQUEST_CREATE = 'appointment.request.create',
  APPOINTMENT_REQUEST_DELETE = 'appointment.request.delete',
  APPOINTMENT_REQUEST_EDIT = 'appointment.request.edit',
  APPOINTMENT_REQUEST_VIEW = 'appointment.request.view',
  APPOINTMENT_VIEW = 'appointment.view',
  APPOINTMENT_WAITING_LIST_CREATE = 'appointment.waiting-list.create',
  APPOINTMENT_WAITING_LIST_DELETE = 'appointment.waiting-list.delete',
  APPOINTMENT_WAITING_LIST_VIEW = 'appointment.waiting-list.view',
  AUTH_MANAGER = 'auth.manager',
  AUTHORIZATION_CREATE = 'authorization.create',
  AUTHORIZATION_EDIT = 'authorization.edit',
  AUTHORIZATION_DELETE = 'authorization.delete',
  AUTHORIZATION_VIEW = 'authorization.view',
  CONSOLE_LOGIN = 'console.login',
  IMPORT_CREATE = 'import.create',
  INTEGRATION_EVENTS_VIEW = 'integration.events.view',
  INTEGRATION_LOGS_VIEW = 'integration.logs.view',
  INTEGRATION_QUEUE_VIEW = 'integration.queue.view',
  INVOICE_CREATE = 'invoice.create',
  INVOICE_VIEW = 'invoice.view',
  INVOICE_VOID = 'invoice.void',
  MESSAGE_ARBITRARY = 'message.arbitrary',
  MESSAGE_CREATE = 'message.create',
  MESSAGE_VIEW = 'message.view',
  NOTE_CREATE = 'note.create',
  NOTE_VIEW = 'note.view',
  NOTIFICATION_EDIT = 'notification.edit',
  NOTIFICATION_VIEW = 'notification.view',
  PATIENT_CREATE = 'patient.create',
  PATIENT_DELETE = 'patient.delete',
  PATIENT_DOCUMENT_VIEW = 'patient.document.view',
  PATIENT_EDIT = 'patient.edit',
  PATIENT_ELIGIBILITY_EDIT = 'patient.eligibility.edit',
  PATIENT_ELIGIBILITY_VIEW = 'patient.eligibility.view',
  PATIENT_FILE_CREATE = 'patient.file.create',
  PATIENT_FILE_VIEW = 'patient.file.view',
  PATIENT_HISTORY_VIEW = 'patient.history.view',
  PATIENT_VIEW = 'patient.view',
  PAYOUT_VIEW = 'payout.view',
  PRIOR_AUTHORIZATION_PULL = 'prior-authorization.pull',
  PROCEDURES_ESTIMATE = 'procedures.estimate',
  REPORT_CREATE = 'report.create',
  REPORT_DELETE = 'report.delete',
  REPORT_VIEW = 'report.view',
  SCHEDULING_QUESTIONS_EDIT = 'scheduling.questions.edit',
  SCHEDULING_QUESTIONS_VIEW = 'scheduling.questions.view',
  SETTING_BLOCKED_TIME = 'setting.blocked-time.view',
  SETTING_EDIT = 'setting.edit',
  SETTING_VIEW = 'setting.view',
  SURVEY_QUESTION_CREATE = 'survey.question.create',
  SURVEY_QUESTION_DELETE = 'survey.question.delete',
  SURVEY_QUESTION_EDIT = 'survey.question.edit',
  SURVEY_QUESTION_VIEW = 'survey.question.view',
  SURVEY_RESPONSE_VIEW = 'survey.response.view',
  TRANSACTION_CREATE = 'transaction.create',
  TRANSACTION_REFUND = 'transaction.refund',
  TRANSACTION_VIEW = 'transaction.view',
  WORKLIST_CREATE = 'work-list.edit',
  WORKLIST_DELETE = 'work-list.delete',
  WORKLIST_VIEW = 'work-list.view',
}

import { ReplaySubject } from 'rxjs'

/**
 * Absract class to provide a void subject that emits when any subclass is instantiated
 */
export abstract class Initializer {
  protected initSubject = new ReplaySubject<void>(1)

  constructor() {
    this.initSubject.next()
  }
}

import { AbstractControl, ValidationErrors } from '@angular/forms'

export function minLessThanMaxValidator(minControl: AbstractControl, maxControl: AbstractControl, required: boolean = false): ValidationErrors | null {
  if (required) {
    if (!minControl.value) {
      minControl.setErrors({ required: true })
      return { required: true }
    }

    if (!maxControl.value) {
      maxControl.setErrors({ required: true })
      return { required: true }
    }
  }

  if (minControl.value && maxControl.value && minControl.value >= maxControl.value) {
    minControl.setErrors({ minGreaterThanOrEqualToMax: true })
    return { minGreaterThanOrEqualToMax: true }
  } else {
    minControl.setErrors(null)
  }

  return null
}

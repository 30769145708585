import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { MMXIconComponent } from './mmx-icon.component'

const declarations = [MMXIconComponent]

@NgModule({
  imports: [CommonModule],
  exports: [...declarations],
  declarations: [...declarations],
})
export class MMXIconModule {}

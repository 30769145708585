import { BaseModel, BaseModelData } from '@mmx/shared'
import { filter } from 'lodash'

import { ProcedureCodeModifierData } from '.'

export interface ProcedureData extends BaseModelData {
  code: string
  name: string
  type: string
  serviceCodes?: string[]
  category?: string
  topModifiers?: any
  supportedModifiers?: any
}

export class Procedure extends BaseModel {
  code: string
  name: string
  type: string
  serviceCodes?: string[]
  category?: string
  topModifiers?: ProcedureCodeModifierData[]
  supportedModifiers?: ProcedureCodeModifierData[]

  constructor(data: ProcedureData) {
    super(data)
    this.code = data.code
    this.name = data.name
    this.type = data.type
    this.serviceCodes = data.serviceCodes
    this.category = data.category
    this.topModifiers = data.topModifiers
    this.supportedModifiers = data.supportedModifiers
  }

  get searchTerms(): string {
    return filter([this.code, this.category, this.name.toLowerCase()]).join(' ')
  }
}

import { CRUD, CrudModel } from '@mmx/shared'
import * as moment from 'moment'

@CRUD({
  base: '/fee-schedules',
})
export class FeeScheduleModel extends CrudModel {
  name: string
  enabled: boolean
  carrierLookups: string[]
  facilityNPIs?: string[]
  type?: string
  rvs?: number
  procedures: FeeSchedule.Procedure[]
  lastProcessedAt?: moment.Moment
  pricesCount?: number

  get default(): boolean {
    return this.type === 'default'
  }

  get url(): string {
    return ['/settings', 'fee-schedules', this.id].join('/')
  }

  constructor(data?: any) {
    super(data, true)

    if (data?.lastProcessedAt) {
      this.lastProcessedAt = this.transformDate(data.lastProcessedAt)
    }

    if (this.procedures == null) {
      this.procedures = []
    }
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      rvs: this.rvs,
      enabled: this.enabled,
      carrierLookups: this.carrierLookups,
      facilityNPIs: this.facilityNPIs,
      procedures: this.procedures,
    }
  }
}

export namespace FeeSchedule {
  export enum CodeCategory {
    HC = 'CPT/HCPCS Codes',
    HP = 'HIPPS Codes',
    ER = 'JSP Codes',
    IV = 'HIEC Codes',
    WK = 'ABC Codes',
  }

  export interface Procedure {
    code: string
    // codeCategory?: CodeCategory
    codeModifier?: string
    procedureName: string
    // practiceProposedPrice: number
    billAmount: number
    negotiatedAmount: number
    // percentageCovered: number
    startDate: string
    endDate?: string
    enabled: boolean
  }
}

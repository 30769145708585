import { BaseModel, InsuranceProviderLookup, InsuranceProviderLookupData } from '@mmx/shared'

import { ProductCategory, ProductCategoryData } from '../product.category.model'
import { ProductData, ProductModel } from '../product.model'

export interface InsurancePlanType {
  code: string
  name: string
}

export interface AuthorizationRuleData {
  id: string
  carrierId: string
  carrierLookupId: string
  carrierLookup?: InsuranceProviderLookupData
  planTypes: InsurancePlanType[]
  codes?: string[]
  products?: ProductData[]
  productCategories?: ProductCategoryData[]
  authorizationRequired: boolean
  referralRequired: boolean
  notes?: string
}

export class AuthorizationRule extends BaseModel {
  carrierId: string
  carrierLookupId: string
  carrierLookup?: InsuranceProviderLookup
  planTypes: InsurancePlanType[]
  codes?: string[]
  products?: ProductModel[]
  productCategories?: ProductCategory[]
  authorizationRequired: boolean
  referralRequired: boolean
  notes?: string

  constructor(data: AuthorizationRuleData) {
    super(data)
    this.carrierId = data.carrierId
    this.carrierLookupId = data.carrierLookupId
    this.planTypes = data.planTypes
    this.codes = data.codes
    this.authorizationRequired = data.authorizationRequired
    this.referralRequired = data.referralRequired
    this.notes = data.notes
    this.products = (data.products ?? []).map((p) => new ProductModel(p))
    this.productCategories = (data.productCategories ?? []).map((c) => new ProductCategory(c))

    if (data.carrierLookup) {
      this.carrierLookup = new InsuranceProviderLookup(data.carrierLookup)
    }
  }
}

import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { NavigationEnd, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { CreateNoteComponent } from 'app/patient-details/dialogs/create-note/create-note.component'
import { toNumber } from 'lodash'
import * as moment from 'moment'
import { BehaviorSubject, catchError, combineLatestWith, filter, finalize, first, map, of, Subject, switchMap, tap } from 'rxjs'

import { PatientPalRestError } from '../../../../../../libs/shared/src/lib/services'
import { WorkItemModel, WorklistModel, WorklistSession } from '../models'
import { ApiService } from './api.service'
import { ClinicService } from './clinic.service'

enum WORK_ACTION {
  CONTINUE = 'continue',
  SNOOZE = 'snooze',
  ESCALATE = 'escalate',
  RELEASE = 'release',
  END = 'end',
}

interface WorkAction {
  action: WORK_ACTION // WebsocketAction.WORK_SESSION_CONTINUE,
  workSessionId: string
  workItemId: string
  snoozeUntil?: string
  escalateTo?: string
}

@Injectable({
  providedIn: 'root',
})
export class WorkService {
  public inProgress$ = new BehaviorSubject(false)
  public inSession$ = new BehaviorSubject<boolean>(false)
  public hasAssignments$ = new BehaviorSubject<boolean>(false)
  public viewingWorkItem$ = new BehaviorSubject<boolean>(false)
  public item$ = new BehaviorSubject<WorkItemModel | null>(null)
  public worklist$ = new BehaviorSubject<WorklistModel | null>(null)
  public session: WorklistSession | undefined
  public hasLeftNote$ = new BehaviorSubject<boolean>(false)
  public snoozeSelection$ = new BehaviorSubject<string | null>(null)
  public disableContinue$ = this.inProgress$.pipe(
    combineLatestWith(this.snoozeSelection$),
    map(([progress, snoozeSelection]) => progress || (this.worklist$.getValue().settings.snooze?.required && snoozeSelection == null)),
  )

  private get timezone() {
    return this.clinicService.clinic.settings.defaultTimezone ?? 'America/New_York'
  }

  public snoozeOptions$ = this.worklist$.pipe(
    combineLatestWith(this.item$),
    map(([worklist, item]) => {
      const startAtValue = item.metadata?.['appointment.startAt']
      const options = worklist?.settings.snooze?.options ?? []
      const regularOptions = options.filter(option => !option.startsWith('-'))
      const relativeOptions = options.filter(option => option.startsWith('-'))

      if (startAtValue) {
        const startAt = moment.tz(startAtValue, this.timezone)
        const minimumSnoozeTime = moment.utc().add(15, 'minutes')
        const filteredRelativeOptions = relativeOptions.filter(snooze => {
          const { quantity, unit } = this.parseSnoozeOption(snooze)
          // we add to the startAt because the quantity is a negative number
          const snoozeUntil = ['m', 'h', 'minutes', 'hours'].includes(unit)
            ? startAt.clone().add(quantity, unit)
            : startAt.clone().startOf('day').add(quantity, unit)
          return snoozeUntil.isAfter(minimumSnoozeTime)
        })

        return [
          ...regularOptions,
          ...filteredRelativeOptions,
        ]
      } else {
        return regularOptions
      }
    }),
  )

  private clinic$ = this.clinicService.clinic$

  private load$ = this.clinic$.pipe(
    first(),
    filter(clinic => clinic.modules.worklist),
    tap(() => this.inProgress$.next(true)),
    switchMap(() => this.api.get('/worklists/work/load').pipe(
      finalize(() => this.inProgress$.next(false)),
    )),
    tap(resp => this.onMessage(resp.data)),
    catchError(err => {
      this.snackError('Failed to load work lists')
      return err
    }),
  )

  private start$ = new Subject<undefined | WorklistModel>()
  private action$ = new Subject<WorkAction>()

  private worklists: WorklistModel[] = []

  constructor(
    private api: ApiService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translationService: TranslateService,
    private clinicService: ClinicService,
    private dialog: MatDialog,
  ) {
    this.load$.subscribe()

    this.start$.pipe(
      tap(() => this.inProgress$.next(true)),
      switchMap(worklist => this.api.post('/worklists/work/start', {
        worklistId: worklist?.id,
      }).pipe(
        catchError((err: PatientPalRestError) => {
          this.snackError(err.error?.message ?? 'Failed to start a work session')
          return of()
        }),
        finalize(() => this.inProgress$.next(false)),
      )),
      tap(resp => this.onMessage(resp.data)),
    ).subscribe()

    this.action$.pipe(
      tap(() => this.inProgress$.next(true)),
      switchMap(message => this.api.post('/worklists/work', message).pipe(
        catchError(() => {
          this.snackError('Failed to perform an action')
          return of()
        }),
        finalize(() => this.inProgress$.next(false)),
      )),
      tap(resp => this.onMessage(resp.data)),
    ).subscribe()

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      combineLatestWith(this.item$),
    ).subscribe(([event, item]: [NavigationEnd, WorkItemModel]) => {
      this.viewingWorkItem$.next(event.url === item?.url)
    })
  }

  getWorklist(worklistId: string): WorklistModel | undefined {
    return this.worklists.find(worklist => worklist.id === worklistId)
  }

  gotoWorkItem() {
    const item = this.item$.getValue()

    if (item) {
      this.router.navigate([item.url])
    }
  }

  /**
   * Start a work session
   *
   * Optionally specify the worklist to begin working, when unspecified, the
   * service will start a work session based on user's assignments.
   */
  startWork(worklist?: WorklistModel): void {
    if (!this.inProgress$.getValue()) {
      this.start$.next(worklist)
    }
  }

  nextItem(): void {
    if (this.inProgress$.getValue()) {
      return
    }

    if (this.noteRequired()) {
      this.openNoteDialog()
      return
    }

    const snooze = this.snoozeSelection$.getValue()
    const item = this.item$.getValue()

    if (!item) {
      return
    }

    if (snooze) {
      const { quantity, unit } = this.parseSnoozeOption(snooze)
      const isRelativeToWorkItem = quantity <= 0

      // when the snooze option is relative, we use the appointment startAt
      const startingDate = isRelativeToWorkItem
        ? moment.tz(item.metadata?.['appointment.startAt'], this.timezone)
        : moment.tz(this.timezone).startOf('minute')

      // as long as the unit is not in minutes or hours, we want it to be
      // ready at the start of a future day, not in the middle of a future day
      const snoozeUntil = ['m', 'h', 'minutes', 'hours'].includes(unit)
        ? startingDate.add(quantity, unit)
        : startingDate.startOf('day').add(quantity, unit)

      const minimumSnoozeTime = moment.utc().add(15, 'minutes')

      if (snoozeUntil.isValid() && snoozeUntil.isAfter(minimumSnoozeTime)) {
        this.action$.next({
          action: WORK_ACTION.SNOOZE,
          workSessionId: this.session.id,
          workItemId: item.id,
          snoozeUntil: snoozeUntil.toISOString(),
        })
      } else {
        alert('Invalid snooze option')
      }
    } else {
      this.action$.next({
        action: WORK_ACTION.CONTINUE,
        workSessionId: this.session.id,
        workItemId: item?.id,
      })
    }
  }

  escalate(escalateTo: string): void {
    if (!this.inProgress$.getValue()) {
      this.inProgress$.next(true)
      const item = this.item$.getValue()
      if (item) {
        this.action$.next({
          action: WORK_ACTION.ESCALATE,
          workSessionId: this.session.id,
          workItemId: item.id,
          escalateTo,
        })
      }
    }
  }

  snooze(snooze: string): void {
    this.snoozeSelection$.next(snooze)
  }

  endSession(type: 'release' | 'finish') {
    if (type === 'finish' && this.noteRequired()) {
      this.openNoteDialog()
      return
    }

    if (!this.inProgress$.getValue()) {
      this.action$.next({
        action: type === 'release' ? WORK_ACTION.RELEASE : WORK_ACTION.END,
        workSessionId: this.session.id,
        workItemId: this.item$.getValue()?.id,
      })
    }
  }

  private onMessage(message: any) {
    this.inProgress$.next(false)

    if (message.session === null) {
      this.session = null
    } else if (message.session != null) {
      this.session = new WorklistSession(message.session)
    }

    if (message.hasAssignments != null) {
      this.hasAssignments$.next(message.hasAssignments)
    }

    if (message.worklists || message.lists) {
      this.worklists = (message.worklists || message.lists).map(listData => new WorklistModel(listData))
    }

    if (message.worklist || message.list) {
      const newWorklist = new WorklistModel(message.worklist || message.list)
      const oldWorklist = this.worklist$.getValue()
      if (newWorklist.id !== oldWorklist?.id) {
        this.worklist$.next(newWorklist)
      }
    }

    if (message.item) {
      this.inSession$.next(true)
      this.hasLeftNote$.next(false)
      this.item$.next(new WorkItemModel(message.item))

      if (message.goto) {
        this.gotoWorkItem()
      }
    } else {
      this.inSession$.next(false)
    }

    if (message.noItems) {
      this.snackError(this.translationService.instant('worklist.assignment.noItems'))
    } else if (message.noAssignments) {
      this.snackError(this.translationService.instant('worklist.assignment.noAssignments'))
    }
  }

  private noteRequired() {
    const requireNote = this.worklist$.getValue()?.settings.requireNotes
    const hasLeftNote = this.hasLeftNote$.getValue()
    return requireNote && !hasLeftNote
  }

  private openNoteDialog() {
    const item = this.item$.getValue()

    this.dialog.open(CreateNoteComponent, {
      panelClass: 'dialog-form',
      width: '450px',
      data: {
        patientId: item.patientId,
        appointmentId: item.appointmentId,
      },
    }).afterClosed().subscribe((note) => {
      if (note) {
        this.hasLeftNote$.next(true)
      }
    })
  }

  private parseSnoozeOption(snooze: string) {
    const quantity = toNumber(snooze.substring(0, snooze.length - 1))
    const unit = snooze.substring(snooze.length - 1) as 'm' | 'h' | 'd'
    return { quantity, unit }
  }

  private snackError(message: string) {
    this.snackBar.open(
      message,
      null,
      {
        panelClass: 'error',
        duration: 3000,
      },
    )
  }
}

import { Injectable } from '@angular/core'
import { orderBy } from 'lodash'
import { BehaviorSubject, debounceTime, map, shareReplay, startWith, Subject, switchMap, tap } from 'rxjs'

import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  public loading$ = new BehaviorSubject(true)
  public refresh$ = new Subject<void>()

  public categories$ = this.refresh$.pipe(
    startWith(null),
    debounceTime(10),
    tap(() => this.loading$.next(true)),
    switchMap(() => this.apiService.getProductCategories()),
    tap(() => this.loading$.next(false)),
    map(fields => orderBy(fields, 'name')),
    shareReplay(1),
  )

  constructor(private apiService: ApiService) { }
}

import { SelectOption } from '@mmx/shared'

import { Invoice } from '../models'

export const InvoiceStatuses: SelectOption<string>[] = [
  { value: Invoice.STATUS.PAYING, viewValue: 'On a payment plan' },
  { value: Invoice.STATUS.PARTIAL, viewValue: 'Partially paid' },
  { value: Invoice.STATUS.PAID, viewValue: 'Paid' },
  { value: Invoice.STATUS.INTERIM, viewValue: 'Interim' },
  { value: Invoice.STATUS.CONFIRMED, viewValue: 'Confirmed' },
  { value: Invoice.STATUS.HOLD, viewValue: 'Hold' },
  { value: Invoice.STATUS.VOID, viewValue: 'Voided' },
  { value: Invoice.STATUS.DRAFT, viewValue: 'Draft' },
  { value: Invoice.STATUS.UNCOLLECTABLE, viewValue: 'Uncollectable' },
]

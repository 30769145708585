import { APPOINTMENT_REQUEST_STATUS } from '@mmx/shared'

import { COLOR } from '../data'
import { Appointment } from '../models'

const waitListStatusColors = {
  [Appointment.WAIT_LIST_STATUS.OPEN]: COLOR.YELLOW,
  [Appointment.WAIT_LIST_STATUS.WAITING]: COLOR.FLASHING_YELLOW,
  [Appointment.WAIT_LIST_STATUS.COMPLETED]: COLOR.GREEN,
  [Appointment.WAIT_LIST_STATUS.CANCELLED]: COLOR.RED,
}

const waitListStatusText = {
  [Appointment.WAIT_LIST_STATUS.OPEN]: 'wait-list.status.open',
  [Appointment.WAIT_LIST_STATUS.WAITING]: 'wait-list.status.waiting',
  [Appointment.WAIT_LIST_STATUS.COMPLETED]: 'wait-list.status.completed',
  [Appointment.WAIT_LIST_STATUS.CANCELLED]: 'wait-list.status.cancelled',
}

const appointmentRequestStatusColors = {
  [APPOINTMENT_REQUEST_STATUS.NEW]: COLOR.YELLOW,
  [APPOINTMENT_REQUEST_STATUS.PATIENT_CONTACTED]: COLOR.YELLOW,
  [APPOINTMENT_REQUEST_STATUS.PENDING]: COLOR.FLASHING_YELLOW,
  [APPOINTMENT_REQUEST_STATUS.COMPLETED]: COLOR.GREEN,
  [APPOINTMENT_REQUEST_STATUS.CANCELLED]: COLOR.RED,
}

const appointmentRequestStatusText = {
  [APPOINTMENT_REQUEST_STATUS.NEW]: 'appointment-request.status.new',
  [APPOINTMENT_REQUEST_STATUS.PATIENT_CONTACTED]: 'appointment-request.status.patient-contacted',
  [APPOINTMENT_REQUEST_STATUS.PENDING]: 'appointment-request.status.pending',
  [APPOINTMENT_REQUEST_STATUS.COMPLETED]: 'appointment-request.status.completed',
  [APPOINTMENT_REQUEST_STATUS.CANCELLED]: 'appointment-request.status.cancelled',
}

export const getWaitListStatusText = (status?: Appointment.WAIT_LIST_STATUS): string => {
  return status ? waitListStatusText[status] : 'wait-list.status.not-added'
}

export const getWaitListStatusColor = (status: Appointment.WAIT_LIST_STATUS): COLOR => {
  return status ? waitListStatusColors[status] : COLOR.DEFAULT
}

export const getAppointmentRequestStatusText = (status?: APPOINTMENT_REQUEST_STATUS): string => {
  return status ? appointmentRequestStatusText[status] : 'wait-list.status.not-added'
}

export const getAppointmentRequestStatusColor = (status: APPOINTMENT_REQUEST_STATUS): COLOR => {
  return status ? appointmentRequestStatusColors[status] : COLOR.DEFAULT
}

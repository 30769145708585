export interface AddressSchema {
  name?: string
  address1?: string
  address2?: string
  address3?: string
  sublocality?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}

export interface FacilityAddressSchema extends AddressSchema {
  lat?: number
  lng?: number
  link?: string
}

export class Address {
  address1: string
  address2: string
  address3: string
  city: string
  state: string
  zip: string
  country = 'US'

  constructor() {}
}

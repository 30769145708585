import { Injectable } from '@angular/core'
import { CUSTOM_FIELD_FOR, CustomFieldModel } from '@mmx/shared'
import { orderBy } from 'lodash'
import { BehaviorSubject, debounceTime, map, shareReplay, startWith, Subject, switchMap, tap } from 'rxjs'

import { CrudService } from './crud.service'

@Injectable({
  providedIn: 'root',
})
export class CustomFieldService {
  public loading$ = new BehaviorSubject(true)
  public refresh$ = new Subject<void>()

  public fields$ = this.refresh$.pipe(
    startWith(null),
    debounceTime(100),
    tap(() => this.loading$.next(true)),
    switchMap(() => this.crudService.index(CustomFieldModel)),
    tap(() => this.loading$.next(false)),
    map(fields => orderBy(fields, 'name')),
    shareReplay(1),
  )

  fieldsFor(fieldsFor: CUSTOM_FIELD_FOR) {
    return this.fields$.pipe(
      map(fields => fields.filter(field => field.for === fieldsFor)),
    )
  }

  patientFields$ = this.fieldsFor(CUSTOM_FIELD_FOR.PATIENT)
  appointmentFields$ = this.fieldsFor(CUSTOM_FIELD_FOR.APPOINTMENT)
  referralFields$ = this.fieldsFor(CUSTOM_FIELD_FOR.REFERRAL)
  authorizationFields$ = this.fieldsFor(CUSTOM_FIELD_FOR.AUTHORIZATION)
  transactionFields$ = this.fieldsFor(CUSTOM_FIELD_FOR.TRANSACTION)

  constructor(private crudService: CrudService) {}
}

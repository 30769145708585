import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

export interface ILayoutConf {
  sideMenuOpened?: boolean
  sideMenuEnlarged?: boolean
  navigationPos?: string // side, top
  sidebarStyle?: string // full, compact, closed
  dir?: string // ltr, rtl
  layoutInTransition?: boolean
  isMobile?: boolean
  sizes?: ILayoutResponsiveSizes
}

interface ILayoutResponsiveSizes {
  xs?: boolean
  sm?: boolean
  md?: boolean
  lg?: boolean
  xl?: boolean
  lt_sm?: boolean
  lt_md?: boolean
  lt_lg?: boolean
  lt_xl?: boolean
}

interface ILayoutChangeOptions {
  duration?: number
  transitionClass?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public layoutConf: ILayoutConf
  public changes: Observable<ILayoutConf>
  // @ts-ignore
  private layoutConfSubject = new BehaviorSubject<ILayoutConf>(this.layoutConf)

  constructor() {
    this.changes = this.layoutConfSubject.asObservable()
    this.setAppLayout()
  }

  setAppLayout() {
    // ******** SET YOUR LAYOUT OPTIONS HERE *********
    this.layoutConf = {
      navigationPos: 'top',
      dir: 'ltr',
    }
  }

  publishLayoutChange(lc: ILayoutConf, opt: ILayoutChangeOptions = {}) {
    if (!opt.transitionClass) {
      this.layoutConf = Object.assign(this.layoutConf, lc)
      return this.layoutConfSubject.next(this.layoutConf)
    }

    const duration = opt.duration || 250
    this.layoutConf = Object.assign(this.layoutConf, lc, {
      layoutInTransition: true,
    })
    this.layoutConfSubject.next(this.layoutConf)

    setTimeout(() => {
      this.layoutConf = Object.assign(this.layoutConf, {
        layoutInTransition: false,
      })
      this.layoutConfSubject.next(this.layoutConf)
    }, duration)
  }
}

import { SelectOption } from '@mmx/shared'

import { APPOINTMENT_REQUEST_TYPE } from '../models'

export const AppointmentRequestTypes: SelectOption<APPOINTMENT_REQUEST_TYPE>[] = [
  {
    value: APPOINTMENT_REQUEST_TYPE.NEW,
    viewValue: 'appointment-request.types.new',
  },
  {
    value: APPOINTMENT_REQUEST_TYPE.RESCHEDULE,
    viewValue: 'appointment-request.types.reschedule',
  },
  {
    value: APPOINTMENT_REQUEST_TYPE.FROM_ORDER,
    viewValue: 'appointment-request.types.from-order',
  },
]

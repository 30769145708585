import { SelectOption } from '@mmx/shared'

import { Transaction } from '../models'

export const TransactionStatuses: SelectOption<string>[] = [
  { value: Transaction.STATUS.SUCCESS, viewValue: 'Success' },
  { value: Transaction.STATUS.PENDING, viewValue: 'Pending' },
  { value: Transaction.STATUS.FAILED, viewValue: 'Failed' },
  { value: Transaction.STATUS.VOIDED, viewValue: 'Voided' },
]

export const TransactionTypes: SelectOption<string>[] = [
  { value: Transaction.TYPE.PAYMENT, viewValue: 'Payment' },
  { value: Transaction.TYPE.CAPTURE, viewValue: 'Capture' },
  { value: Transaction.TYPE.REFUND, viewValue: 'Refund' },
  { value: Transaction.TYPE.DISPUTE, viewValue: 'Dispute' },
]

export const TransactionReasons: SelectOption<string>[] = [
  { value: Transaction.REASON.COPAY, viewValue: 'Copay' },
  {
    value: Transaction.REASON.MEDICAL_RECORD_FEE,
    viewValue: 'Medical Record Fee',
  },
  { value: Transaction.REASON.CUSTOM, viewValue: 'Custom' },
]

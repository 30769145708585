import { PaginationParams } from '.'

export type PaginatedData = unknown[]

export interface CacheEntryI {
  data?: PaginatedData
  nextIndices?: Map<string, number>
  total?: number
}

function lastKey<T>(m: Map<T, unknown>): T {
  return m.size && [...m][m.size - 1][0]
}

/*
  A cache entry for every unique url. The entry contains an Array representing all the data
  and a Map of 'startAt' waypoints to data indices
*/

export class CacheEntry {
  constructor(obj: CacheEntryI = {}) {
    Object.assign(this, obj)
  }

  private data: PaginatedData = []
  nextIndices: Map<string, number> = new Map()
  total?: number

  hasData(requestParams: PaginationParams): boolean {
    const testAry = this.data.slice(requestParams.start, requestParams.end)
    return testAry.length === requestParams.pageSize || this.complete
  }

  sliceData(requestParams: PaginationParams): PaginatedData {
    return this.data.slice(requestParams.start, requestParams.end)
  }

  appendData(data: PaginatedData, startAt?: string): void {
    let idx: number
    if (this.nextIndices.has(startAt)) {
      idx = this.nextIndices.get(startAt)
    } else {
      idx = this.data.length
      this.nextIndices.set(startAt, idx)
    }
    this.data.splice(idx, data.length, ...data)
  }

  get length(): number {
    return this.data.length
  }

  get nextIndex(): string {
    return lastKey(this.nextIndices)
  }

  get complete(): boolean {
    return this.length >= this.total
  }
}

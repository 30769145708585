import { UntypedFormGroup } from '@angular/forms'

export const timeValidator = (group: UntypedFormGroup) => {
  const startTimeValue = group.get('startTime')?.value
  const endTimeValue = group.get('endTime')?.value

  if (!startTimeValue || !endTimeValue) {
    return false
  }

  const [startHours, startMinutes] = startTimeValue.split(':').map(Number)
  const [endHours, endMinutes] = endTimeValue.split(':').map(Number)

  if (endHours < startHours || (endHours === startHours && endMinutes <= startMinutes)) {
    return true
  }

  return false
}

export enum REFERRAL_STATUS {
  /** The referral is pending additional information or action from the patient. */
  AWAITING_PATIENT = 'PP',

  /** The referral is pending additional information or action from the referrer. */
  AWAITING_REFERRER = 'PRF',

  /** The referral is awaiting authorization from an insurance company or other entity. */
  AWAITING_AUTHORIZATION = 'PA',

  /** The referral has been received but must be reviewed. */
  AWAITING_REVIEW = 'PRV',

  /** The receiving provider has accepted the referral and agreed to take on the patient. */
  ACCEPTED = 'A',

  /** The referral has been declined by the receiving provider. */
  DECLINED = 'D',

  /** An appointment has been scheduled for the patient with the receiving provider. */
  SCHEDULED = 'S',

  /** The referral process has been completed, usually indicating that the patient has been seen by the receiving provider. */
  COMPLETED = 'CO',

  /** The referral has been canceled, either by the patient or the referring provider. */
  CANCELLED = 'CA',

  NONE = 'N',
  NOT_KNOWN = 'NK',
  REQUIRED = 'R',
  NOT_REQUIRED = 'NR',
}

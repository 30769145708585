import { BaseModel } from '../base-model'
import { RoleData } from './role-data'
import { RoleType } from './role-type'

export class Role extends BaseModel {
  name: string
  description: string
  permissions: string[]
  type: RoleType

  constructor(data: RoleData) {
    super(data)
    this.clinicId = data.clinicId
    this.name = data.name
    this.description = data.description
    this.permissions = data.permissions
    this.type = data.type
  }
}

import { BaseModelData, CRUD, CrudModel, ModelDate, PatientUploadedFile, REFERRAL_STATUS } from '@mmx/shared'
import * as _ from 'lodash'
import * as moment from 'moment'

import { ProductModel } from './product.model'

export interface ReferralData extends BaseModelData {
  patientId: string
  insuranceId?: string
  carrierLookupId?: string
  carrierId?: string
  startAt?: ModelDate
  endAt?: ModelDate
  appointmentIds?: string[]
  number?: string
}

@CRUD({
  base: '/crud/referrals',
})
export class ReferralModel extends CrudModel {
  patientId: string
  appointmentIds: Set<string>
  insuranceId?: string
  carrierLookupId?: string
  carrierId?: string
  products: ProductModel[]
  productNames: string[]
  number: string
  startAt?: moment.Moment
  endAt?: moment.Moment
  note?: string
  status: REFERRAL_STATUS
  updatedAt: moment.Moment
  createdAt: moment.Moment
  customFields?: Record<string, any>
  attachments?: PatientUploadedFile[]

  constructor(data: ReferralData) {
    super(data, true)

    if (data.startAt) {
      this.startAt = this.transformDate(data.startAt, false)
    }
    if (data.endAt) {
      this.endAt = this.transformDate(data.endAt, false)
    }

    // this.products = (data.products || []).map((product: ProductData) => new ProductModel(product))
    // this.productNames = this.products.map((product: ProductModel) => product.name)
    this.appointmentIds = new Set(data.appointmentIds)
  }

  toJSON(): any {
    return _.extend(super.toJSON(), {
      status: this.status,
      patientId: this.patientId,
      appointmentIds: this.appointmentIds ? [...this.appointmentIds] : undefined,
      insuranceId: this.insuranceId,
      carrierLookupId: this.carrierLookupId,
      carrierId: this.carrierId,
      products: this.products,
      number: this.number,
      startAt: this.startAt,
      endAt: this.endAt,
      customFields: this.customFields,
      note: this.note,
    })
  }
}

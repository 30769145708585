import { QuestionMeasurementType, QuestionType, SelectOption } from '@mmx/shared'

export const QuestionTypes: SelectOption<QuestionType>[] = [
  {
    viewValue: 'Address',
    value: QuestionType.Address,
    hint: 'Collects a complete street or mailing address.',
  },
  {
    viewValue: 'Body Selector',
    value: QuestionType.BodySelector,
    hint: 'Shows an image of a body and allows the patient to select body parts. Common use: confirm which part of the body was hurt.',
  },
  {
    viewValue: 'Checkbox',
    value: QuestionType.Checkbox,
    hint: 'Present multiple options to the patient where several can be selected. Common use: selecting allergens.',
  },
  {
    viewValue: 'Date',
    value: QuestionType.Date,
    hint: 'Collect a specific date (day, month, and year). For less specific days, consider using a Measurement question.',
  },
  {
    viewValue: 'Draw on Image',
    value: QuestionType.Image,
    hint: 'Upload an image the patient can draw on. Common use: circling parts of the body.',
  },
  {
    viewValue: 'Measurement',
    value: QuestionType.Measurement,
    hint: 'Ask for a weight, height, length, temperature, age, or numeric value. ',
  },
  {
    viewValue: 'Dropdown',
    value: QuestionType.Dropdown,
    hint: 'Provide a dropdown control, similar to this question type control.',
  },
  {
    viewValue: 'File',
    value: QuestionType.File,
    hint: 'Ask the patient to upload a file or an image. Common use: upload script.',
  },
  {
    viewValue: 'Label Only',
    value: QuestionType.Label,
    hint: 'Display additional context. Common use: Label a section of questions to improve clarity for the patient.',
  },
  {
    viewValue: 'Phone',
    value: QuestionType.Phone,
    hint: 'Ask for a phone or fax number.',
  },
  {
    viewValue: 'Radio Buttons',
    value: QuestionType.Radio,
    hint: 'Present multiple options to the patient where only one value can be selected.',
  },
  {
    viewValue: 'Table',
    value: QuestionType.Table,
    hint: 'A table with several columns, the patient can enter as many rows as needed. Common use: current drugs or prescriptions.',
  },
  {
    viewValue: 'Text',
    value: QuestionType.Text,
    hint: 'A free text input.',
  },
  {
    viewValue: 'Slider',
    value: QuestionType.Slider,
    hint: 'A slider question. it allows for the selection of a value from a range.',
  },
  {
    viewValue: 'Yes or No',
    value: QuestionType.Boolean,
    hint: 'A yes or no question which supports showing additional questions if the patient selects yes.',
  },
]

export const QuestionMeasurementTypes: SelectOption<QuestionMeasurementType>[] =
  [
    { value: QuestionMeasurementType.Weight, viewValue: 'Weight' },
    { value: QuestionMeasurementType.Length, viewValue: 'Height or Length' },
    { value: QuestionMeasurementType.Temp, viewValue: 'Temperature' },
    {
      value: QuestionMeasurementType.Age,
      viewValue: 'Age or Time Ago (in years and months)',
    },
    { value: QuestionMeasurementType.Numeric, viewValue: 'Numeric' },
    { value: QuestionMeasurementType.Ssn, viewValue: 'Social Security Number' },
  ]

export const QuestionAgeTypes: SelectOption<string>[] = [
  { value: '>=', viewValue: 'Equal to or older than' },
  { value: '<=', viewValue: 'Equal to or younger than' },
  { value: '=', viewValue: 'Equal to' },
  { value: 'BETWEEN', viewValue: 'Between' },
]

import { Injectable } from '@angular/core'
import { BaseModel } from '@mmx/shared'
import { AppConfirmService } from 'app/app-confirm'
import { ApiService, ClinicService } from 'app/core'
import { remove, sortBy } from 'lodash'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { IsDirtyService } from './is-dirty'

@Injectable()
export class SelectedRecordFacilitatorService<T extends BaseModel> extends IsDirtyService {
  public record: T
  public records: T[]
  public onChange = new BehaviorSubject<T>(null)

  constructor(
    protected clinicService: ClinicService,
    protected restService: ApiService,
    protected confirmService: AppConfirmService,
  ) {
    super(confirmService)
  }

  load(): Observable<T[]> {
    return this.loadHandler().pipe(
      map((records) => {
        this.records = this.sort(records)
        this.onChange.next(null)
        return this.records
      }),
    )
  }

  softLoad(): void {
    if (this.records == null && this.record == null) {
      this.load().subscribe(() => {})
    }
  }

  sort(records: T[]) {
    return sortBy(records, ['sortKey', 'id'])
  }

  change(record: T) {
    if (record.id) {
      remove(this.records, (res) => res.id === record.id)
    }
    this.record = record
    if (record.id) {
      this.records.push(this.record)
      this.records = sortBy(this.records, ['sortKey', 'id'])
    }
    this.onChange.next(this.record)
  }

  add(record: T, goto = true) {
    this.records.push(record)
    this.records = sortBy(this.records, ['sortKey', 'id'])
    if (goto) {
      this.change(record)
    }
    this.softLoad()
  }

  read(id?: string): Observable<T> {
    return this.readHandler(id)
  }

  delete(record: T) {
    if (record.id) {
      remove(this.records, (loc) => loc.id === record.id)
    }

    if (
      this.record &&
      this.record.id === record.id &&
      this.records.length > 0
    ) {
      this.record = this.records[0]
    }

    this.onChange.next(null)
  }

  protected loadHandler(): Observable<T[]> {
    return of([])
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected readHandler(id?: string): Observable<T> {
    return of(null)
  }
}

import { Injectable } from '@angular/core'
import { Resource, ResourceType } from '@mmx/shared'
import { orderBy } from 'lodash'
import { BehaviorSubject, debounceTime, map, Observable, shareReplay, startWith, Subject, switchMap, tap } from 'rxjs'

import { CrudService } from './crud.service'

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  public loading$ = new BehaviorSubject(true)
  public refresh$ = new Subject<void>()

  public resources$ = this.refresh$.pipe(
    startWith(null),
    debounceTime(100),
    tap(() => this.loading$.next(true)),
    switchMap(() => this.crudService.index(Resource)),
    tap(() => this.loading$.next(false)),
    map(resources => orderBy(resources, 'name')),
    shareReplay(1),
  )

  constructor(private crudService: CrudService) {}

  public hasResourceOfType(type: ResourceType): Observable<boolean> {
    return this.resources$.pipe(
      map(resources => resources.find(resource => resource.type === type) != null),
    )
  }
}

import { Injectable } from '@angular/core'
import { AUTHORIZATION_STATUS, REFERRAL_STATUS } from '@mmx/shared'
import { AuthenticatedRestService } from 'app/core/services/auth-rest.service'

export enum NecessityType {
  AUTHORIZATION = 'authorization',
  REFERRAL = 'referral',
}

export type NecessityStatus = AUTHORIZATION_STATUS | REFERRAL_STATUS

export interface SaveNecessityParams extends Record<string, any> {
  appointmentId: string
  type: NecessityType
  status: NecessityStatus
  insuranceId: string
}

@Injectable()
export class NecessityService extends AuthenticatedRestService {
  public save(params: SaveNecessityParams) {
    return this.put(`/appointments/${params.appointmentId}/necessity`, params)
  }
}

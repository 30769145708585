import { AddressSchema, BaseModel, BaseModelData, PatientNameSchema } from '@mmx/shared'
import { get, map } from 'lodash'

export type GenericCoverageLevel =
  | 'children_only'
  | 'dependents_only'
  | 'employee_and_children'
  | 'employee_only'
  | 'employee_and_spouse'
  | 'family'
  | 'individual'
  | 'spouse_and_children'
  | 'spouse_only'

export type GenericNetworkStatus = 'yes' | 'no' | 'not_applicable'

export type GenericAuthorizationRequired = 'yes' | 'no' | undefined

export type GenericTimePeriod =
  | 'calendar_year'
  | 'service_year'
  | 'remaining'
  | 'hour'
  | 'day'
  | '24_hours'
  | 'years'
  | 'year_to_date'
  | 'contract'
  | 'episode'
  | 'visit'
  | 'outlier'
  | 'exceed'
  | 'not_exceeded'
  | 'lifetime'
  | 'lifetime_remaining'
  | 'month'
  | 'week'
  | 'admission'

export interface GenericMessage {
  message: string
}

export interface GenericBenefitAmount {
  amount: number
  currency: string // USD
}

export interface GenericEntity {
  phone?: string
  email?: string
  url?: string
  fax?: string
  name?: PatientNameSchema
  address?: AddressSchema
  organization_name?: string
  messages?: GenericMessage[]
}

export interface GenericPrimaryCareProvider extends GenericEntity {
  npi?: string
}

export interface GenericCoverageItem {
  description?: string
  service_types: string[]
  service_type_codes: string[]
  messages?: GenericMessage[]
  in_plan_network: GenericNetworkStatus
  coverage_level: GenericCoverageLevel | void
  place_of_service?: string
  delivery?: any

  /**
   * @property selected
   * When true, this means a clinician selected this as the preferred line item
   */
  selected?: boolean
}

export interface GenericCoinsurance extends GenericCoverageItem {
  benefit_percent: number
  authorization_required?: GenericAuthorizationRequired
}

export interface GenericCopayment extends GenericCoverageItem {
  copayment: GenericBenefitAmount
  authorization_required?: GenericAuthorizationRequired
}

export interface GenericDeductible extends GenericCoverageItem {
  time_period?: GenericTimePeriod
  eligibility_date?: string
  benefit_amount: GenericBenefitAmount
  remaining_amount?: GenericBenefitAmount
}

export type GenericOutOfPocketMaximum = GenericDeductible

export type EligibilityBenefitItem =
  | GenericCoinsurance
  | GenericCopayment
  | GenericDeductible
  | GenericOutOfPocketMaximum

export interface GenericLimitation extends GenericCoverageItem {
  benefit_amount?: GenericBenefitAmount
  remaining_amount?: GenericBenefitAmount
  time_period?: GenericTimePeriod
  quantity?: string
  quantity_qualifier?: string
  latest_visit_or_consultation_date?: string
  authorization_required?: GenericAuthorizationRequired
}

export interface GenericCoverage {
  messages: GenericMessage[]
  eligibility_begin_date?: string // The date eligibility started for the member’s plan.
  eligibility_end_date?: string // The date eligibility ends for the member’s plan.
  pcp?: any
  coinsurance?: GenericCoinsurance[]
  copay?: GenericCopayment[]
  deductibles?: GenericDeductible[]
  out_of_pocket?: GenericOutOfPocketMaximum[] | void
  limitations?: GenericLimitation[] | void
  contacts?: any[]
  // service_types: string[]
  // service_type_codes: string[]
}

export interface PlanDetails {
  planName?: string // The special program name for the insurance plan. This is often the brand or marketing name for the plan.
  planNumber?: string // The group or policy number associated with the coverage.
  policyNumber?: string
  contractNumber?: string // Used to identify the provider’s contract number.
  memberId: string
  groupName?: string
  groupNumber?: string
  insuranceType?: string
  networkId?: string // Used to identify a member’s plan network.
  networkName?: string // Description for plan network for the member specified in the eligibility response.
  planStartDate?: string // The date that plan coverage started for the member specified in the eligibility response.
  planEndDate?: string // The date that plan coverage ends for the member specified in the eligibility response.
  policyEffectiveDate?: string
  policyExpirationDate?: string
}

export interface GenericEligibilityError {
  code: string
  message: string

  entity?: GenericEntity
  correctionRequired?: boolean
  tryAgainLater?: boolean
}

export interface StandardizedEligibilityData {
  service_types: string[] | void
  service_type_codes: string[] | void
  errors?: GenericEligibilityError[] | void
  subscriber?: any
  /**
   * @deprecated
   */
  plan?: PlanDetails
  plans?: PlanDetails[] | void
  coverage?: GenericCoverage | void
  payer?: GenericEntity
}

export interface EligibilityData extends BaseModelData {
  patientId: string
  source: string
  providerId: string
  carrierLookupId?: string
  clinicCarrierName?: string
  carrierName?: string
  carrierSlug?: string
  valid: boolean
  active: boolean
  // payload: any
  data: StandardizedEligibilityData
  meta: any
  inNetwork: boolean
  raw?: any
  payload?: any
}

export class Eligibility extends BaseModel {
  patientId: string
  source: string // enum
  providerId: string // patientpal insurance carrier id
  carrierLookupId?: string // clinic's insurance carrier lookup
  clinicCarrierName?: string // clinic's custom carrier name for payer
  carrierSlug?: string
  valid: boolean
  active: boolean
  // payload: any
  data: StandardizedEligibilityData
  meta: any
  inNetwork: boolean
  selectedCoverageItems: any
  raw?: any
  payload?: any

  private _carrierName: string // patientpal insurance carrier name

  constructor(data: EligibilityData) {
    super(data)
    this.patientId = data.patientId
    this.source = data.source
    this.providerId = data.providerId
    this.carrierLookupId = data.carrierLookupId
    this.clinicCarrierName = data.clinicCarrierName
    this._carrierName = data.carrierName
    this.carrierSlug = data.carrierSlug
    this.valid = data.valid
    this.active = data.active
    // this.payload = data.payload
    this.data = data.data
    this.meta = data.meta
    this.inNetwork = data.inNetwork || false
    this.raw = data.raw
    this.payload = data.payload

    // backwards compatibility
    if (this.data && this.data.plans == null && this.data.plan != null) {
      this.data.plans = [this.data.plan]
    }
  }

  get text(): string {
    return this.carrierName + ' ' + this.memberId
  }

  get url(): string {
    if (this.patientId) {
      return `/patient/${this.patientId}/eligibility/${this.id}`
    } else {
      return `/procedures-estimate/eligibilities/${this.id}`
    }
  }

  get memberId(): string {
    const memberId = get(this, 'data.subscriber.id')

    if (!memberId) {
      return 'common.none'
    }
    return memberId
  }

  get carrier(): string {
    return this.providerId
  }

  get carrierName(): string {
    return this._carrierName || this.providerId
  }

  get groupNumber(): string {
    return get(this, 'data.plan.groupNumber')
  }

  get good(): boolean {
    return this.valid && this.active
  }

  get selectedDeductible(): GenericDeductible | 'none' | undefined {
    return this.getSelected<GenericDeductible>('deductibles')
  }

  get selectedCopayment(): GenericCopayment | 'none' | undefined {
    return this.getSelected<GenericCopayment>('copay')
  }

  get selectedCoinsurance(): GenericCoinsurance | 'none' | undefined {
    return this.getSelected<GenericCoinsurance>('coinsurance')
  }

  get selectedOutOfPocketMax(): GenericOutOfPocketMaximum | 'none' | undefined {
    return this.getSelected<GenericOutOfPocketMaximum>('out_of_pocket')
  }

  getInNetworkLabel(inPlanNetwork: string) {
    switch (inPlanNetwork) {
      case 'not_applicable':
        return 'All Providers (see messages)'
      case 'yes':
        return 'In Network'
      case 'no':
        return 'Out Of Network'
      default:
        return inPlanNetwork
    }
  }

  toJSON() {
    return {
      inNetwork: this.inNetwork,
      eligibilityData: this.data,
      selectedCoverageItems: this.selectedCoverageItems,
    }
  }

  private getSelected<T extends GenericCoverageItem>(
    benefitType: string,
  ): T | 'none' | undefined {
    // if they have explicitly selected 'none', then make sure we honor that
    if (
      this.selectedCoverageItems &&
      this.selectedCoverageItems[benefitType] &&
      this.selectedCoverageItems[benefitType]?.includes('none')
    ) {
      return 'none'
    }

    const items: T[] = map(get(this, `data.coverage.${benefitType}`, []))
    return items.find((d) => d.selected)
  }
}

import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'
import { ApiService, Note } from 'app/core'

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss'],
})
export class CreateNoteComponent {
  public inProgress: boolean

  public form = new FormGroup({
    note: new FormControl<string>('', [Validators.required]),
  })

  constructor(
    private dialogRef: MatDialogRef<CreateNoteComponent>,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) private data: { patientId: string, appointmentId?: string},
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  public submit() {
    if (this.form.invalid) {
      return
    }
    this.inProgress = true

    this.apiService.createNote({
      patientId: this.data.patientId,
      appointmentId: this.data.appointmentId,
      text: this.form.value.note.trim(),
    }).subscribe({
      next: (response) => {
        this.dialogRef.close(new Note({ ...response.data }))
      },
      error: () => {
        this.snackBar.open(this.translateService.instant('note.add-note-error'), 'close', { duration: 3000 })
        this.inProgress = false
      },
    })
  }
}

import { BaseModelData, Clinician } from '@mmx/shared'

import { AvailityServiceReview } from './availity'

export enum AuthorizationHistoryEventType {
  AuthCreated = 'C',
  AuthPulled = 'P',
  AuthSubmitted = 'S',
  AuthEdited = 'E',
  AuthDeleted = 'D',
}

export type authorizationChange = Record<string, { oldValue: any, newValue: any }>

export interface AuthorizationHistory extends BaseModelData {
  patientId: string
  authorizationId: string
  eventType: AuthorizationHistoryEventType
  change?: authorizationChange
  submittedData?: AvailityServiceReview.Review
  updatedBy?: string
  clinician?: Clinician
}

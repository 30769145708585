import { AUTHORIZATION_STATUS } from '@mmx/shared'

import { COLOR } from '../data'
import { getWorstStatus } from './get-worst-status'

const statusColors = {
  [AUTHORIZATION_STATUS.NONE]: COLOR.DEFAULT,
  [AUTHORIZATION_STATUS.NOT_KNOWN]: COLOR.DEFAULT,
  [AUTHORIZATION_STATUS.NOT_INITIATED]: COLOR.DEFAULT,
  [AUTHORIZATION_STATUS.NOT_FOUND]: COLOR.DEFAULT,
  [AUTHORIZATION_STATUS.INITIATED_CASE_MEDICAL]: COLOR.YELLOW,
  [AUTHORIZATION_STATUS.INITIATED_CASE_INSURANCE]: COLOR.YELLOW,
  [AUTHORIZATION_STATUS.CONTACT_PAYER]: COLOR.FLASHING_YELLOW,
  [AUTHORIZATION_STATUS.DENIED]: COLOR.FLASHING_RED,
  [AUTHORIZATION_STATUS.PEER_REVIEW]: COLOR.YELLOW,
  [AUTHORIZATION_STATUS.INELIGIBLE]: COLOR.FLASHING_RED,
  [AUTHORIZATION_STATUS.NO_ACTION_REQUIRED]: COLOR.FLASHING_GREEN,
  [AUTHORIZATION_STATUS.EXPIRED]: COLOR.RED,
  [AUTHORIZATION_STATUS.OUTDATED]: COLOR.FLASHING_RED,
  [AUTHORIZATION_STATUS.PARTIALLY_APPROVED]: COLOR.FLASHING_GREEN,
  [AUTHORIZATION_STATUS.CANCELLED]: COLOR.DEFAULT,
  [AUTHORIZATION_STATUS.COMPLETED]: COLOR.GREEN,
  [AUTHORIZATION_STATUS.APPROVED]: COLOR.GREEN,
  [AUTHORIZATION_STATUS.NOT_REQUIRED]: COLOR.GREEN,
}

export const getAuthorizationStatusText = (
  status: AUTHORIZATION_STATUS = AUTHORIZATION_STATUS.NONE,
): string => {
  return `statuses.authorizations.${status}`
}

export const getAuthorizationStatusColor = (
  status: AUTHORIZATION_STATUS = AUTHORIZATION_STATUS.NONE,
): COLOR => {
  return statusColors[status]
}

export const getWorstAuthorizationStatusColor = (statuses: AUTHORIZATION_STATUS[]): COLOR => {
  if (!statuses.length) {
    return getAuthorizationStatusColor()
  }

  return getAuthorizationStatusColor(
    getWorstStatus<AUTHORIZATION_STATUS>(statusColors, statuses),
  )
}

export const getWorstAuthorizationStatusText = (statuses: AUTHORIZATION_STATUS[]): string => {
  if (!statuses.length) {
    return getAuthorizationStatusText()
  }

  return getAuthorizationStatusText(
    getWorstStatus<AUTHORIZATION_STATUS>(statusColors, statuses),
  )
}

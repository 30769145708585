/**
 * We should follow the HL7 medical standard
 *
 * @see https://www.hl7.org/implement/standards/fhir/v3/Race/cs.html
 *
 * The minimum race categories are:
 *  American Indian or Alaska Native
 *  Asian
 *  Black or African American
 *  Native Hawaiian or Other Pacific Islander
 *  White
 */

export const Races: string[] = [
  'American Indian or Alaska Native',
  '  American Indian',
  // '    Abenaki',
  // '    Algonquian',
  // '    Apache',
  // '      Chiricahua',
  // '      Fort Sill Apache',
  // '      Jicarilla Apache',
  // '      Lipan Apache',
  // '      Mescalero Apache',
  // '      Oklahoma Apache',
  // '      Payson Apache',
  // '      San Carlos Apache',
  // '      White Mountain Apache',
  // '    Arapaho',
  // '      Northern Arapaho',
  // '      Southern Arapaho',
  // '      Wind River Arapaho',
  // '    Arikara',
  // '    Assiniboine',
  // '    Assiniboine Sioux',
  // '      Fort Peck Assiniboine Sioux',
  // '    Bannock',
  // '    Blackfeet',
  // '    Brotherton',
  // '    Burt Lake Band',
  // '    Caddo',
  // '      Oklahoma Cado',
  // '    Cahuilla',
  // '      Agua Caliente Cahuilla',
  // '      Augustine',
  // '      Cabazon',
  // '      Los Coyotes',
  // '      Morongo',
  // '      Santa Rosa Cahuilla',
  // '      Torres-Martinez',
  // '    California Tribes',
  // '      Cahto',
  // '      Chimariko',
  // '      Coast Miwok',
  // '      Digger',
  // '      Kawaiisu',
  // '      Kern River',
  // '      Mattole',
  // '      Red Wood',
  // '      Santa Rosa',
  // '      Takelma',
  // '      Wappo',
  // '      Yana',
  // '      Yuki',
  // '    Canadian and Latin American Indian',
  // '      Canadian Indian',
  // '      Central American Indian',
  // '      French American Indian',
  // '      Mexican American Indian',
  // '      South American Indian',
  // '    Spanish American Indian',
  // '    Catawba',
  // '      Alatna',
  // '      Alexander',
  // '      Allakaket',
  // '      Alanvik',
  // '      Anvik',
  // '      Arctic',
  // '      Beaver',
  // '      Birch Creek',
  // '      Cantwell',
  // '      Chalkyitsik',
  // '      Chickaloon',
  // '      Chistochina',
  // '      Chitina',
  // '      Circle',
  // '      Cook Inlet',
  // '      Copper Center',
  // '      Copper River',
  // '      Dot Lake',
  // '      Doyon',
  // '      Eagle',
  // '      Eklutna',
  // '      Evansville',
  // '      Fort Yukon',
  // '      Gakona',
  // '      Galena',
  // '      Grayling',
  // '      Gulkana',
  // '      Healy Lake',
  // '      Holy Cross',
  // '      Hughes',
  // '      Huslia',
  // '      Iliamna',
  // '      Kaltag',
  // '      Kluti Kaah',
  // '      Knik',
  // '      Koyukuk',
  // '      Lake Minchumina',
  // '      Lime',
  // '      Mcgrath',
  // '      Manley Hot Springs',
  // '      Mentasta Lake',
  // '      Minto',
  // '      Nenana',
  // '      Nikolai',
  // '      Ninilchik',
  // '      Nondalton',
  // '      Northway',
  // '      Nulato',
  // '      Pedro Bay',
  // '      Rampart',
  // '      Ruby',
  // '      Salamatof',
  // '      Seldovia',
  // '      Slana',
  // '      Shageluk',
  // '      Stevens',
  // '      Stony River',
  // '      Takotna',
  // '      Tanacross',
  // '      Tanaina',
  // '      Tanana',
  // '      Tanana Chiefs',
  // '      Tazlina',
  // '      Telida',
  // '      Tetlin',
  // '      Tok',
  // '      Tyonek',
  // '      Venetie',
  // '      Wiseman',
  // '    Cayuse',
  // '    Chehalis',
  // '    Chemakuan',
  // '      Hoh',
  // '      Quileute',
  // '    Chemehuevi',
  // '    Cherokee',
  // '      Cherokee Alabama',
  // '      Cherokees of Northeast Alabama',
  // '      Cherokees of Southeast Alabama',
  // '      Eastern Cherokee',
  // '      Echota Cherokee',
  // '      Etowah Cherokee',
  // '      Northern Cherokee',
  // '      Tuscola',
  // '      United Keetowah Band of Cherokee',
  // '      Western Cherokee',
  // '    Cherokee Shawnee',
  // '    Cheyenne',
  // '      Northern Cheyenne',
  // '      Southern Cheyenne',
  // '    Cheyenne-Arapaho',
  // '    Chickahominy',
  // '      Eastern Chickahominy',
  // '      Western Chickahominy',
  // '    Chickasaw',
  // '    Chinook',
  // '      Clatsop',
  // '      Columbia River Chinook',
  // '      Kathlamet',
  // '      Upper Chinook',
  // '      Wakiakum Chinook',
  // '      Willapa Chinook',
  // '      Wishram',
  // '    Chippewa',
  // '      Bad River',
  // '      Bay Mills Chippewa',
  // '      Bois Forte',
  // '      Burt Lake Chippewa',
  // '      Fond du Lac',
  // '      Grand Portage',
  // '      Grand Traverse Band of Ottawa-Chippewa',
  // '      Keweenaw',
  // '      Lac Courte Oreilles',
  // '      Lac du Flambeau',
  // '      Lac Vieux Desert Chippewa',
  // '      Lake Superior',
  // '      Leech Lake',
  // '      Little Shell Chippewa',
  // '      Mille Lacs',
  // '      Minnesota Chippewa',
  // '      Ontonagon',
  // '      Red Cliff Chippewa',
  // '      Red Lake Chippewa',
  // '      Saginaw Chippewa',
  // '      St. Croix Chippewa',
  // '      Sault Ste. Marie Chippewa',
  // '      Sokoagon Chippewa',
  // '      Turtle Mountain',
  // '      White Earth',
  // '    Chippewa Cree',
  // '      Rocky Boy\'s Chippewa Cree',
  // '    Chitimacha',
  // '    Choctaw',
  // '      Clifton Choctaw',
  // '      Jena Choctaw',
  // '      Mississippi Choctaw',
  // '      Mowa Band of Choctaw',
  // '      Oklahoma Choctaw',
  // '    Chumash',
  // '      Santa Ynez',
  // '    Clear Lake',
  // '    Coeur D\'Alene',
  // '    Coharie',
  // '    Colorado River',
  // '    Colville',
  // '    Comanche',
  // '      Oklahoma Comanche',
  // '    Coos, Lower Umpqua, Siuslaw',
  // '    Coos',
  // '    Coquilles',
  // '    Costanoan',
  // '    Coushatta',
  // '      Alabama Coushatta',
  // '    Cowlitz',
  // '    Cree',
  // '    Creek',
  // '      Alabama Creek',
  // '      Alabama Quassarte',
  // '      Eastern Creek',
  // '      Eastern Muscogee',
  // '      Kialegee',
  // '      Lower Muscogee',
  // '      Machis Lower Creek Indian',
  // '      Poarch Band',
  // '      Principal Creek Indian Nation',
  // '      Star Clan of Muscogee Creeks',
  // '      Thlopthlocco',
  // '      Tuckabachee',
  // '    Croatan',
  // '    Crow',
  // '    Cupeno',
  // '      Agua Caliente',
  // '    Delaware',
  // '      Eastern Delaware',
  // '      Lenni-Lenape',
  // '      Munsee',
  // '      Oklahoma Delaware',
  // '      Rampough Mountain',
  // '      Sand Hill',
  // '    Diegueno',
  // '      Campo',
  // '      Capitan Grande',
  // '      Cuyapaipe',
  // '      La Posta',
  // '      Manzanita',
  // '      Mesa Grande',
  // '      San Pasqual',
  // '      Santa Ysabel',
  // '      Sycuan',
  // '    Eastern Tribes',
  // '      Attacapa',
  // '      Biloxi',
  // '      Georgetown',
  // '      Moor',
  // '      Nansemond',
  // '      Natchez',
  // '      Nausu Waiwash',
  // '      Nipmuc',
  // '      Paugussett',
  // '      Pocomoke Acohonock',
  // '      Southeastern Indians',
  // '      Susquehanock',
  // '      Tunica Biloxi',
  // '      Waccamaw-Siousan',
  // '      Wicomico',
  // '    Esselen',
  // '    Fort Belknap',
  // '    Fort Berthold',
  // '    Fort Mcdowell',
  // '    Fort Hall',
  // '    Gabrieleno',
  // '    Grand Ronde',
  // '    Gros Ventres',
  // '      Atsina',
  // '    Haliwa',
  // '    Hidatsa',
  // '    Hoopa',
  // '      Trinity',
  // '      Whilkut',
  // '    Hoopa Extension',
  // '    Houma',
  // '    Inaja-Cosmit',
  // '    Iowa',
  // '      Iowa of Kansas-Nebraska',
  // '      Iowa of Oklahoma',
  // '    Iroquois',
  // '      Cayuga',
  // '      Mohawk',
  // '      Oneida',
  // '      Onondaga',
  // '      Seneca',
  // '      Seneca Nation',
  // '      Seneca-Cayuga',
  // '      Tonawanda Seneca',
  // '      Tuscarora',
  // '      Wyandotte',
  // '    Juaneno',
  // '    Kalispel',
  // '    Karuk',
  // '    Kaw',
  // '    Kickapoo',
  // '      Oklahoma Kickapoo',
  // '      Texas Kickapoo',
  // '    Kiowa',
  // '      Oklahoma Kiowa',
  // '    Klallam',
  // '      Jamestown',
  // '      Lower Elwha',
  // '      Port Gamble Klallam',
  // '    Klamath',
  // '    Konkow',
  // '    Kootenai',
  // '    Lassik',
  // '    Long Island',
  // '      Matinecock',
  // '      Montauk',
  // '      Poospatuck',
  // '      Setauket',
  // '    Luiseno',
  // '      La Jolla',
  // '      Pala',
  // '      Pauma',
  // '      Pechanga',
  // '      Soboba',
  // '      Twenty-Nine Palms',
  // '      Temecula',
  // '    Lumbee',
  // '    Lummi',
  // '    Maidu',
  // '      Mountain Maidu',
  // '      Nishinam',
  // '    Makah',
  // '    Maliseet',
  // '    Mandan',
  // '    Mattaponi',
  // '    Menominee',
  // '    Miami',
  // '      Illinois Miami',
  // '      Indiana Miami',
  // '      Oklahoma Miami',
  // '    Miccosukee',
  // '    Micmac',
  // '      Aroostook',
  // '    Mission Indians',
  // '    Miwok',
  // '    Modoc',
  // '    Mohegan',
  // '    Mono',
  // '    Nanticoke',
  // '    Narragansett',
  // '    Navajo',
  // '      Alamo Navajo',
  // '      Canoncito Navajo',
  // '      Ramah Navajo',
  // '    Nez Perce',
  // '    Nomalaki',
  // '    Northwest Tribes',
  // '      Alsea',
  // '      Celilo',
  // '      Columbia',
  // '      Kalapuya',
  // '      Molala',
  // '      Talakamish',
  // '      Tenino',
  // '      Tillamook',
  // '      Wenatchee',
  // '      Yahooskin',
  // '    Omaha',
  // '    Oregon Athabaskan',
  // '    Osage',
  // '    Otoe-Missouria',
  // '    Ottawa',
  // '      Burt Lake Ottawa',
  // '      Michigan Ottawa',
  // '      Oklahoma Ottawa',
  // '    Paiute',
  // '      Bishop',
  // '      Bridgeport',
  // '      Burns Paiute',
  // '      Cedarville',
  // '      Fort Bidwell',
  // '      Fort Independence',
  // '      Kaibab',
  // '      Las Vegas',
  // '      Lone Pine',
  // '      Lovelock',
  // '      Malheur Paiute',
  // '      Moapa',
  // '      Northern Paiute',
  // '      Owens Valley',
  // '      Pyramid Lake',
  // '      San Juan Southern Paiute',
  // '      Southern Paiute',
  // '      Summit Lake',
  // '      Utu Utu Gwaitu Paiute',
  // '      Walker River',
  // '      Yerington Paiute',
  // '    Pamunkey',
  // '    Passamaquoddy',
  // '      Indian Township',
  // '      Pleasant Point Passamaquoddy',
  // '    Pawnee',
  // '      Oklahoma Pawnee',
  // '    Penobscot',
  // '    Peoria',
  // '      Oklahoma Peoria',
  // '    Pequot',
  // '      Marshantucket Pequot',
  // '    Pima',
  // '      Gila River Pima-Maricopa',
  // '      Salt River Pima-Maricopa',
  // '    Piscataway',
  // '    Pit River',
  // '    Pomo',
  // '      Central Pomo',
  // '      Dry Creek',
  // '      Eastern Pomo',
  // '      Kashia',
  // '      Northern Pomo',
  // '      Scotts Valley',
  // '      Stonyford',
  // '      Sulphur Bank',
  // '    Ponca',
  // '      Nebraska Ponca',
  // '      Oklahoma Ponca',
  // '    Potawatomi',
  // '      Citizen Band Potawatomi',
  // '      Forest County',
  // '      Hannahville',
  // '      Huron Potawatomi',
  // '      Pokagon Potawatomi',
  // '      Prairie Band',
  // '      Wisconsin Potawatomi',
  // '    Powhatan',
  // '    Pueblo',
  // '      Acoma',
  // '      Arizona Tewa',
  // '      Cochiti',
  // '      Hopi',
  // '      Isleta',
  // '      Jemez',
  // '      Keres',
  // '      Laguna',
  // '      Nambe',
  // '      Picuris',
  // '      Piro',
  // '      Pojoaque',
  // '      San Felipe',
  // '      San Ildefonso',
  // '      San Juan Pueblo',
  // '      San Juan De',
  // '      San Juan',
  // '      Sandia',
  // '      Santa Ana',
  // '      Santa Clara',
  // '      Santo Domingo',
  // '      Taos',
  // '      Tesuque',
  // '      Tewa',
  // '      Tigua',
  // '      Zia',
  // '      Zuni',
  // '    Puget Sound Salish',
  // '      Duwamish',
  // '      Kikiallus',
  // '      Lower Skagit',
  // '      Muckleshoot',
  // '      Nisqually',
  // '      Nooksack',
  // '      Port Madison',
  // '      Puyallup',
  // '      Samish',
  // '      Sauk-Suiattle',
  // '      Skokomish',
  // '      Skykomish',
  // '      Snohomish',
  // '      Snoqualmie',
  // '      Squaxin Island',
  // '      Steilacoom',
  // '      Stillaguamish',
  // '      Suquamish',
  // '      Swinomish',
  // '      Tulalip',
  // '      Upper Skagit',
  // '    Quapaw',
  // '    Quinault',
  // '    Rappahannock',
  // '    Reno-Sparks',
  // '    Round Valley',
  // '    Sac and Fox',
  // '      Iowa Sac and Fox',
  // '      Missouri Sac and Fox',
  // '      Oklahoma Sac and Fox',
  // '    Salinan',
  // '    Salish',
  // '    Salish and Kootenai',
  // '    Schaghticoke',
  // '    Scott Valley',
  // '    Seminole',
  // '      Big Cypress',
  // '      Brighton',
  // '      Florida Seminole',
  // '      Hollywood Seminole',
  // '      Oklahoma Seminole',
  // '    Serrano',
  // '      San Manual',
  // '    Shasta',
  // '    Shawnee',
  // '      Absentee Shawnee',
  // '      Eastern Shawnee',
  // '    Shinnecock',
  // '    Shoalwater Bay',
  // '    Shoshone',
  // '      Battle Mountain',
  // '      Duckwater',
  // '      Elko',
  // '      Ely',
  // '      Goshute',
  // '      Panamint',
  // '      Ruby Valley',
  // '      Skull Valley',
  // '      South Fork Shoshone',
  // '      Te-Moak Western Shoshone',
  // '      Timbi-Sha Shoshone',
  // '      Washakie',
  // '      Wind River Shoshone',
  // '      Yomba',
  // '    Shoshone Paiute',
  // '      Duck Valley',
  // '      Fallon',
  // '      Fort McDermitt',
  // '    Siletz',
  // '    Sioux',
  // '      Blackfoot Sioux',
  // '      Brule Sioux',
  // '      Cheyenne River Sioux',
  // '      Crow Creek Sioux',
  // '      Dakota Sioux',
  // '      Flandreau Santee',
  // '      Fort Peck',
  // '      Lake Traverse Sioux',
  // '      Lower Brule Sioux',
  // '      Lower Sioux',
  // '      Mdewakanton Sioux',
  // '      Miniconjou',
  // '      Oglala Sioux',
  // '      Pine Ridge Sioux',
  // '      Pipestone Sioux',
  // '      Prairie Island Sioux',
  // '      Prior Lake Sioux',
  // '      Rosebud Sioux',
  // '      Sans Arc Sioux',
  // '      Santee Sioux',
  // '      Sisseton-Wahpeton',
  // '      Sisseton Sioux',
  // '      Spirit Lake Sioux',
  // '      Standing Rock Sioux',
  // '      Teton Sioux',
  // '      Two Kettle Sioux',
  // '      Upper Sioux',
  // '      Wahpekute Sioux',
  // '      Wahpeton Sioux',
  // '      Wazhaza Sioux',
  // '      Yankton Sioux',
  // '      Yanktonai Sioux',
  // '    Siuslaw',
  // '    Spokane',
  // '    Stewart',
  // '    Stockbridge',
  // '    Susanville',
  // '    Tohono O\'Odham',
  // '      Ak-Chin',
  // '      Gila Bend',
  // '      San Xavier',
  // '      Sells',
  // '    Tolowa',
  // '    Tonkawa',
  // '    Tygh',
  // '    Umatilla',
  // '    Umpqua',
  // '      Cow Creek Umpqua',
  // '    Ute',
  // '      Allen Canyon',
  // '      Uintah Ute',
  // '      Ute Mountain Ute',
  // '    Wailaki',
  // '    Walla-Walla',
  // '    Wampanoag',
  // '      Gay Head Wampanoag',
  // '      Mashpee Wampanoag',
  // '    Warm Springs',
  // '    Wascopum',
  // '    Washoe',
  // '      Alpine',
  // '      Carson',
  // '      Dresslerville',
  // '    Wichita',
  // '    Wind River',
  // '    Winnebago',
  // '      Ho-chunk',
  // '      Nebraska Winnebago',
  // '    Winnemucca',
  // '    Wintun',
  // '    Wiyot',
  // '      Table Bluff',
  // '    Yakama',
  // '    Yakama Cowlitz',
  // '    Yaqui',
  // '      Barrio Libre',
  // '      Pascua Yaqui',
  // '    Yavapai Apache',
  // '    Yokuts',
  // '      Chukchansi',
  // '      Tachi',
  // '      Tule River',
  // '    Yuchi',
  // '    Yuman',
  // '      Cocopah',
  // '      Havasupai',
  // '      Hualapai',
  // '      Maricopa',
  // '      Mohave',
  // '      Quechan',
  // '      Yavapai',
  // '    Yurok',
  // '      Coast Yurok',
  '  Alaska Native',
  // '    Alaska Indian',
  // '      Alaskan Athabascan',
  // '        Ahtna',
  // '      Southeast Alaska',
  // '        Tlingit-Haida',
  // '          Angoon',
  // '          Central Council of Tlingit and Haida Tribes',
  // '          Chilkat',
  // '          Chilkoot',
  // '          Craig',
  // '          Douglas',
  // '          Haida',
  // '          Hoonah',
  // '          Hydaburg',
  // '          Kake',
  // '          Kasaan',
  // '          Kenaitze',
  // '          Ketchikan',
  // '          Klawock',
  // '          Pelican',
  // '          Petersburg',
  // '          Saxman',
  // '          Sitka',
  // '          Tenakee Springs',
  // '          Tlingit',
  // '          Wrangell',
  // '          Yakutat',
  // '        Tsimshian',
  // '          Metlakatla',
  // '    Eskimo',
  // '      Greenland Eskimo',
  // '      Inupiat Eskimo',
  // '        Ambler',
  // '        Anaktuvuk',
  // '        Anaktuvuk Pass',
  // '        Arctic Slope Inupiat',
  // '        Arctic Slope Corporation',
  // '        Atqasuk',
  // '        Barrow',
  // '        Bering Straits Inupiat',
  // '        Brevig Mission',
  // '        Buckland',
  // '        Chinik',
  // '        Council',
  // '        Deering',
  // '        Elim',
  // '        Golovin',
  // '        Inalik Diomede',
  // '        Inupiaq',
  // '        Kaktovik',
  // '        Kawerak',
  // '        Kiana',
  // '        Kivalina',
  // '        Kobuk',
  // '        Kotzebue',
  // '        Koyuk',
  // '        Kwiguk',
  // '        Mauneluk Inupiat',
  // '        Nana Inupiat',
  // '        Noatak',
  // '        Nome',
  // '        Noorvik',
  // '        Nuiqsut',
  // '        Point Hope',
  // '        Point Lay',
  // '        Selawik',
  // '        Shaktoolik',
  // '        Shishmaref',
  // '        Shungnak',
  // '        Solomon',
  // '        Teller',
  // '        Unalakleet',
  // '        Wainwright',
  // '        Wales',
  // '        White Mountain',
  // '        White Mountain Inupiat',
  // '        Mary\'s Igloo',
  // '      Siberian Eskimo',
  // '        Gambell',
  // '        Savoonga',
  // '        Siberian Yupik',
  // '      Yupik Eskimo',
  // '        Akiachak',
  // '        Akiak',
  // '        Alakanuk',
  // '        Aleknagik',
  // '        Andreafsky',
  // '        Aniak',
  // '        Atmautluak',
  // '        Bethel',
  // '        Bill Moore\'s Slough',
  // '        Bristol Bay Yupik',
  // '        Calista Yupik',
  // '        Chefornak',
  // '        Chevak',
  // '        Chuathbaluk',
  // '        Clark\'s Point',
  // '        Crooked Creek',
  // '        Dillingham',
  // '        Eek',
  // '        Ekuk',
  // '        Ekwok',
  // '        Emmonak',
  // '        Goodnews Bay',
  // '        Hooper Bay',
  // '        Iqurmuit (Russian Mission)',
  // '        Kalskag',
  // '        Kasigluk',
  // '        Kipnuk',
  // '        Koliganek',
  // '        Kongiganak',
  // '        Kotlik',
  // '        Kwethluk',
  // '        Kwigillingok',
  // '        Levelock',
  // '        Lower Kalskag',
  // '        Manokotak',
  // '        Marshall',
  // '        Mekoryuk',
  // '        Mountain Village',
  // '        Naknek',
  // '        Napaumute',
  // '        Napakiak',
  // '        Napaskiak',
  // '        Newhalen',
  // '        New Stuyahok',
  // '        Newtok',
  // '        Nightmute',
  // '        Nunapitchukv',
  // '        Oscarville',
  // '        Pilot Station',
  // '        Pitkas Point',
  // '        Platinum',
  // '        Portage Creek',
  // '        Quinhagak',
  // '        Red Devil',
  // '        St. Michael',
  // '        Scammon Bay',
  // '        Sheldon\'s Point',
  // '        Sleetmute',
  // '        Stebbins',
  // '        Togiak',
  // '        Toksook',
  // '        Tulukskak',
  // '        Tuntutuliak',
  // '        Tununak',
  // '        Twin Hills',
  // '        Georgetown',
  // '        St. Mary\'s',
  // '        Umkumiate',
  // '    Aleut',
  // '      Alutiiq Aleut',
  // '        Tatitlek',
  // '        Ugashik',
  // '      Bristol Bay Aleut',
  // '        Chignik',
  // '        Chignik Lake',
  // '        Egegik',
  // '        Igiugig',
  // '        Ivanof Bay',
  // '        King Salmon',
  // '        Kokhanok',
  // '        Perryville',
  // '        Pilot Point',
  // '        Port Heiden',
  // '      Chugach Aleut',
  // '        Chenega',
  // '        Chugach Corporation',
  // '        English Bay',
  // '        Port Graham',
  // '      Eyak',
  // '      Koniag Aleut',
  // '        Akhiok',
  // '        Agdaagux',
  // '        Karluk',
  // '        Kodiak',
  // '        Larsen Bay',
  // '        Old Harbor',
  // '        Ouzinkie',
  // '        Port Lions',
  // '      Sugpiaq',
  // '      Suqpigaq',
  // '      Unangan Aleut',
  // '        Akutan',
  // '        Aleut Corporation',
  // '        Aleutian',
  // '        Aleutian Islander',
  // '        Atka',
  // '        Belkofski',
  // '        Chignik Lagoon',
  // '        King Cove',
  // '        False Pass',
  // '        Nelson Lagoon',
  // '        Nikolski',
  // '        Pauloff Harbor',
  // '        Qagan Toyagungin',
  // '        Qawalangin',
  // '        St. George',
  // '        St. Paul',
  // '        Sand Point',
  // '        South Naknek',
  // '        Unalaska',
  // '        Unga',
  'Asian',
  '  Asian Indian',
  '  Bangladeshi',
  '  Bhutanese',
  '  Burmese',
  '  Cambodian',
  '  Chinese',
  '  Taiwanese',
  '  Filipino',
  '  Hmong',
  '  Indonesian',
  '  Japanese',
  '  Korean',
  '  Laotian',
  '  Malaysian',
  '  Okinawan',
  '  Pakistani',
  '  Sri Lankan',
  '  Thai',
  '  Vietnamese',
  '  Iwo Jiman',
  '  Maldivian',
  '  Nepalese',
  '  Singaporean',
  '  Madagascar',
  'Black or African American',
  '  Black',
  '  African American',
  '  African',
  // '    Botswanan',
  // '    Ethiopian',
  // '    Liberian',
  // '    Namibian',
  // '    Nigerian',
  // '    Zairean',
  '  Bahamian',
  '  Barbadian',
  '  Dominican',
  '  Dominica Islander',
  '  Haitian',
  '  Jamaican',
  '  Tobagoan',
  '  Trinidadian',
  '  West Indian',
  'Native Hawaiian or Other Pacific Islander',
  '  Polynesian',
  // '    Native Hawaiian',
  // '    Samoan',
  // '    Tahitian',
  // '    Tongan',
  // '    Tokelauan',
  '  Micronesian',
  // '    Guamanian or Chamorro',
  // '    Guamanian',
  // '    Chamorro',
  // '    Mariana Islander',
  // '    Marshallese',
  // '    Palauan',
  // '    Carolinian',
  // '    Kosraean',
  // '    Pohnpeian',
  // '    Saipanese',
  // '    Kiribati',
  // '    Chuukese',
  // '    Yapese',
  '  Melanesian',
  // '    Fijian',
  // '    Papua New Guinean',
  // '    Solomon Islander',
  // '    New Hebrides',
  // '  Other Pacific Islander',
  'White',
  '  European',
  // '    Armenian',
  // '    English',
  // '    French',
  // '    German',
  // '    Irish',
  // '    Italian',
  // '    Polish',
  // '    Scottish',
  '  Middle Eastern or North African',
  // '    Assyrian',
  // '    Egyptian',
  // '    Iranian',
  // '    Iraqi',
  // '    Lebanese',
  // '    Palestinian',
  // '    Syrian',
  // '    Afghanistani',
  // '    Israeili',
  '  Arab',
  // 'Other Race',
]

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { finalize, Observable } from 'rxjs'

import { environment } from '../../../environments/environment'
import { LoadingService } from './loading.service'

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(environment.api)) {
      const path = [...request.url.split(environment.api)][1]
      const token = request.params.get('loading')
      this.loadingService.start(path, token)
      return next
        .handle(request)
        .pipe(finalize(() => this.loadingService.stop(path, token)))
    } else {
      return next.handle(request)
    }
  }
}

import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'
import { ClinicService, RoutingService } from 'app/core/services'
import { every } from 'lodash'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ClinicModulesGuard implements CanActivate {
  constructor(
    private clinicService: ClinicService,
    private router: Router,
    private routingSvc: RoutingService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.clinicService.clinic$.pipe(
      map(clinic => clinic != null && every(route.data?.modules ?? [], (clinicModule) => clinic.modules[clinicModule])),
      tap(value => {
        if (!value) {
          this.router.navigateByUrl(this.routingSvc.getFirstAuthenticatedRoute())
        }
      }),
      catchError(() => of(false)),
    )
  }
}

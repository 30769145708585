<div mat-dialog-title>
  <h2>{{ 'note.add-internal-note' | translate }}</h2>
</div>

<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>
        {{ 'note.note' | translate }}
      </mat-label>
      <textarea
        matInput
        formControlName="note"
        [placeholder]="'note.text-field-placeholder' | translate"
        mat-autosize
        matAutosizeMinRows="1"
        matAutosizeMaxRows="5"
      ></textarea>
    </mat-form-field>
    <mat-hint>
      {{ 'note.add-note-hint' | translate }}
    </mat-hint>
  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-stroked-button type="button" mat-dialog-close>
    {{ 'common.cancel' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="form.invalid || inProgress"
    (click)="submit()"
  >
    {{ 'common.add' | translate }}
  </button>
</mat-dialog-actions>

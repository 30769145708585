import { BaseModel, BaseModelData, Facility } from '@mmx/shared'
import * as moment from 'moment'

import { ProductModel } from './product.model'

export interface ChargeDescriptionMasterData extends BaseModelData {
  product: ProductModel
  productId: string
  location: Facility
  facilityId: string
  type: ChargeDescriptionMaster.TYPE
  chargeType: ChargeDescriptionMaster.CHARGE_TYPE
  chargeCode: string
  chargeDescription?: string
  revCode: string
  cptCode: string
  hcpcsCode: string
  departmentCode: string
  departmentDescription?: string
  unitCharge: number
  quantity: number
  effectiveDate: moment.Moment
}

export class ChargeDescriptionMasterModel extends BaseModel {
  productId: string
  facilityId: string
  type: ChargeDescriptionMaster.TYPE
  chargeType: ChargeDescriptionMaster.CHARGE_TYPE
  chargeCode: string
  chargeDescription?: string
  revCode: string
  cptCode: string
  hcpcsCode: string
  departmentCode: string
  departmentDescription?: string
  unitCharge: number
  quantity: number
  effectiveDate: moment.Moment

  constructor(data?: any) {
    super(data, true)

    if (data?.effectiveDate) {
      this.effectiveDate = this.transformDate(data.effectiveDate)
    }
  }

  toJSON() {
    return {
      id: this.id,
      productId: this.productId,
      facilityId: this.facilityId,
      type: this.type,
      chargeType: this.chargeType,
      chargeCode: this.chargeCode,
      chargeDescription: this.chargeDescription || '',
      revCode: this.revCode,
      cptCode: this.cptCode,
      hcpcsCode: this.hcpcsCode,
      departmentCode: this.departmentCode,
      departmentDescription: this.departmentDescription || '',
      unitCharge: this.unitCharge,
      quantity: this.quantity,
      effectiveDate: this.effectiveDate,
    }
  }
}

export namespace ChargeDescriptionMaster {
  export enum CHARGE_TYPE {
    CHARGE = 'CHARGE',
    INSPMT = 'INSPMT',
    PATPMT = 'PATPMT',
    CONTALLOW = 'CONTALLOW',
  }

  export enum TYPE {
    PB = 'pb',
    HB = 'hb',
  }
}

import { BaseModel, ClinicEhr, ClinicModules, ClinicModulesPreferences, TaxRate } from '@mmx/shared'
import { environment } from 'environments'
import { cloneDeep, extend } from 'lodash'

import { standardizePhone } from '../../utils/phone'
import { ClinicData } from './clinic-data'
import { ClinicIntegrationSupport } from './clinic-integration-support'
import { ClinicOptions } from './clinic-options'
import { IClinicPaymentProvider } from './clinic-payment-provider'
import { ClinicPricer } from './clinic-pricer'
import { ClinicProvider } from './clinic-provider'
import { ClinicSocialNetwork } from './clinic-social-network'

export class Clinic extends BaseModel {
  id: string
  slug: string
  name: string
  email: string
  phoneNumber: string
  languages: string[]
  image: string
  defaultTax?: TaxRate
  twilioNumber: string
  website: string
  paymentProvider: IClinicPaymentProvider
  hasPaymentProvider?: boolean
  stripeCustomerId?: string
  pinCode: string
  type: string
  provider: ClinicProvider
  pricer: ClinicPricer
  socialNetwork: ClinicSocialNetwork
  options: ClinicOptions = {
    inPatientContact: true,
  }
  modules: ClinicModules = {}
  modulesPreferences: ClinicModulesPreferences = {
    unknownInsurance: true,
    paymentPlans: false,
  }
  settings: { [key: string]: any }
  integration?: ClinicIntegrationSupport
  ehr: ClinicEhr

  get enabledModules(): string[] {
    const modules: string[] = []

    for (const module of Object.keys(this.modules)) {
      if (this.modules[module]) {
        modules.push(module)
      }
    }

    return modules
  }

  constructor(data?: ClinicData) {
    super(data, true)

    if (data) {
      extend(this, data)
    }

    if (!this.provider) {
      this.provider = {}
    }

    if (!this.pricer) {
      this.pricer = {}
    }

    if (!this.settings) {
      this.settings = {}
    }

    if (!this.languages) {
      this.languages = ['en']
    }

    if (!this.socialNetwork) {
      this.socialNetwork = {}
    }

    // ensure backwards compatibility
    if (!this.provider.type) {
      this.provider.type = 'singular'
    }

    this.options.inPatientContact = true
  }

  hasClientLaunchButtons(input: { patient?: any; appointment?: any }) {
    if (
      this.integration &&
      this.integration.clientLaunchButtons &&
      this.integration.clientLaunchButtons.length > 0
    ) {
      return this.integration.clientLaunchButtons.find((button) =>
        this.getClientLaunchButtonEndpoint(button.endpoint, input),
      )
    }

    return false
  }

  getClientLaunchButtonEndpoint(
    endpoint: string,
    input: { patient?: any; appointment?: any },
  ) {
    if (
      this.integration &&
      this.integration.supports &&
      this.integration.supports.includes('CLIENT_LAUNCH') &&
      typeof this.integration.endpoint === 'string'
    ) {
      const url = endpoint
        .replace('{{ENDPOINT}}', this.integration.endpoint.replace(/\/$/, ''))
        .replace('{{PATIENT_EID}}', input.patient?.eid)
        .replace(
          '{{B64_PATIENT_EID}}',
          input.patient?.eid ? btoa(input.patient?.eid) : '',
        )
        .replace('{{PATIENT_EKEY}}', input.patient?.ekey)
        .replace('{{PATIENT_ID}}', input.patient?.id)
        .replace('{{APPOINTMENT_ID}}', input.appointment?.id)
        .replace('{{APPOINTMENT_EID}}', input.appointment?.eid)
        .replace('{{APPOINTMENT_EKEY}}', input.appointment?.ekey)
        .replace(
          '{{STUDY_UID}}',
          input.appointment?.metadata?.StudyInstanceUID ??
          input.appointment?.metadata?.study_uid,
        )
        .replace(
          '{{METADATA:evo_clinic_id}}',
          input.appointment?.metadata?.evo_clinic_id,
        )

      if (
        !url.includes('{{') &&
        !url.includes('}}') &&
        !url.includes('undefined')
      ) {
        return url
      }
    }

    return ''
  }

  linkToPortal(path = '/') {
    const slug = this.slug
    if (environment.env === 'dev') {
      return `http://localhost:5555${path}`
    } else if (environment.env === 'staging') {
      return `https://${slug}.staging.patientpal.com${path}`
    } else {
      return `https://${slug}.patientpal.com${path}`
    }
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      slug: this.slug,
      pinCode: this.pinCode,
      phoneNumber: standardizePhone(this.phoneNumber),
      languages: this.languages,
      image: this.image,
      twilioNumber: standardizePhone(this.twilioNumber),
      website: this.website,
      provider: cloneDeep(this.provider),
      pricer: cloneDeep(this.pricer),
      socialNetwork: cloneDeep(this.socialNetwork),
      paymentProvider: cloneDeep(this.paymentProvider),
      options: cloneDeep(this.options),
      modules: cloneDeep(this.modules),
      modulesPreferences: cloneDeep(this.modulesPreferences),
      settings: cloneDeep(this.settings),
    }
  }

  clone(): Clinic {
    return new Clinic(this.toJSON())
  }
}

import { SelectOption } from '../../classes'
import { REFERRAL_STATUS } from './referral-status-type'

export const ReferralStatuses: SelectOption<REFERRAL_STATUS>[] = [
  {
    value: REFERRAL_STATUS.AWAITING_PATIENT,
    viewValue: 'statuses.referrals.PP',
  },
  {
    value: REFERRAL_STATUS.AWAITING_REFERRER,
    viewValue: 'statuses.referrals.PRF',
  },
  {
    value: REFERRAL_STATUS.AWAITING_AUTHORIZATION,
    viewValue: 'statuses.referrals.PA',
  },
  {
    value: REFERRAL_STATUS.AWAITING_REVIEW,
    viewValue: 'statuses.referrals.PRV',
  },
  {
    value: REFERRAL_STATUS.ACCEPTED,
    viewValue: 'statuses.referrals.A',
  },
  {
    value: REFERRAL_STATUS.DECLINED,
    viewValue: 'statuses.referrals.D',
  },
  {
    value: REFERRAL_STATUS.SCHEDULED,
    viewValue: 'statuses.referrals.S',
  },
  {
    value: REFERRAL_STATUS.COMPLETED,
    viewValue: 'statuses.referrals.CO',
  },
  {
    value: REFERRAL_STATUS.CANCELLED,
    viewValue: 'statuses.referrals.CA',
  },
]

import { Injectable } from '@angular/core'
import { SelectOption } from '@mmx/shared'

import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  audioTracks: { [key: string]: HTMLAudioElement } = {}

  constructor(private authService: AuthService) {}

  load(trackOrEvent: AudioConfig.EVENT | string): void {
    const track = this.getTrack(trackOrEvent)
    if (track != null && this.audioTracks[track] == null) {
      this.audioTracks[track] = new Audio()
      this.audioTracks[track].src = `/assets/sounds/${track}.mp3`
      this.audioTracks[track].load()
    }
  }

  play(trackOrEvent: AudioConfig.EVENT | string) {
    const track = this.getTrack(trackOrEvent)
    if (track != null) {
      this.load(track)
      const play = this.audioTracks[track].play()

      play.catch(() => {
        // we ignore audio playback errors, because it isn't necessary
      })
    }
  }

  private getTrack(trackOrEvent: AudioConfig.EVENT | string): string | null {
    const audioTracks =
      this.authService.user?.settings?.notificationSounds ?? {}
    const track =
      audioTracks[trackOrEvent] ??
      AudioConfig.defaultTracks[trackOrEvent] ??
      trackOrEvent
    return typeof track === 'string' && track !== 'none' ? track : null
  }
}

export namespace AudioConfig {
  export enum EVENT {
    NEW_MESSAGE = 'newMessage',
    NEW_NOTE = 'newNote',
  }

  export const defaultTracks = {
    [EVENT.NEW_MESSAGE]: 'hi',
    [EVENT.NEW_NOTE]: 'none',
  }

  export const AudioTracks: SelectOption<string>[] = [
    { viewValue: 'Boing', value: 'boing' },
    { viewValue: 'Ding', value: 'ding' },
    { viewValue: 'Here You Go', value: 'here-you-go' },
    { viewValue: 'Hi!', value: 'hi' },
    { viewValue: 'Plink', value: 'plink' },
    { viewValue: 'Ta-da', value: 'ta-da' },
    { viewValue: 'Wow', value: 'wow' },
  ]
}

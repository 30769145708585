import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, switchMap, tap } from 'rxjs/operators'

import { AuthService, PermissionsService, RoutingService } from '../services'

@Injectable()
export class PermissionsGuard {
  constructor(
    private router: Router,
    private authSvc: AuthService,
    private routingSvc: RoutingService,
    private permissionsSvc: PermissionsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authSvc.changes
      .pipe(
        switchMap((user) => {
          if (!user?.roles) {
            return this.router.navigateByUrl('/auth/login')
          }

          if (!route.data.permission) {
            return of(true)
          }

          return this.permissionsSvc.hasPermission(route.data.permission)
        }),
        tap((hasPermission) => {
          if (hasPermission){
            return
          }

          this.router.navigateByUrl(this.routingSvc.getFirstAuthenticatedRoute())
        }),
        catchError(() => {
          return of(false)
        }),
      )
  }
}

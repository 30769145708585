type IntakeFile = 'insuranceCard' | string

export interface IImageCacheItem {
  url: string
  isUploaded?: boolean
}

export class ImageCache {
  isInitialized: boolean
  data: Map<IntakeFile, IImageCacheItem>

  constructor() {
    this.data = new Map<IntakeFile, IImageCacheItem>()
    this.isInitialized = false
  }

  get(key: IntakeFile) {
    return this.data.get(key)
  }

  set(key: IntakeFile, data: IImageCacheItem) {
    return this.data.set(key, data)
  }

  delete(key: IntakeFile) {
    return this.data.delete(key)
  }

  clear() {
    return this.data.clear()
  }
}

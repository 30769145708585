import { AbstractControl } from '@angular/forms'

export function unique(entities: string[]) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value ? control.value.toLocaleLowerCase() : null

    if (!value) {
      return null
    }

    if (entities.map((e) => e.toLocaleLowerCase()).includes(value)) {
      return { unique: { value: control.value } }
    }

    return null
  }
}

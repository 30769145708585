import { Injectable } from '@angular/core'
import { AttachPaymentMethodInterface } from '@mmx/components'
import { CreatePaymentIntentPayload } from '@mmx/shared'
import { PaymentIntent } from '@stripe/stripe-js'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { AuthenticatedRestService } from '../../core/services/auth-rest.service'

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends AuthenticatedRestService {

  createPaymentIntent(payload: CreatePaymentIntentPayload): Observable<PaymentIntent> {
    return this.post<PaymentIntent>('/payments/create-payment-intent', payload).pipe(
      map((resp) => resp.data),
    )
  }

  createSetupIntent(patientId: string) {
    return this.post<string>(`/payments/patients/${patientId}/setup-intent`, {}).pipe(
      map((response) => response.data),
    )
  }

  attachTilledPaymentMethodToCustomer(id: string, patientId: string) {
    const route = `/payments-tilled/methods/attach`
    return this.put<AttachPaymentMethodInterface>(route, { id, patientId }).pipe(
      map((resp) => {
        return resp.data
      }),
    )
  }
}

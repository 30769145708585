import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  InsuranceProviderConfigurationData,
  InsuranceProviderConfigurationModel,
  RestService,
} from '@mmx/shared'
import { BehaviorSubject, catchError, finalize, map, of, ReplaySubject, switchMap, tap } from 'rxjs'

export interface GetInsuranceCarrierConfigurationParams {
  providerLookupId: string
  type: string
  payerId: string
  subtypeId?: string
}

@Injectable({
  providedIn: 'root',
})
export class InsuranceProviderConfigurationService {
  private readonly baseUrl = '/insurances/payers/configuration'

  public isLoading$ = new BehaviorSubject<boolean>(false)
  public configurationParams$ = new ReplaySubject<GetInsuranceCarrierConfigurationParams>(1)
  public configurationError$ = new BehaviorSubject<string>(null)

  public configuration$ = this.configurationParams$.pipe(
    tap(() => {
      this.isLoading$.next(true)
      this.configurationError$.next(null)
    }),
    switchMap((params) => this.getInsuranceProviderConfiguration(params).pipe(
      finalize(() => this.isLoading$.next(false)),
      catchError((e) => {
        this.configurationError$.next(e.error.message)
        return of(null)
      }),
    )),
  )

  constructor(
    private restService: RestService,
  ) {}

  private getInsuranceProviderConfiguration(params: GetInsuranceCarrierConfigurationParams) {
    const httpParams = new HttpParams({ fromObject: params as Record<string, any> })

    return this.restService.get<InsuranceProviderConfigurationData>(this.baseUrl, httpParams)
      .pipe(
        map((response) => new InsuranceProviderConfigurationModel(response.data)),
      )
  }
}

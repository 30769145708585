import { SelectOption } from '@mmx/shared'

import { SCHEDULING_RULE_GROUP_TYPE } from '../models'

export const SchedulingRuleGroupTypes: SelectOption<string>[] = [
  {
    value: SCHEDULING_RULE_GROUP_TYPE.DEFAULT,
    viewValue: 'scheduling-rule-group.types.default',
  },
  {
    value: SCHEDULING_RULE_GROUP_TYPE.CUSTOM,
    viewValue: 'scheduling-rule-group.types.custom',
  },
]

import { SelectOption } from '@mmx/shared'

// https://en.wikipedia.org/wiki/ISO_4217

export const Currencies: SelectOption<string>[] = [
  { value: 'USD', viewValue: 'United States dollar (USD)' },
  { value: 'CAD', viewValue: 'Canadian dollar (CAD)' },
  { value: 'EUR', viewValue: 'Euro (EUR)' },
  // { value: 'MXN', viewValue: 'Mexican peso (MXN)' },
]

import { Injectable } from '@angular/core'
import { ShoppableServiceLabel, toData } from '@mmx/shared'
import { orderBy } from 'lodash'
import { BehaviorSubject, map } from 'rxjs'
import { tap } from 'rxjs/operators'

import { AuthenticatedRestService } from './auth-rest.service'

@Injectable()
export class ShoppableServicesLabelsService extends AuthenticatedRestService {

  public labels$ = new BehaviorSubject<ShoppableServiceLabel[]>([])
  public topTierLabels$ = this.labels$.pipe(
    map((labels) => labels.filter((label) => label.topTier)),
  )
  public otherLabels$ = this.labels$.pipe(
    map((labels) => labels.filter((label) => !label.topTier)),
  )

  private readonly route = '/shoppable-services/labels'

  public getLabels() {
    return this.get<ShoppableServiceLabel[]>(this.route).pipe(
      map(toData),
      tap((labels) => this.labels$.next(labels)),
    )
  }

  public createLabel(label: Partial<ShoppableServiceLabel>) {
    return this.post<ShoppableServiceLabel>(this.route, label).pipe(
      map(toData),
      tap((newLabel) => {
        const labels = this.labels$.value
        this.labels$.next(orderBy([...labels, newLabel], 'sortOrder'))
      }),
    )
  }

  public updateLabel(id: string, label: Partial<ShoppableServiceLabel>) {
    return this.patch<ShoppableServiceLabel>(`${this.route}/${id}`, label).pipe(
      map(toData),
      tap((updatedLabel) => {
        const labels = this.labels$.value
        this.labels$.next(labels.map((label) => label.id === updatedLabel.id ? updatedLabel : label ))
      }),
    )
  }

  public updateLabelsOrder() {
    return this.put<ShoppableServiceLabel[]>(this.route, { labels: this.labels$.value }).pipe(
      map(toData),
      tap((labels) => this.labels$.next(labels)),
    )
  }

  public deleteLabel(id: string) {
    return this.delete(`${this.route}/${id}`).pipe(
      tap(() => {
        const labels = this.labels$.value
        this.labels$.next(labels.filter((label) => label.id !== id))
      }),
    )
  }
}

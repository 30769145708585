export namespace AvailityServiceReview {

  export const principalDiagnosisQualifierCode = 'ABK'
  export const icd10DiagnosisQualifierCode = 'ABF'

  export enum RenderingProviderRoleCode {
    Facility = 'FA',
    Clinic = 'G3',
    AttendingPhysician = '71',
    OrderingPhysician = 'DK',
    PrimaryCareProvider = 'P3',
    ReferringProvider = 'DN',
  }

  export interface CodeValue {
    code: string
    value: string
  }

  export interface ValidationMessage {
    /**
     * The field or parameter associated with this error.
     */
    field: string
    /**
     * A code for the error.
     */
    code: string
    /**
     * The message associated with this error.
     */
    errorMessage: string
    /**
     * The array index of the item associated with this error.
     */
    index: number
  }

  export interface Note {
    /**
     * The type of note.
     */
    type: string
    /**
     * The type code of the note.
     */
    typeCode: string
    /**
     * The note message.
     */
    message: string
  }

  export interface Payer {
    /**
     * Service review payer id
     */
    id: string
    /**
     * Service review payer name
     */
    name: string
    /**
     * Full Name
     */
    contactName: string
    /**
     * Phone Number
     */
    phone: string
    /**
     * Extension
     */
    extension: string
    /**
     * Fax
     */
    fax: string
    /**
     * E-mail Address
     */
    emailAddress: string
    /**
     * Website URL
     */
    url: string
  }

  export interface Location {
    /**
     * The street line 1.
     */
    addressLine1: string
    /**
     * The street line 2.
     */
    addressLine2: string
    /**
     * The city.
     */
    city: string
    /**
     * The state.
     */
    state: string
    /**
     * The state code.
     */
    stateCode: string
    /**
     * The ZIP code.
     */
    zipCode: string
  }

  export interface Provider extends Location {
    /**
     * The last name of an individual provider or the name of an organizational provider.
     */
    lastName: string
    /**
     * The first name of an individual provider.
     */
    firstName: string
    /**
     * The middle name of an individual provider.
     */
    middleName: string
    /**
     * The suffix of an individual provider.
     */
    suffix: string
    /**
     * The NPI of a provider.
     */
    npi: string
    /**
     * The tax id of a provider.
     */
    taxId: string
    /**
     * The payer assigned provider id of the provider.
     */
    payerAssignedProviderId: string
    /**
     * The speciality of a provider.
     */
    specialty: string
    /**
     * The speciality code of a provider.
     */
    specialtyCode: string
    /**
     * Full Name
     */
    contactName: string
    /**
     * Phone Number
     */
    phone: string
    /**
     * Phone Extension
     */
    extension: string
    /**
     * fax
     */
    fax: string
    /**
     * E-mail Address
     */
    emailAddress: string
    /**
     * Website URL
     */
    url: string
  }

  export interface RequestingProvider extends Provider {
    /**
     * The payer assigned provider reference number.
     */
    submitterId: string
  }

  export interface RenderingProvider extends Provider {
    /**
     * The role of a provider.
     */
    role: string
    /**
     * The role code of a provider.
     */
    roleCode: string
  }

  export interface Subscriber extends Location {
    /**
     * The subscriber's first name.
     */
    firstName: string
    /**
     * The subscriber's middle name.
     */
    middleName: string
    /**
     * The subscriber's last name.
     */
    lastName: string
    /**
     * The subscriber's suffix.
     */
    suffix: string
    /**
     * The health plan member id of the subscriber.
     */
    memberId: string
  }

  export interface Patient extends Location {
    /**
     * The patient's first name.
     */
    firstName: string
    /**
     * The patient's middle name.
     */
    middleName: string
    /**
     * The patient's last name.
     */
    lastName: string
    /**
     * The patient's suffix.
     */
    suffix: string
    /**
     * The patient's relationship to the subscriber or policy holder.
     */
    subscriberRelationship: string
    /**
     * The patient's relationship code to the subscriber or policy holder.
     */
    subscriberRelationshipCode: string
    /**
     * The patient's date of birth.
     */
    birthDate: string
    /**
     * The patient's gender.
     */
    gender: string
    /**
     * The patient's gender code.
     */
    genderCode: string
    /**
     * The patient's status
     */
    status: string
    /**
     * The patient's status code
     */
    statusCode: string
    /**
     * The condition
     */
    condition: string
    /**
     * The condition code
     */
    conditionCode: string
    /**
     * The patient is covered by Medicare
     */
    medicareCoverage: boolean
    /**
     * The prognosis
     */
    prognosis: string
    /**
     * The prognosis code
     */
    prognosisCode: string
  }

  export interface Diagnosis {
    /**
     * The qualifier for the diagnosis.
     */
    qualifier: string
    /**
     * The qualifier code for the diagnosis.
     */
    qualifierCode: string
    /**
     * The value or description of the diagnosis.
     */
    value: string
    /**
     * The code for the diagnosis.
     */
    code: string
    /**
     * The date the diagnosis was reached.
     */
    date: string
  }

  export interface TransportLocation extends Location {
    /**
     * The name of the transport location
     */
    name: string
  }

  export interface Procedure {
    /**
     * for institutional procedure the revenue value of the procedure.
     */
    revenueValue: string
    /**
     * for institutional procedure the revenue code of the procedure.
     */
    revenueCode: string
    /**
     * the qualifier for the procedure.
     */
    qualifier: string
    /**
     * the qualifier code for the procedure.
     */
    qualifierCode: string
    /**
     * the value or description for the procedure.
     */
    value: string
    /**
     * the code for the procedure.
     */
    code: string
    /**
     * modifier for the procedure
     */
    modifier1: string
    /**
     * modifier code for the procedure
     */
    modifierCode1: string
    /**
     * modifier for the procedure
     */
    modifier2: string
    /**
     * modifier code for the procedure
     */
    modifierCode2: string
    /**
     * modifier for the procedure
     */
    modifier3: string
    /**
     * modifier code for the procedure
     */
    modifierCode3: string
    /**
     * modifier for the procedure
     */
    modifier4: string
    /**
     * modifier code for the procedure
     */
    modifierCode4: string
    /**
     * description of the procedure.
     */
    description: string
    /**
     * the quantity of the procedure.
     */
    quantity: string
    /**
     * the unit of measure for quantity of the procedure.
     */
    quantityType: string
    /**
     * the code for the unit of measure for quantity of the procedure.
     */
    quantityTypeCode: string
    serviceQuantity: "1"
    serviceQuantityType: "Units"
    serviceQuantityTypeCode: "FL"
    /**
     * The date the procedure started.
     */
    fromDate: string
    /**
     * The date the procedure ended.
     */
    toDate: string
    /**
     * the status of the procedure.
     */
    status: string
    /**
     * the status code of the procedure.
     */
    statusCode: string
    /**
     * Status reasons
     */
    statusReasons: CodeValue[]
    /**
     * The date the authorization was issued.
     */
    certificationIssueDate: string
    /**
     * The date the authorization became effective.
     */
    certificationEffectiveDate: string
    /**
     * The date the authorization expired.
     */
    certificationExpirationDate: string
    /**
     * the authorization number.
     */
    certificationNumber: string
    /**
     * Trace numbers added to this Service Review by the payer.
     */
    traceNumbers: string[]
    /**
     * Notes, disclaimers, and descriptions.
     */
    notes: Note[]
  }

  export interface Attachment {
    /**
     * The attachment file name.
     */
    fileName: string
    /**
     * The id number of the attachments.
     */
    id: string
    /**
     * The id type for the attachment.
     */
    idType: string
    /**
     * The date the attachment info was received.
     */
    dateReceived: string
  }

  export interface SupplementalInformation {
    /**
     * The attachments information.
     */
    attachments: Attachment[]
    /**
     * The diagnosis information.
     */
    diagnoses: Diagnosis[]
    /**
     * The procedure information.
     */
    procedures: Diagnosis[]
    /**
     * The authorization or referral number.
     */
    refAuthNumber: string
    /**
     * The sequence number.
     */
    sequence: string
    /**
     * The update type.
     */
    updateType: string
  }

  export interface Review {
    /**
     * The service review id.
     */
    id: string
    /**
     * The Availity Customer ID of the customer that owns this service review.
     */
    customerId: string
    /**
     * The control number of the X12 request that this service review was created from.
     */
    controlNumber: string
    /**
     * The User ID of the customer that owns this service review.
     */
    userId: string
    /**
     * short form indicator
     */
    shortFormIndicator: boolean
    /**
     * Indicates if this service review is updateable.
     */
    updatable: boolean
    /**
     * Indicates if this service review is deletable.
     */
    deletable: boolean
    /**
     * Updatable fields.
     */
    updatableFields: string[]
    /**
     * The status of the service review.
     */
    status: string
    /**
     * The status code of the service review.
     */
    statusCode: string
    /**
     * Status reasons
     */
    statusReasons: CodeValue[]
    /**
     * The date this coverage entry was created.
     */
    createdDate: string
    /**
     * The date when this coverage entry was last updated.
     */
    updatedDate: string
    /**
     * The date when this coverage entry expires.
     */
    expirationDate: string
    /**
     * Messages after validation.
     */
    validationMessages: ValidationMessage[]
    /**
     * Notes from the provider.
     */
    providerNotes: Note[]
    /**
     * Notes from the payer.
     */
    payerNotes: Note[]
    payer: Payer
    requestingProvider: RequestingProvider
    subscriber: Subscriber
    patient: Patient
    /**
     * Diagnoses.
     */
    diagnoses: Diagnosis[]
    /**
     * The date this service review was created.
     */
    certificationIssueDate: string
    /**
     * The date this service review became effective.
     */
    certificationEffectiveDate: string
    /**
     * The date this service review expires.
     */
    certificationExpirationDate: string
    /**
     * The service review number.
     */
    certificationNumber: string
    /**
     * The reference number.
     */
    referenceNumber: string
    /**
     * Trace numbers added to this Service Review by the payer.
     */
    traceNumbers: string[]
    /**
     * type of request.
     */
    requestType: string
    /**
     * request type code
     */
    requestTypeCode: string
    /**
     * type of service.
     */
    serviceType: string
    /**
     * type code.
     */
    serviceTypeCode: string
    /**
     * Additional service types.
     */
    additionalServiceTypes: CodeValue[]
    /**
     * place of service.
     */
    placeOfService: string
    /**
     * place of service code.
     */
    placeOfServiceCode: Availity.PlaceOfService
    /**
     * level of service.
     */
    serviceLevel: string
    /**
     * level of service code.
     */
    serviceLevelCode: string
    /**
     * The date the service started.
     */
    fromDate: string
    /**
     * The date the service ended.
     */
    toDate: string
    /**
     * the quantity of the procedure.
     */
    quantity: string
    /**
     * the unit of measure for quantity of the procedure.
     */
    quantityType: string
    /**
     * the code for the unit of measure for quantity of the procedure.
     */
    quantityTypeCode: string
    /**
     * the admission type.
     */
    admissionType: string
    /**
     * the admission type code.
     */
    admissionTypeCode: string
    /**
     * the admission source.
     */
    admissionSource: string
    /**
     * the admission source code.
     */
    admissionSourceCode: string
    /**
     * The nursing home residential status.
     */
    nursingHomeResidentialStatus: string
    /**
     * The nursing home residential status code.
     */
    nursingHomeResidentialStatusCode: string
    /**
     * The date the home health service started.
     */
    homeHealthStartDate: string
    /**
     * The date the home health service started.
     */
    homeHealthCertificationPeriodStartDate: string
    /**
     * The date the home health service started.
     */
    homeHealthCertificationPeriodEndDate: string
    /**
     * The type of medically related tranport.
     */
    transportType: string
    /**
     * The type code of medically related tranport.
     */
    transportTypeCode: string
    /**
     * The distance of medically related tranport.
     */
    transportDistance: string
    /**
     * The purpose of medically related tranport.
     */
    transportPurpose: string
    /**
     * The chiropractic treatment count.
     */
    chiropracticTreatmentCount: string
    /**
     * The beginning subluxation level.
     */
    beginningSubluxationLevel: string
    /**
     * The beginning subluxation level code.
     */
    beginningSubluxationLevelCode: string
    /**
     * The ending subluxation level.
     */
    endingSubluxationLevel: string
    /**
     * The ending subluxation level code.
     */
    endingSubluxationLevelCode: string
    /**
     * The spinal condition.
     */
    spinalCondition: string
    /**
     * The spinal condition code.
     */
    spinalConditionCode: string
    /**
     * A description of the spinal condition.
     */
    spinalConditionDescription: string
    /**
     * The oxygen equipment type.
     */
    oxygenEquipmentType: string
    /**
     * The oxygen equipment type code.
     */
    oxygenEquipmentTypeCode: string
    /**
     * The oxygen flow rate.
     */
    oxygenFlowRate: string
    /**
     * The oxygen daily use count.
     */
    oxygenDailyUseCount: string
    /**
     * The oxygen use period hour count.
     */
    oxygenUsePeriodHourCount: string
    /**
     * The oxygen order text.
     */
    oxygenOrderText: string
    /**
     * The oxygen delivery system type.
     */
    oxygenDeliverySystemType: string
    /**
     * The oxygen delivery system type code.
     */
    oxygenDeliverySystemTypeCode: string
    /**
     * The locations of medically related transport.
     */
    transportLocations: TransportLocation[]
    /**
     * The medical procedures to be performed.
     */
    procedures: Procedure[]
    /**
     * The rendering providers to perform the procedures.
     */
    renderingProviders: RenderingProvider[]
    supplementalInformation: SupplementalInformation
  }
}

export namespace Availity {
  export enum PlaceOfService {
    PHARMACY = '01',
    SCHOOL = '03',
    HOMELESS_SHELTER = '04',
    INDIAN_HEALTH_SERVICE_FREE_STANDING_FACILITY = '05',
    INDIAN_HEALTH_SERVICE_PROVIDER_BASED_FACILITY = '06',
    TRIBAL_638_FREE_STANDING_FACILITY = '07',
    TRIBAL_638_PROVIDER_BASED_FACILITY = '08',
    OFFICE = '11',
    HOME = '12',
    ASSISTED_LIVING_FACILITY = '13',
    GROUP_HOME = '14',
    MOBILE_UNIT = '15',
    OFF_CAMPUS_OUTPATIENT_HOSPITAL = '19',
    URGENT_CARE_FACILITY = '20',
    INPATIENT_HOSPITAL = '21',
    ON_CAMPUS_OUTPATIENT_HOSPITAL = '22',
    EMERGENCY_ROOM_HOSPITAL = '23',
    AMBULATORY_SURGICAL_CENTER = '24',
    BIRTHING_CENTER = '25',
    MILITARY_TREATMENT_FACILITY = '26',
    SKILLED_NURSING_FACILITY = '31',
    NURSING_FACILITY = '32',
    CUSTODIAL_CARE_FACILITY = '33',
    HOSPICE = '34',
    AMBULANCE_LAND = '41',
    AMBULANCE_AIR_OR_WATER = '42',
    INDEPENDENT_CLINIC = '49',
    FEDERALLY_QUALIFIED_HEALTH_CENTER = '50',
    INPATIENT_PSYCHIATRIC_FACILITY = '51',
    PSYCHIATRIC_FACILITY_PARTIAL_HOSPITALIZATION = '52',
    COMMUNITY_MENTAL_HEALTH_CENTER = '53',
    INTERMEDIATE_CARE_FACILITY_MENTALLY_RETARDED = '54',
    RESIDENTIAL_SUBSTANCE_ABUSE_TREATMENT_FACILITY = '55',
    PSYCHIATRIC_RESIDENTIAL_TREATMENT_CENTER = '56',
    NON_RESIDENTIAL_SUBSTANCE_ABUSE_TREATMENT_FACILITY = '57',
    MASS_IMMUNIZATION_CENTER = '60',
    COMPREHENSIVE_INPATIENT_REHABILITATION_FACILITY = '61',
    COMPREHENSIVE_OUTPATIENT_REHABILITATION_FACILITY = '62',
    END_STATE_RENAL_DISEASE_TREATMENT_FACILITY = '65',
    PUBLIC_HEALTH_CLINIC = '71',
    RURAL_HEALTH_CLINIC = '72',
    INDEPENDENT_LABORATORY = '81',
    OTHER_PLACE_OF_SERVICE = '99',
  }
}

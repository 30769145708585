import { BaseModel, BaseModelData } from '@mmx/shared'

export interface WorklistSessionData extends BaseModelData {
}

export class WorklistSession extends BaseModel {
  constructor(data?: WorklistSessionData) {
    super(data, true)
  }

  toJSON() {
    return {
      id: this.id,
    }
  }
}

import { BaseModel, BaseModelData, Breadcrumb, ModelDate } from '@mmx/shared'

import { WorklistAutomation } from './worklist/automation'
import { WorklistField } from './worklist/field'
import { WorklistFilter } from './worklist/filter'
import { WorklistWeightRule } from './worklist/weight-rule'

export interface WorklistData extends BaseModelData {
  name: string
  description: string
  referenceType: string
  filters: any[]
  exclusionWorkLists?: string[]
  fields: any[]
  weightRules: any[]
  automations?: any[]
  settings?: WorklistSettings
  users: string[]
  roles: string[]
  lastProcessedAt?: ModelDate
  distributionByDay?: Record<string, number>
}

export interface WorklistSettings {
  requireNotes?: boolean
  distinctPatients?: boolean
  escalation?: boolean
  escalationWorkLists?: string[]
  advancedAssignment?: boolean
  assignmentGroupingFields?: string[]
  revalidate?: { quantity: number, units: string }
  snooze?: { options: string[], required: boolean }
}

export class WorklistModel extends BaseModel implements Breadcrumb {
  name: string
  description: string
  referenceType: Worklist.REFERENCE_TYPE
  /**
   * Current number of items in the worklist
   */
  count: number
  filters: WorklistFilter[]
  exclusionWorkLists?: string[]
  fields: WorklistField[]
  weightRules: WorklistWeightRule[]
  automations: WorklistAutomation[]
  roles: string[]
  users: string[]
  settings: WorklistSettings
  lastProcessedAt?: moment.Moment
  distributionByDay?: Record<string, number>

  constructor(data?: WorklistData) {
    super(data, true)
    this.settings = data.settings || {}
    this.lastProcessedAt = this.transformDate(data.lastProcessedAt, false)
    this.distributionByDay = data.distributionByDay
  }

  get text(): string {
    return this.name
  }

  get url(): string {
    return `/worklist/${this.id}`
  }

  get editUrl(): string {
    return `/worklist/${this.id}/edit`
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      referenceType: this.referenceType,
      filters: this.filters,
      exclusionWorkLists: this.exclusionWorkLists,
      fields: this.fields,
      weightRules: this.weightRules,
      automations: this.automations,
      roles: this.roles,
      users: this.users,
      settings: this.settings,
    }
  }
}

export namespace Worklist {
  export type REFERENCE_TYPE = 'patient' | 'appointment' | 'arbitrary'
}

import { AddressSchema, BaseModelData, CRUD, CrudModel, PatientNameSchema } from '@mmx/shared'

export interface ReferrerData extends BaseModelData {
  clinicId: string
  name?: PatientNameSchema
  eid?: string
  npi?: string
  taxId?: string
  submitterId?: string
  payerAssignedId?: string
  speciality?: string
  email?: string
  phone?: string
  address?: AddressSchema
  status: Referrer.STATUS
  autoDeliver?: Referrer.AUTO_DELIVER[]
  facilities?: string[]
  notes?: string
}

@CRUD({
  base: '/crud/referrers',
})
export class ReferrerModel extends CrudModel {
  name: PatientNameSchema
  eid: string
  npi: string
  taxId: string
  submitterId: string
  payerAssignedId: string
  speciality: string
  email: string
  phone: string
  address: AddressSchema
  status: Referrer.STATUS
  autoDeliver: Referrer.AUTO_DELIVER[]
  facilities: string[]
  notes: string

  constructor(data: ReferrerData) {
    super(data)

    this.clinicId = data.clinicId
    this.name = data.name
    this.eid = data.eid
    this.npi = data.npi
    this.taxId = data.taxId
    this.submitterId = data.submitterId
    this.payerAssignedId = data.payerAssignedId
    this.speciality = data.speciality
    this.email = data.email
    this.address = data.address
    this.phone = data.phone
    this.status = data.status
    this.autoDeliver = data.autoDeliver || []
    this.facilities = data.facilities || []
    this.notes = data.notes
  }

  get shortName() {
    return `${this.name.family}, ${this.name.given}`.toUpperCase()
  }
}

export namespace Referrer {
  export enum STATUS {
    ACTIVE = 'A',
    INACTIVE = 'I',
  }

  export enum AUTO_DELIVER {
    PRELIMINARY_REPORT_VIA_FAX = 'PF',
    PRELIMINARY_REPORT_VIA_EMAIL = 'PE',
    FINAL_REPORT_VIA_FAX = 'FF',
    FINAL_REPORT_VIA_EMAIL = 'FE',
  }
}

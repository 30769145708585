import { Injectable } from '@angular/core'
import { AppConfirmService } from 'app/app-confirm'
import { Observable } from 'rxjs'

import { IsDirty } from './is-dirty.interface'

@Injectable()
export class IsDirtyService {
  constructor(protected confirmService: AppConfirmService) {}

  canDeactivate(component: IsDirty): boolean | Observable<boolean> {
    const isDirty = component?.isDirty()
    if (isDirty) {
      return this.confirmService.confirm({
        message: 'You have unsaved changed, are you sure you want to leave?',
      })
    } else {
      return true
    }
  }
}

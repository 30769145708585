import { HttpRequest } from '@angular/common/http'

export interface PaginationParamsI {
  pageSize: number
  pageIndex: number
  startAt: string
  start?: number
  end?: number
  total?: number
}

export class PaginationParams implements PaginationParamsI {
  pageSize: number
  pageIndex: number
  startAt: string
  total: number

  constructor(req: HttpRequest<unknown>) {
    Object.assign(this, {
      pageSize: parseInt(req.params.get('pageSize'), 10),
      pageIndex: parseInt(req.params.get('pageIndex'), 10),
      startAt: req.params.get('startAt'),
      total: req.params.get('total') && parseInt(req.params.get('total'), 10),
    })
  }

  get start(): number {
    return this.pageIndex * this.pageSize
  }

  get end(): number {
    return this.start + this.pageSize
  }
}

import { BaseModel, BaseModelData, Resource } from '@mmx/shared'

import { ProductModel } from './product.model'

export interface ShoppableServiceData extends BaseModelData {
  clinicId: string
  name: string
  facilityIds?: string[]
  labelIds?: string[]
  products?: string[]
  patientType?: ShoppableService.PATIENT_TYPE
  status: ShoppableService.STATUS
  notes?: string
  resources?: Partial<Resource>[]
  hidden?: boolean
}

export class ShoppableServiceModel extends BaseModel {
  clinicId: string
  name: string
  products?: string[]
  patientType: ShoppableService.PATIENT_TYPE
  status: ShoppableService.STATUS
  notes: string
  resources?: Partial<Resource>[]
  hidden?: boolean
  facilityIds?: string[]
  labelIds?: string[]

  constructor(data: ShoppableServiceData) {
    super(data)

    this.id = data.id
    this.clinicId = data.clinicId
    this.name = data.name
    this.products = data.products
    this.patientType = data.patientType
    this.status = data.status
    this.notes = data.notes
    this.resources = data.resources
    this.hidden = data.hidden
    this.facilityIds = data.facilityIds
    this.labelIds = data.labelIds
  }

}

export interface ShoppableServiceProductsResponse {
  product: ProductModel
  shoppableService?: {
    id: string
    name: string
  }
}

export namespace ShoppableService {
  export enum STATUS {
    ACTIVE = 'A',
    INACTIVE = 'I',
  }
  export enum PATIENT_TYPE {
    IN_PATIENT = 'IP',
    OUT_PATIENT = 'OP',
  }
}

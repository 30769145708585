import { AUTHORIZATION_STATUS, SelectOption } from '@mmx/shared'

const statusesInOrder: AUTHORIZATION_STATUS[] = [
  AUTHORIZATION_STATUS.NONE,
  AUTHORIZATION_STATUS.NOT_KNOWN,
  AUTHORIZATION_STATUS.NOT_FOUND,
  AUTHORIZATION_STATUS.NOT_INITIATED,
  AUTHORIZATION_STATUS.INITIATED_CASE_MEDICAL,
  AUTHORIZATION_STATUS.INITIATED_CASE_INSURANCE,
  AUTHORIZATION_STATUS.CONTACT_PAYER,
  AUTHORIZATION_STATUS.DENIED,
  AUTHORIZATION_STATUS.PEER_REVIEW,
  AUTHORIZATION_STATUS.INELIGIBLE,
  AUTHORIZATION_STATUS.APPROVED,
  AUTHORIZATION_STATUS.PARTIALLY_APPROVED,
  AUTHORIZATION_STATUS.NOT_REQUIRED,
  AUTHORIZATION_STATUS.NO_ACTION_REQUIRED,
  AUTHORIZATION_STATUS.CANCELLED,
  AUTHORIZATION_STATUS.COMPLETED,
  AUTHORIZATION_STATUS.EXPIRED,
  AUTHORIZATION_STATUS.OUTDATED,
]

export const AuthorizationStatuses: SelectOption<AUTHORIZATION_STATUS>[] = statusesInOrder.map(value => ({
  value,
  viewValue: `statuses.authorizations.${value}`,
}))

export function getAuthorizationStatusesWithout(without: AUTHORIZATION_STATUS[]) {
  return AuthorizationStatuses.filter(option => !without.includes(option.value))
}

import { Injectable } from '@angular/core'
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar'
import { PatientPalRestError } from '@mmx/shared'
import { extend, get, isNil, isString, without } from 'lodash'
import { BehaviorSubject } from 'rxjs'

export interface SnackBarOptions {
  message: string
  panelClass?: 'success' | 'warn'
  duration?: null | number
  action?: string
  config?: MatSnackBarConfig
}

export class AsyncProcess {
  id: number
  snackRef: MatSnackBarRef<SimpleSnackBar>
  inProgress = false
  timeout = 10000
  timer: number // NodeJS.Timer

  constructor(private progressService: ProgressIndicatorService) {
    this.id = Date.now()
  }

  start(): AsyncProcess {
    this.inProgress = true
    this.progressService.start(this)
    this.timer = setTimeout(() => this.stop(), this.timeout) as any
    return this
  }

  stop(snackBarOptions?: string | SnackBarOptions): AsyncProcess {
    clearTimeout(this.timer)
    this.inProgress = false
    this.progressService.stop(this)

    if (snackBarOptions) {
      this.progressService.snack(snackBarOptions)
    }

    return this
  }

  fail(message: string): AsyncProcess {
    clearTimeout(this.timer)
    this.inProgress = false
    this.progressService.stop(this)
    this.progressService.snack({
      message,
      action: 'Dismiss',
      config: {
        duration: undefined,
        panelClass: 'error',
      },
    })

    return this
  }
}

@Injectable()
export class ProgressIndicatorService {
  // public enabled: Observable<boolean>
  public subject: BehaviorSubject<boolean>
  public catchError: (err: PatientPalRestError) => void

  // private _enabled = false
  private requests: AsyncProcess[] = []

  constructor(private snackBar: MatSnackBar) {
    // this.enabled = this.subject.asObservable()
    this.subject = new BehaviorSubject<boolean>(false)

    this.catchError = (err: PatientPalRestError) => {
      this.error(err)
    }
  }

  error(err: PatientPalRestError) {
    const errorMessage =
      get(err, 'error.errors[0].message') ||
      err.error.message ||
      'An error occurred. Please try again.'
    this.snack({
      message: errorMessage,
      panelClass: 'warn',
    })
  }

  async(): AsyncProcess {
    return new AsyncProcess(this)
  }

  start(process?: AsyncProcess): AsyncProcess {
    if (isNil(process)) {
      process = this.async()
    }

    this.requests.push(process)

    // if (!this._enabled) {
    this.subject.next(true)
    // this._enabled = true
    // }

    return process
  }

  stop(process: AsyncProcess): AsyncProcess {
    this.requests = without(this.requests, process)

    if (this.requests.length === 0) {
      this.subject.next(false)
      // this._enabled = false
    }

    return process
  }

  snack(snackBarOptions: SnackBarOptions | string) {
    let options: SnackBarOptions

    if (isString(snackBarOptions)) {
      options = {
        message: snackBarOptions as string,
      }
    } else {
      options = snackBarOptions as SnackBarOptions
    }

    let duration = 10000

    if (options.panelClass === 'warn' && options.duration == null) {
      if (options.action == null) {
        options.action = 'Dismiss'
      }
      duration = undefined
    } else if (options.duration != null) {
      duration = options.duration
    }

    return this.snackBar.open(
      options.message,
      options.action,
      extend(
        {
          duration,
          horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
          verticalPosition: 'top', // 'top' | 'bottom'
        },
        options.config,
      ),
    )
  }

  // wrap<T>(observable: Observable<T>): Subject<T> {
  //   this.enabled = true

  //   const shared = observable.pipe(share())

  //   shared.subscribe(
  //     (resp) => {
  //       this.enabled = false
  //     },
  //     (err) => {
  //       this.enabled = false
  //     },
  //   )

  //   return subject
  // }
}

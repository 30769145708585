<div mat-dialog-title fxLayoutAlign="center center">
  <mat-icon [color]="data.iconColor || 'warn'">{{
    data.icon || 'error_outline'
  }}</mat-icon>
</div>

<mat-dialog-content>
  <div [innerHTML]="data.message | translate"></div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <div fxFlex *ngIf="data.hideNoButton"></div>
  <button
    *ngIf="!data.hideNoButton"
    mat-stroked-button
    (click)="dialogRef.close(false)"
  >
    {{ (data.noBtnLabel | translate) || ('common.no' | translate) }}
  </button>
  <button
    #confirmButton
    cdkFocusInitial
    mat-flat-button
    color="warn"
    (click)="dialogRef.close(true)"
  >
    {{ (data.yesBtnLabel | translate) || ('common.yes' | translate) }}
  </button>
</mat-dialog-actions>

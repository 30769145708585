import { BaseModel, BaseModelData, SelectOption } from '@mmx/shared'
import { extend, filter, find, map, without } from 'lodash'

import { Price, PriceData } from './price.model'

export interface ProductData extends BaseModelData {
  type: string
  sku: string
  eid?: string
  /**
   * @deprecated
   * Use `codes` instead
  */
  code?: string
  codes?: string[]
  modifiers?: string[] | Record<string, string[]>
  serviceCodes?: string[]
  recommendedServiceCodes?: string[]
  usingRecommendServiceCodes?: boolean
  name?: string
  description?: string
  categoryId?: string
  categories?: string[]
  defaultDuration?: number
  price?: PriceData
  prices?: PriceData[]
  resources?: string[]
}

export class ProductModel extends BaseModel {
  type: Product.TYPE
  sku: string
  eid: string
  /**
   * @deprecated
   * Use `codes` instead
  */
  code: string
  codes?: string[]
  modifiers?: string[] | Record<string, string[]>
  serviceCodes?: string[]
  recommendedServiceCodes?: string[]
  usingRecommendServiceCodes?: boolean
  name?: string
  description?: string
  categoryId: string
  categories: string[]
  defaultDuration?: number
  price?: Price
  prices?: Price[]
  resources?: string[]

  constructor(data?: ProductData) {
    super(data)

    if (data) {
      this.type = data.type as Product.TYPE
      this.sku = data.sku
      this.eid = data.eid
      this.code = data.code
      this.codes = data.codes ?? (data.code ? [data.code] : [])
      this.modifiers = data.modifiers
      this.serviceCodes = data.serviceCodes
      this.recommendedServiceCodes = data.recommendedServiceCodes
      this.usingRecommendServiceCodes = data.usingRecommendServiceCodes
      this.name = data.name
      this.description = data.description
      this.categoryId = data.categoryId
      this.categories = data.categories ?? []
      this.defaultDuration = data.defaultDuration
      this.resources = data.resources

      if (this.categoryId != null) {
        this.categories = [
          this.categoryId,
          ...without(this.categories, this.categoryId),
        ]
      }

      if (data.price) {
        this.price = new Price(data.price)
      }

      if (data.prices) {
        this.prices = map(data.prices, (priceData) => {
          priceData.productId = this.id
          return new Price(priceData)
        })
      }
    }
  }

  get typeDisplay(): string {
    const type = this.type || Product.TYPE.MEDICAL_PROCEDURE
    const option = find(ProductTypes, (opt) => {
      return opt.value === type
    })
    return option ? option.viewValue : ''
  }

  get url() {
    return `/settings/products/${this.id}`
  }

  get codeDisplay(): string {
    if (this.codes) {
      return this.codes.map((code) => {
        if (this.modifiers?.[code]) {
          return `${code} (${this.modifiers[code].join('')})`
        }

        return code
      }).join(', ')
    }

    // fallback to old code
    let code = this.code || this.sku || ''
    if (Array.isArray(this.modifiers) && this.modifiers?.length > 0) {
      code += `-${this.modifiers.join('')}`
    }
    return code
  }

  get searchTerms(): string {
    return filter([
      this.name.toLocaleLowerCase(),
      this.code,
      this.sku.toLowerCase(),
    ]).join(' ')
  }

  getCPTCodes(): string[] {
    if (!!this.codes?.length) {
      return this.codes
    }

    if (!!this.code) {
      return [this.code]
    }

    return []
  }

  toJSON() {
    return extend(super.toJSON(), {
      type: this.type,
      sku: this.sku,
      eid: this.eid,
      code: this.code,
      codes: this.codes,
      modifiers: this.modifiers,
      serviceCodes: this.serviceCodes,
      usingRecommendServiceCodes: this.usingRecommendServiceCodes,
      name: this.name,
      description: this.description,
      categoryId: this.categoryId,
      categories: this.categories,
      defaultDuration: this.defaultDuration,
      resources: this.resources,
    })
  }
}

export namespace Product {
  export enum TYPE {
    MEDICAL_PROCEDURE = 'M',
    DENTAL_PROCEDURE = 'D',
    SERVICE = 'S',
    PRODUCT = 'P',
  }
}

export const ProductTypes: SelectOption<string>[] = [
  { value: Product.TYPE.MEDICAL_PROCEDURE, viewValue: 'Medical Procedure' },
  { value: Product.TYPE.DENTAL_PROCEDURE, viewValue: 'Dental Procedure' },
  { value: Product.TYPE.SERVICE, viewValue: 'Service' },
  { value: Product.TYPE.PRODUCT, viewValue: 'Product' },
]

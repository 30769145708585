import { BaseModelData, CRUD, CrudModel } from '@mmx/shared'

import { EstimateCharge } from './estimate-charge'

export interface EstimateTemplateData extends BaseModelData {
  name: string
  facilityId: string
  charges?: EstimateCharge[]
}

@CRUD({
  base: '/crud/estimate-templates',
})
export class EstimateTemplateModel extends CrudModel {
  name: string
  facilityId: string
  charges?: EstimateCharge[]

  constructor(data?: EstimateTemplateData) {
    super(data, true)
  }

  override toJSON() {
    return Object.assign(super.toJSON(), {
      name: this.name,
      facilityId: this.facilityId,
      charges: this.charges,
    })
  }

  get hasPBCharges() {
    return this.charges?.some(charge => charge.type === 'pb')
  }

  get hasHBCharges() {
    return this.charges?.some(charge => charge.type === 'hb')
  }
}

<div
  *ngIf="app.logoSrc != null; else mmxLogo"
  fxLayout="row"
  fxLayoutAlign="center center"
  [class]="'logo-container ' + app.site"
>
  <ng-container *ngIf="app.site === 'healthlogix'; else imageLogo">
    <div [class]="'logo-background ' + app.site"></div>
    <span class="health">Health</span>
    <div class="logix">
      <div class="bg"></div>
      <!-- <div class="arrowhead"></div> -->
      <div class="arrowshaft above" [class.paused]="paused"></div>
      <span>Logix</span>
      <div class="arrowshaft below" [class.paused]="paused"></div>
    </div>
  </ng-container>
  <ng-template #imageLogo>
    <div [class]="'logo-background ' + app.site"></div>
    <img [src]="app.logoSrc" [class]="'logo ' + app.site" fxFlex />
  </ng-template>
</div>
<ng-template #mmxLogo>
  <div class="spinner" [ngClass]="classes">
    <svg
      width="30"
      height="30"
      viewBox="0 0 120 120"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="logo-space">
          <circle r="60" cx="60" cy="60"/>
        </clipPath>
      </defs>

      <g class="group" clip-path="url(#logo-space)">
        <circle class="circle" r="60" cx="60" cy="60"/>
        <path class="left" transform="translate(16, 31)" d="M0,59.1h18.6l19.6-19.5c5.6-5.6,5.6-14.6,0-20.2L18.7,0H0l29.6,29.6L0,59.1z"/>
        <path class="right" transform="translate(16, 31)" d="M89.8,0H71.2L51.6,19.5c-5.6,5.6-5.6,14.6,0,20.2l19.5,19.5h18.7L60.2,29.6L89.8,0z"/>
      </g>
    </svg>
  </div>
</ng-template>

export interface ServiceType {
  code: string
  name: string
}

export const ServiceTypes: ServiceType[] = [
  { code: '1', name: 'medical_care' },
  { code: '2', name: 'surgical' },
  { code: '3', name: 'consultation' },
  { code: '4', name: 'diagnostic_x_ray' },
  { code: '5', name: 'diagnostic_lab' },
  { code: '6', name: 'radiation_therapy' },
  { code: '7', name: 'anesthesia' },
  { code: '8', name: 'surgical_assistance' },
  { code: '9', name: 'other_medical' },
  { code: '10', name: 'blood_charges' },
  { code: '11', name: 'used_medical_equipment' },
  { code: '12', name: 'medical_equipment_purchase' },
  { code: '13', name: 'ambulatory_service_facility' },
  { code: '14', name: 'renal_supplies_in_the_home' },
  { code: '15', name: 'alternate_method_dialysis' },
  { code: '16', name: 'chronic_renal_disease_equipment' },
  { code: '17', name: 'pre_admission_testing' },
  { code: '18', name: 'medical_equipment_rental' },
  { code: '19', name: 'pneumonia_vaccine' },
  { code: '20', name: 'second_surgical_opinion' },
  { code: '21', name: 'third_surgical_opinion' },
  { code: '22', name: 'social_work' },
  { code: '23', name: 'diagnostic_dental' },
  { code: '24', name: 'periodontics' },
  { code: '25', name: 'restorative' },
  { code: '26', name: 'endodontics' },
  { code: '27', name: 'maxillofacial_prosthetics' },
  { code: '28', name: 'adjunctive_dental_services' },
  { code: '30', name: 'health_benefit_plan_coverage' },
  { code: '32', name: 'plan_waiting_period' },
  { code: '33', name: 'chiropractic' },
  { code: '34', name: 'chiropractic_office_visits' },
  { code: '35', name: 'dental_care' },
  { code: '36', name: 'dental_crowns' },
  { code: '37', name: 'dental_accident' },
  { code: '38', name: 'orthodontics' },
  { code: '39', name: 'prosthodontics' },
  { code: '40', name: 'oral_surgery' },
  { code: '41', name: 'routine_preventive_dental' },
  { code: '42', name: 'home_health_care' },
  { code: '43', name: 'home_health_prescriptions' },
  { code: '44', name: 'home_health_visits' },
  { code: '45', name: 'hospice' },
  { code: '46', name: 'respite_care' },
  { code: '47', name: 'hospital' },
  { code: '48', name: 'hospital_inpatient' },
  { code: '49', name: 'hospital_room_and_board' },
  { code: '50', name: 'hospital_outpatient' },
  { code: '51', name: 'hospital_emergency_accident' },
  { code: '52', name: 'hospital_emergency_medical' },
  { code: '53', name: 'hospital_ambulatory_surgical' },
  { code: '54', name: 'long_term_care' },
  { code: '55', name: 'major_medical' },
  { code: '56', name: 'medically_related_transportation' },
  { code: '57', name: 'air_transportation' },
  { code: '58', name: 'cabulance' },
  { code: '59', name: 'licensed_ambulance' },
  { code: '60', name: 'general_benefits' },
  { code: '61', name: 'in_vitro_fertilization' },
  { code: '62', name: 'mri_cat_scan' },
  { code: '63', name: 'donor_procedures' },
  { code: '64', name: 'acupuncture' },
  { code: '65', name: 'newborn_care' },
  { code: '66', name: 'pathology' },
  { code: '67', name: 'smoking_cessation' },
  { code: '68', name: 'well_baby_care' },
  { code: '69', name: 'maternity' },
  { code: '70', name: 'transplants' },
  { code: '71', name: 'audiology_exam' },
  { code: '72', name: 'inhalation_therapy' },
  { code: '73', name: 'diagnostic_medical' },
  { code: '74', name: 'private_duty_nursing' },
  { code: '75', name: 'prosthetic_device' },
  { code: '76', name: 'dialysis' },
  { code: '77', name: 'otological_exam' },
  { code: '78', name: 'chemotherapy' },
  { code: '79', name: 'allergy_testing' },
  { code: '80', name: 'immunizations' },
  { code: '81', name: 'routine_physical' },
  { code: '82', name: 'family_planning' },
  { code: '83', name: 'infertility' },
  { code: '84', name: 'abortion' },
  { code: '85', name: 'aids' },
  { code: '86', name: 'emergency_services' },
  { code: '87', name: 'cancer' },
  { code: '88', name: 'pharmacy' },
  { code: '89', name: 'free_standing_prescription_drug' },
  { code: '90', name: 'mail_order_prescription_drug_brand_name' },
  { code: '91', name: 'brand_name_prescription_drug' },
  { code: '92', name: 'generic_prescription_drug' },
  { code: '93', name: 'podiatry' },
  { code: '94', name: 'podiatry_office_visits' },
  { code: '95', name: 'podiatry_nursing_home_visits' },
  { code: '96', name: 'professional_physician' },
  { code: '97', name: 'anesthesiologist' },
  { code: '98', name: 'professional_physician_visit_office' },
  { code: '99', name: 'professional_physician_visit_inpatient' },
  { code: 'A0', name: 'professional_physician_visit_outpatient' },
  { code: 'A1', name: 'professional_physician_visit_nursing_home' },
  { code: 'A2', name: 'professional_physician_visit_skilled_nursing_facility' },
  { code: 'A3', name: 'professional_physician_visit_home' },
  { code: 'A4', name: 'psychiatric' },
  { code: 'A5', name: 'psychiatric_room_and_board' },
  { code: 'A6', name: 'psychotherapy' },
  { code: 'A7', name: 'psychiatric_inpatient' },
  { code: 'A8', name: 'psychiatric_outpatient' },
  { code: 'A9', name: 'rehabilitation' },
  { code: 'AA', name: 'rehabilitation_room_and_board' },
  { code: 'AB', name: 'rehabilitation_inpatient' },
  { code: 'AC', name: 'rehabilitation_outpatient' },
  { code: 'AD', name: 'occupational_therapy' },
  { code: 'AE', name: 'physical_medicine' },
  { code: 'AF', name: 'speech_therapy' },
  { code: 'AG', name: 'skilled_nursing_care' },
  { code: 'AH', name: 'skilled_nursing_care_room_and_board' },
  { code: 'AI', name: 'substance_abuse' },
  { code: 'AJ', name: 'alcoholism' },
  { code: 'AK', name: 'drug_addiction' },
  { code: 'AL', name: 'vision_optometry' },
  { code: 'AM', name: 'frames' },
  { code: 'AN', name: 'routine_exam' },
  { code: 'AO', name: 'lenses' },
  { code: 'AP', name: 'routine_eye_exam' },
  { code: 'AQ', name: 'nonmedically_necessary_physical' },
  { code: 'AR', name: 'experimental_drug_therapy' },
  { code: 'B1', name: 'burn_care' },
  { code: 'B2', name: 'brand_name_prescription_drug_formulary' },
  { code: 'B3', name: 'brand_name_prescription_drug_non_formulary' },
  { code: 'BA', name: 'independent_medical_evaluation' },
  { code: 'BB', name: 'partial_hospitalization_psychiatric' },
  { code: 'BC', name: 'day_care_psychiatric' },
  { code: 'BD', name: 'cognitive_therapy' },
  { code: 'BE', name: 'massage_therapy' },
  { code: 'BF', name: 'pulmonary_rehabilitation' },
  { code: 'BG', name: 'cardiac_rehabilitation' },
  { code: 'BH', name: 'pediatric' },
  { code: 'BI', name: 'nursery' },
  { code: 'BJ', name: 'skin' },
  { code: 'BK', name: 'orthopedic' },
  { code: 'BL', name: 'cardiac' },
  { code: 'BM', name: 'lymphatic' },
  { code: 'BN', name: 'gastrointestinal' },
  { code: 'BP', name: 'endocrine' },
  { code: 'BQ', name: 'neurology' },
  { code: 'BR', name: 'eye' },
  { code: 'BS', name: 'invasive_procedures' },
  { code: 'BT', name: 'gynecological' },
  { code: 'BU', name: 'obstetrical' },
  { code: 'BV', name: 'obstetrical_gynecological' },
  { code: 'BW', name: 'mail_order_prescription_drug_generic' },
  { code: 'BX', name: 'mail_order_prescription_drug' },
  { code: 'BY', name: 'physician_visit_office_sick' },
  { code: 'BZ', name: 'physician_visit_office_well' },
  { code: 'C1', name: 'coronary_care' },
  { code: 'CA', name: 'private_duty_nursing_inpatient' },
  { code: 'CB', name: 'private_duty_nursing_home' },
  { code: 'CC', name: 'surgical_benefits_professional_physician' },
  { code: 'CD', name: 'surgical_benefits_facility' },
  { code: 'CE', name: 'mental_health_provider_inpatient' },
  { code: 'CF', name: 'mental_health_provider_outpatient' },
  { code: 'CG', name: 'mental_health_facility_inpatient' },
  { code: 'CH', name: 'mental_health_facility_outpatient' },
  { code: 'CI', name: 'substance_abuse_facility_inpatient' },
  { code: 'CJ', name: 'substance_abuse_facility_outpatient' },
  { code: 'CK', name: 'screening_x_ray' },
  { code: 'CL', name: 'screening_laboratory' },
  { code: 'CM', name: 'mammogram_high_risk_patient' },
  { code: 'CN', name: 'mammogram_low_risk_patient' },
  { code: 'CO', name: 'flu_vaccination' },
  { code: 'CP', name: 'eyewear_and_accessories' },
  { code: 'CQ', name: 'case_management' },
  { code: 'DG', name: 'dermatology' },
  { code: 'DM', name: 'medical_equipment' },
  { code: 'DS', name: 'diabetic_supplies' },
  { code: 'E0', name: 'allied_behavioral_analysis_therapy' },
  { code: 'E1', name: 'non_medical_equipment_non_dme' },
  { code: 'E2', name: 'psychiatric_emergency' },
  { code: 'E3', name: 'step_down_unit' },
  { code: 'E4', name: 'skilled_nursing_facility_head_level_of_care' },
  { code: 'E5', name: 'skilled_nursing_facility_ventilator_level_of_care' },
  { code: 'E6', name: 'level_of_care_1' },
  { code: 'E7', name: 'level_of_care_2' },
  { code: 'E8', name: 'level_of_care_3' },
  { code: 'E9', name: 'level_of_care_4' },
  { code: 'E10', name: 'radiographs' },
  { code: 'E11', name: 'diagnostic_imaging' },
  { code: 'E12', name: 'basic_restorative_dental' },
  { code: 'E13', name: 'major_restorative_dental' },
  { code: 'E14', name: 'fixed_prosthodontics' },
  { code: 'E15', name: 'removable_prosthodontics' },
  { code: 'E16', name: 'intraoral_images_complete_series' },
  { code: 'E17', name: 'oral_evaluation' },
  { code: 'E18', name: 'dental_prophylaxis' },
  { code: 'E19', name: 'panoramic_images' },
  { code: 'E20', name: 'sealants' },
  { code: 'E21', name: 'flouride_treatments' },
  { code: 'E22', name: 'dental_implants' },
  { code: 'E23', name: 'temporomandibular_joint_dysfunction' },
  { code: 'E24', name: 'retail_pharmacy_prescription_drug' },
  { code: 'E25', name: 'long_term_care_pharmacy' },
  { code: 'E26', name: 'comprehensive_medication_therapy_management_review' },
  { code: 'E27', name: 'targeted_medication_therapy_management_review' },
  { code: 'E28', name: 'dietary_nutritional_services' },
  { code: 'E29', name: 'technical_cardiac_rehabilitation_services_component' },
  {
    code: 'E30',
    name: 'professional_cardiac_rehabilitation_services_component',
  },
  {
    code: 'E31',
    name: 'professional_intensive_cardiac_rehabilitation_services_component',
  },
  { code: 'E32', name: 'intensive_cardiac_rehabilitation_technical_component' },
  { code: 'E33', name: 'intensive_cardiac_rehabilitation' },
  { code: 'E34', name: 'pulmonary_rehabilitation_technical_component' },
  { code: 'E35', name: 'pulmonary_rehabilitation_professional_component' },
  { code: 'E36', name: 'convenience_care' },
  { code: 'E37', name: 'telemedicine' },
  { code: 'E38', name: 'pharmacist_services' },
  { code: 'E39', name: 'diabetic_education' },
  { code: 'EA', name: 'preventive_services' },
  { code: 'EB', name: 'specialty_pharmacy' },
  { code: 'EC', name: 'durable_medical_equipment_new' },
  { code: 'ED', name: 'cat_scan' },
  { code: 'EE', name: 'ophthalmology' },
  { code: 'EF', name: 'contact_lenses' },
  { code: 'EG', name: 'fertility_preservation' },
  { code: 'F1', name: 'medical_coverage' },
  { code: 'F2', name: 'social_work_coverage' },
  { code: 'F3', name: 'dental_coverage' },
  { code: 'F4', name: 'hearing_coverage' },
  { code: 'F5', name: 'prescription_drug_coverage' },
  { code: 'F6', name: 'vision_coverage' },
  { code: 'F7', name: 'orthodontia_coverage' },
  { code: 'F8', name: 'mental_health_coverage' },
  { code: 'GF', name: 'generic_prescription_drug_formulary' },
  { code: 'GN', name: 'generic_prescription_drug_non_formulary' },
  { code: 'GY', name: 'allergy' },
  { code: 'IC', name: 'intensive_care' },
  { code: 'MH', name: 'mental_health' },
  { code: 'NI', name: 'neonatal_intensive_care' },
  { code: 'ON', name: 'oncology' },
  { code: 'PE', name: 'positron_emission_tomography_pet_scan' },
  { code: 'PT', name: 'physical_therapy' },
  { code: 'PU', name: 'pulmonary' },
  { code: 'RN', name: 'renal' },
  { code: 'RT', name: 'residential_psychiatric_treatment' },
  { code: 'SMH', name: 'serious_mental_health' },
  { code: 'TC', name: 'transitional_care' },
  { code: 'TN', name: 'transitional_nursery_care' },
  { code: 'UC', name: 'urgent_care' },
]

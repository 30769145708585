import { Injectable } from '@angular/core'
import { BehaviorSubject, filter, map, pairwise, ReplaySubject, share, startWith, tap } from 'rxjs'

export type PatientId = string
export type AppointmentId = string
export type IntakeId = string

const log = (message: string, meta: string | string[]) => {
  // eslint-disable-next-line no-console
  console.debug(`[ParamsService]: ${message} ${JSON.stringify(meta)}`)
}

@Injectable({
  providedIn: 'root',
})
export class ParamsService {
  private patientIdSubject = new BehaviorSubject<PatientId | null>(null)
  private appointmentIdSubject = new BehaviorSubject<AppointmentId | null>(null)
  private intakeIdSubject = new BehaviorSubject<IntakeId | null>(null)

  public patientId$ = this.patientIdSubject.pipe(
    startWith(null),
    pairwise(),
    share({
      connector: () => new ReplaySubject(null),
      resetOnError: true,
      resetOnComplete: true,
    }),
    tap(pair => log(`patientId$`, pair)),
  )

  /**
   * Emit only when the patient ID has been distinctly defined
  */
  public patientDefined$ = this.patientId$.pipe(
    map(([, newId]) => newId),
    filter((id) => id != null),
  )

  /**
   * Emit a uniq event when the patient id from params become null.
  */
  public patientUndefined$ = this.patientId$.pipe(
    map(([, newId]) => newId),
    filter((id) => !id),
  )

  public appointmentId$ = this.appointmentIdSubject.pipe(
    startWith(null),
    pairwise(),
    share({
      connector: () => new ReplaySubject(null),
      resetOnError: true,
      resetOnComplete: true,
    }),
    tap(pair => log(`appointmentId$`, pair)),
  )

  public intakeId$ = this.intakeIdSubject.pipe(
    startWith(null),
    pairwise(),
    share({
      connector: () => new ReplaySubject(null),
      resetOnError: true,
      resetOnComplete: true,
    }),
    tap(pair => log(`intakeId$`, pair)),
  )

  public setPatientId(patientId: PatientId | null): void {
    log(`updated patientId`, [patientId])
    this.patientIdSubject.next(patientId)
  }

  public setAppointmentId(appointmentId: AppointmentId | null): void {
    log(`updated appointmentId`, [appointmentId])
    this.appointmentIdSubject.next(appointmentId)
  }

  public setIntakeId(intakeId: IntakeId | null): void {
    log(`updated intakeId`, [intakeId])
    this.intakeIdSubject.next(intakeId)
  }
}

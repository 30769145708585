import { StripeError } from '@stripe/stripe-js'

export function getStripeErrorMessage(error: StripeError): string | undefined {
  switch (error.code) {
    case 'expired_card':
      return 'Card is expired'
    case 'card_declined':
      return 'Card is declined'
    default:
      return
  }
}

import { DiagnosisCode, STATUS, StudyData } from '@mmx/shared'

import { ProductModel } from './product.model'

/**
 * Each study can contain an array of productIds, however, we create a StudyModel
 * per product when displaying studies in a table so this model takes the Product
 * which this StudyModel is for.
 */
export class StudyModel {
  public static build(study: StudyData, products: ProductModel[]) {
    return study.products.map(productId => {
      const product = products.find(p => p.id === productId)
      if (product) {
        return new StudyModel(study, product)
      }
      return null
    }).filter(study => study !== null)
  }

  eid?: string
  ekey?: string
  dicomUID?: string
  /**
   * List of all productIds on the study
   */
  productIds?: string[]
  productCategories?: string[]
  diagnosisCodes?: DiagnosisCode[]
  status?: STATUS
  statusDescription?: string
  startAt?: string
  endAt?: string

  /**
   * This specific Product this StudyModel is for
   */
  product?: ProductModel

  get productName() {
    return this.product?.name
  }

  get productCodes() {
    return this.product?.codes ?? []
  }

  constructor(data: StudyData, product: ProductModel) {
    this.eid = data.eid || 'N/A'
    this.ekey = data.ekey
    this.dicomUID = data.dicomUID
    this.productIds = data.products
    this.productCategories = data.productCategories
    this.diagnosisCodes = data.diagnosisCodes
    this.status = data.status
    this.statusDescription = data.statusDescription
    this.startAt = data.startAt
    this.endAt = data.endAt
    this.product = product
  }

  public getDiagnosisCodesNames(): string {
    return this.diagnosisCodes?.map(diagnosisCode => diagnosisCode.code).join(', ') || 'N/A'
  }
}
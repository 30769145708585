import { Facility } from '@mmx/shared'
import * as moment from 'moment-timezone'

import { Clinic } from '../models'

const dayFormat = 'L' // 'MM//DD/YYYY'
const timeFormat = 'LT' // 'h:mm A'

export function getFacilityAndLocalTime(dt: moment.Moment, input?: {
  clinic?: Clinic,
  facility?: Facility,
  tz?: string,
}): string | undefined {
  const startAt = dt.clone()
  const format = `${dayFormat} ${timeFormat}`
  const utcTime = startAt.clone().utc().format(format)
  const localTime = startAt.clone().local().format(format)
  const remoteTZ = input?.facility?.timezone ?? input.clinic?.settings?.defaultTimezone
  let facilityTime = ''

  if (remoteTZ) {
    facilityTime = startAt.tz(remoteTZ).format(format)
  } else if (input.tz) {
    facilityTime = startAt.tz(input.tz).format(format)
  }

  if (facilityTime && facilityTime !== localTime && localTime !== utcTime) {
    return `Facility time: ${facilityTime}, local time: ${localTime}`
  }
}

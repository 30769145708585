import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Patient, RestResponse } from '@mmx/shared'
import * as moment from 'moment'
import { map, Observable, shareReplay, Subject, switchMap } from 'rxjs'

import { AuthenticatedRestService } from './auth-rest.service'

export interface StructuredSearchFields {
  name: string
  eid?: string
  phone: string
  dob: moment.Moment
}

type SearchStringParams = {
  searchTerm: string
} & {
  [Property in keyof StructuredSearchFields]?: never
}

type StructuredParams = StructuredSearchFields & {
  searchTerm?: never
}

export type ElasticParams = StructuredParams | SearchStringParams

function parameterize(object: ElasticParams): HttpParams {
  let httpParams = new HttpParams()
  Object.entries(object).forEach(([key, value]) => {
    if (value) {
      httpParams = httpParams.set(key, value)
    }
  })
  return httpParams
}

function formatDates(object: ElasticParams): ElasticParams {
  Object.entries(object).forEach(([key, value]) => {
    if (moment.isMoment(value)) {
      object[key] = value.format('YYYY-MM-DD')
    }
  })
  return object
}

function createPatientObjects(object) {
  object.data = object.data.map((obj) => new Patient(obj))
  return object
}

@Injectable({
  providedIn: 'root',
})
export class SearchService extends AuthenticatedRestService {
  private route = '/patients/search'

  searchPatientsSubject = new Subject<ElasticParams>()

  private request = (params: HttpParams) => this.get<any>(this.route, params)

  // eslint-disable-next-line @typescript-eslint/member-ordering
  searchResult$ = this.searchPatientsSubject.pipe(
    map(formatDates),
    map(parameterize),
    switchMap(this.request.bind(this)),
    map(createPatientObjects),
    shareReplay(1),
  )

  searchPatients(search: ElasticParams): Observable<RestResponse<any>> {
    const httpParams = new HttpParams({
      fromObject: search as Record<string, any>,
    })
    return this.request(httpParams)
  }
}

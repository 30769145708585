import {
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { truthyValues } from '@mmx/shared'
import {
  BehaviorSubject,
  catchError,
  concatMap,
  exhaustMap,
  finalize,
  map,
  of,
  ReplaySubject,
  share,
  withLatestFrom,
} from 'rxjs'

import { AdvancedTableApiService } from '../../advanced-table/advanced-table-api-service'
import { ChargeDescriptionMasterModel } from '../models'
import { AuthenticatedRestService } from './auth-rest.service'

@Injectable()
export class ChargeMasterService
  extends AuthenticatedRestService
  implements AdvancedTableApiService<ChargeDescriptionMasterModel>
{
  route = '/charge-description-master'
  collectionParams = new ReplaySubject<Record<string, any>>(1)
  updateParams = new ReplaySubject<Record<string, any>>(1)
  bust$ = new BehaviorSubject(false)

  private header$ = this.bust$.pipe(
    map((bust) => (bust ? new HttpHeaders({ 'x-refresh': 'true' }) : null)),
  )

  collectionResponse$ = this.collectionParams.pipe(
    map(truthyValues),
    map((object) => new HttpParams({ fromObject: object })),
    withLatestFrom(this.header$),
    concatMap(([params, headers]) =>
      this.get<ChargeDescriptionMasterModel[]>(this.route, params, { headers }).pipe(
        catchError((error: HttpErrorResponse) => of(error)),
        finalize(() => this.bust$.next(false)),
      ),
    ),
    share(),
  )

  updateResponse$ = this.updateParams.pipe(
    map(truthyValues),
    exhaustMap((params) =>
      this.putOrPost(params).pipe(
        catchError((error: HttpErrorResponse) => of(error)),
        finalize(() => this.bust$.next(true)),
      ),
    ),
    share(),
  )

  private putOrPost = (params: Record<string, any>) => {
    if (params.id) {
      return this.put<ChargeDescriptionMasterModel>(`${this.route}/${params.id}`, params)
    } else {
      return this.post<ChargeDescriptionMasterModel>(this.route, params)
    }
  }
}

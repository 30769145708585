import { intersection, isArray, isPlainObject, without } from 'lodash'

export interface Difference {
  type: 'array' | 'object'
  removed?: any[]
  added?: any[]
  diff?: any
}

function diff(obj1, obj2) {
  const result = {}
  if (Object.is(obj1, obj2)) {
    return undefined
  }
  if (!obj2 || typeof obj2 !== 'object') {
    return obj2
  }
  Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {
    if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
      result[key] = obj2[key]
    }
    if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
      const value = diff(obj1[key], obj2[key])
      if (value !== undefined) {
        result[key] = value
      }
    }
  })
  return result
}

export function getDifference(outdated: any, updated: any): Difference | undefined {
  if (outdated != null && updated != null) {
    if (typeof outdated === 'string' && (outdated.startsWith('{') || outdated.startsWith('['))) {
      try {
        outdated = JSON.parse(outdated)
        updated = JSON.parse(updated)
      } catch (ex) { }
    }

    if (isPlainObject(outdated) && isPlainObject(updated)) {
      return {
        type: 'object',
        diff: diff(outdated, updated),
      }
    } else if (isArray(outdated) && isArray(updated)) {
      const inter = intersection(outdated, updated)
      const removed = without(outdated, ...inter)
      const added = without(updated, ...inter)
      return {
        type: 'array',
        removed,
        added,
      }
    }
  }
}

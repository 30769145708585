import { capitalize, extend } from 'lodash'
import * as moment from 'moment'

import { Intake } from './intake.model'
import { Invoice } from './invoice.model'
import { Transaction } from './transaction.model'

export class PatientDocument {
  id: string
  shortId: string
  patientId: string
  appointmentId: string
  transactionType?: string
  transactionMethod?: string
  createdAt: moment.Moment
  status?: string
  type: string
  updatedAt: moment.Moment

  constructor(data) {
    extend(this, data)

    this.shortId = data.shortId || this.id.substr(0, 8)

    if (data.createdAt) {
      this.createdAt = moment(data.createdAt)
    }

    if (data.updatedAt) {
      this.updatedAt = moment(data.updatedAt)
    }
  }

  get title() {
    return 'common.' + this.type
  }

  get transactionTypeDisplay() {
    if (this.type === 'transaction') {
      switch (this.transactionType) {
        case Transaction.TYPE.PAYMENT: return 'common.payment'
        case Transaction.TYPE.CAPTURE: return 'transactions.capture'
        case Transaction.TYPE.REFUND: return 'transactions.refund'
        case Transaction.TYPE.DISPUTE: return 'transactions.dispute'
      }
    }
  }

  get statusDisplay() {
    if (this.type === 'intake') {
      switch (this.status) {
        case Intake.STAGE.PENDING:
          return 'common.pending'
        case Intake.STAGE.DELIVERED:
          return 'common.delivered'
        case Intake.STAGE.SUBMITTED:
          return 'common.submitted'
        case Intake.STAGE.CONFIRMED:
          return 'common.confirmed'
        case Intake.STAGE.TECH_CONFIRMED:
          return 'common.tech-confirmed'
        case Intake.STAGE.TECH_REVIEW:
          return 'common.tech-review'
        case Intake.STAGE.SYNCING:
          return 'common.syncing'
        case Intake.STAGE.SYNCED:
          return 'common.synced'
      }
    } else if (this.type === 'eligibility') {
      return capitalize(this.status)
    } else if (this.type === 'transaction') {
      switch (this.status) {
        case Transaction.STATUS.SUCCESS:
          return 'common.success'
        case Transaction.STATUS.FAILED:
          return 'common.failed'
        case Transaction.STATUS.PENDING:
          return 'common.pending'
        case Transaction.STATUS.VOIDED:
          return 'common.voided'
      }
    } else if (this.type === 'invoice') {
      switch (this.status) {
        case Invoice.STATUS.DRAFT:
          return 'common.draft'
        case Invoice.STATUS.VOID:
          return 'common.voided'
        case Invoice.STATUS.HOLD:
          return 'common.hold'
        case Invoice.STATUS.INTERIM:
          return 'common.interim'
        case Invoice.STATUS.CONFIRMED:
          return 'common.confirmed'
        case Invoice.STATUS.UNCOLLECTABLE:
          return 'common.uncollectable'
        case Invoice.STATUS.PAYING:
          return 'common.on-payment-plan'
        case Invoice.STATUS.PARTIAL:
          return 'common.partially-paid'
        case Invoice.STATUS.PAID:
          return 'common.paid'
      }
    }

    return ''
  }

  get from() {
    return this.createdAt ? this.createdAt.fromNow() : ''
  }
}

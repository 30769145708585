/**
 * @see http://terminology.hl7.org/CodeSystem/v3-MaritalStatus
 */
export const MaritalStatuses: string[] = [
  'Annulled',
  'Divorced',
  'Domestic Partner',
  'Interlocutory',
  'Legally Separated',
  'Married',
  '  Common Law',
  'Polygamous',
  'Unmarried',
  '  Never Married',
  'Widowed',

  // 'Living Together',
  // 'Other',
  // 'Registered Domestic Partner',
  // 'Separated',
  // 'Single',
]

import { CRUD, CrudModel, InsuranceProviderLookup } from '@mmx/shared'

import { ProductCategory } from '../product.category.model'
import { ProductModel } from '../product.model'
import { PortalModel } from './portal.model'
import { PortalRuleData } from './portal-rule.data'
import { PortalRuleType } from './portal-rule-type'

@CRUD({
  base: '/crud/portal-rule',
})
export class PortalRuleModel extends CrudModel {
  public type!: PortalRuleType
  public portal!: PortalModel
  public carrierLookups!: InsuranceProviderLookup[]
  public products?: ProductModel[]
  public productCategories?: ProductCategory[]
  public notes?: string

  constructor(data: PortalRuleData) {
    super(data, true)

    if (data.portal) {
      this.portal = new PortalModel(data.portal)
    }

    if (data.carrierLookups) {
      this.carrierLookups = data.carrierLookups.map((carrier) => new InsuranceProviderLookup(carrier))
    }

    if (data.products) {
      this.products = data.products.map((product) => new ProductModel(product))
    }

    if (data.productCategories) {
      this.productCategories = data.productCategories.map((category) => new ProductCategory(category))
    }
  }

  override toJSON() {
    return {
      ...super.toJSON(),
      type: this.type,
      portal: this.portal.id,
      carrierLookupIds: (this.carrierLookups || []).map((carrier) => carrier.id),
      productIds: (this.products || []).map((product) => product.id),
      productCategoryIds: (this.productCategories || []).map((category) => category.id),
      notes: this.notes,
    }
  }
}

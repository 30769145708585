import { BaseModel, BaseModelData } from '@mmx/shared'

export interface ProcedureCodeModifierData extends BaseModelData {
  code: string
  abbreviation: string
}

export class ProcedureCodeModifier extends BaseModel {
  code: string
  abbreviation: string

  constructor(data: ProcedureCodeModifierData) {
    super(data, true)
  }
}

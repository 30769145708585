import { BaseModelData, Clinician, CRUD, CrudModel } from '@mmx/shared'

import { InvoiceLineItem } from '../invoice.model'
import { EstimateCharge } from './estimate-charge'
import { HBPricerRequest, HBPricerResponse } from './hb-pricer-api'
import { PBPricerRequest, PBPricerResponse } from './pb-pricer-api'
import { PricerRequestOptions } from './pricer-request'

export interface CalculatedEstimate {
  deductible: number
  coinsurance: number
  copayment: number
  outOfPocketMax: number
  insuranceType: 'selfpay' | 'medical' | 'lien' | 'wc'
  total: number
  totalBillableToPatient: number
  hasSecondary?: boolean
  secondaryInsuranceCoversCoinsurance?: boolean
  secondaryInsuranceCoversCopayment?: boolean
  secondaryInsuranceCoversDeductible?: boolean
  estimateType: 'patientpal' | 'custom'
  message?: string
  missingData?: boolean
  provider?: {
    id?: string
    name?: string
  }
  insuranceCarrier?: {
    lookupId?: string
    clinicCarrierName?: string
  }
  confidence: number
  deductibleApplied: number
  coinsuranceApplied: number
  copaymentApplied: number
  outOfPocketMaxApplied: number | null
  insuranceResponsibility: number
  secondaryInsuranceResponsibility?: number
  patientsResponsibility: number
}

export interface EstimateData extends BaseModelData {
  patientId: string
  facilityId: string
  templateId?: string
  appointmentIds: string[]
  patientType?: Estimate.PATIENT_TYPE
  pricerRequestOptions?: PricerRequestOptions
  charges?: EstimateCharge[]
  pbResponse?: PBPricerResponse
  hbResponse?: HBPricerResponse
  estimate?: CalculatedEstimate
  clinicianId?: string
  clinician?: Clinician
}

@CRUD({
  base: '/estimates',
})
export class EstimateModel extends CrudModel {
  patientId: string
  facilityId: string
  templateId?: string
  appointmentIds: string[]
  patientType?: Estimate.PATIENT_TYPE
  pricerRequestOptions?: PricerRequestOptions
  charges?: EstimateCharge[]
  pbRequest?: PBPricerRequest
  hbRequest?: HBPricerRequest
  pbResponse?: PBPricerResponse
  hbResponse?: HBPricerResponse
  estimate?: CalculatedEstimate
  clinicianId?: string
  clinician?: Clinician

  constructor(data?: EstimateData) {
    super(data, true)
  }

  get totalPBCharges(): number {
    // Get the total of all charges with a type of 'PB'. Remember to multiply the unit charge by the quantity
    return this.charges.reduce((total, charge) => {
      return charge.type === 'pb' ? total + (charge.unitCharge * charge.quantity) : total
    }, 0)
  }

  get totalHBCharges(): number {
    // Get the total of all charges with a type of 'HB'
    return this.charges.reduce((total, charge) => {
      return charge.type === 'hb' ? total + (charge.unitCharge * charge.quantity) : total
    }, 0)
  }

  get lineItems(): InvoiceLineItem[] {
    return this.charges?.map(charge => {
      const lineItem = {
        name: charge.type, // this isn't part of InvoiceLineItem, but it is apparently used in appointment estimate card
        description: charge.description ?? '',
        amount: charge.amount ?? (charge.unitCharge * charge.quantity),
        // expectedAmount: number // TODO
        currency: charge.currency,
        quantity: charge.quantity,
        unit_amount: charge.unitCharge,
      }

      return lineItem
    })
  }

  override toJSON() {
    return Object.assign(super.toJSON(), {
      patientId: this.patientId,
      facilityId: this.facilityId,
      templateId: this.templateId,
      appointmentIds: this.appointmentIds,
      patientType: this.patientType,
      charges: this.charges,
      estimate: this.estimate,
    })
  }
}

export namespace Estimate {
  export enum PATIENT_TYPE {
    InPatient = 'I',
    OutPatient = 'O',
  }
}

import { COLOR } from '../data'
import { WorkItem } from '../models'

const statusColors = {
  [WorkItem.STATUS.NEW]: COLOR.DEFAULT,
  [WorkItem.STATUS.ASSIGNED]: COLOR.GREEN,
  [WorkItem.STATUS.SNOOZED]: COLOR.RED,
  [WorkItem.STATUS.IN_PROGRESS]: COLOR.FLASHING_GREEN,
  [WorkItem.STATUS.FINISHED]: COLOR.GREY,
  [WorkItem.STATUS.ESCALATED]: COLOR.YELLOW,
  [WorkItem.STATUS.CANCELLED]: COLOR.DEFAULT,
}

export const getWorkItemStatusColor = (
  status: WorkItem.STATUS = WorkItem.STATUS.NEW,
): COLOR => {
  return statusColors[status]
}

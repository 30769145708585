import { BaseModel } from '../base-model'
import { ClinicianData } from './clinician-data'

export class Clinician extends BaseModel {
  name: string
  abbreviation?: string
  email: string
  emailBounced?: boolean
  title: string
  roles: string[]
  facilities: string[]
  notifications: ('Email' | 'Push')[]
  mfa: { name: string; id: string; type: string; phoneNumber?: string }[]
  apiKeys: { id: string; lastUsedAt?: string; plainTextSecret?: string }[]
  settings: {
    dateFormat: string
    timeFormat: string
    notificationSounds?: { [key: string]: string }
  }
  isInternalEmployee?: boolean

  // backwards compatibility
  get isPatientPalEmployee() {
    return this.isInternalEmployee
  }

  constructor(data: ClinicianData) {
    super(data)
    this.clinicId = data.clinicId
    this.name = data.name
    this.abbreviation = data.abbreviation
    this.email = data.email
    this.emailBounced = data.emailBounced
    this.title = data.title
    this.roles = data.roles ?? [data.role]
    this.notifications = data.notifications || []
    this.facilities = data.facilities || []
    this.mfa = data.mfa || []
    this.apiKeys = data.apiKeys || []
    this.settings = data.settings || {}
    this.isInternalEmployee = data.isInternalEmployee
  }
}

import { ProductCategory } from '../product.category.model'

export type PieChartData = [
  { name: 'prepared'; value: number },
  { name: 'pending'; value: number },
]

export class PieChart {
  category: ProductCategory
  total: number
  prepared: number
  data: PieChartData
  colors: any

  constructor(data: {
    category: ProductCategory
    total: number
    prepared: number
    data: PieChartData
  }) {
    this.category = data.category
    this.total = data.total
    this.prepared = data.prepared
    this.data = data.data
    this.colors = [
      {
        name: 'prepared',
        value: this.category.color,
      },
      {
        name: 'pending',
        value: '#c1bfbf', // or '#DCDCDC' ?
      },
    ]
  }
}

import { BaseModel, BaseModelData } from '@mmx/shared'
import { extend } from 'lodash'

export interface ProductCategoryData extends BaseModelData {
  label: string
  color: string
  disableIntake?: boolean
  hidden?: boolean
  hideAppointments?: boolean
  resources?: string[]
}

export class ProductCategory extends BaseModel {
  label: string
  color: string
  disableIntake?: boolean
  hidden?: boolean
  hideAppointments?: boolean
  resources?: string[]

  constructor(data: ProductCategoryData) {
    super(data)
    this.label = data.label
    this.color = data.color
    this.disableIntake = data.disableIntake
    this.hidden = data.hidden
    this.hideAppointments = data.hideAppointments
    this.resources = data.resources
  }

  toJSON() {
    return extend(super.toJSON(), {
      label: this.label,
      color: this.color,
      disableIntake: this.disableIntake,
      hidden: this.hidden,
      hideAppointments: this.hideAppointments,
      resources: this.resources,
    })
  }
}

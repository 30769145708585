import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { defer, iif, Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { AuthenticatedRestService } from '../services/auth-rest.service'
import { ClinicService } from '../services/clinic.service'

/**
 * Checks the user is logged in.
 * Does not check permission, use PermissionsGuard for permission check.
 */
@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private restService: AuthenticatedRestService,
    private clinicService: ClinicService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const checkAuth$ = defer(() => this.restService.checkAuth())

    return checkAuth$.pipe(
      mergeMap(success =>
        iif(
          () => success,
          this.clinicService.clinic$.pipe(map(clinic => Boolean(clinic))),
          of(success),
        ),
      ),
      map((success) => {
        if (!success) {
          this.router.navigate(['/auth/login'], {
            queryParams: {
              redirectTo: state.url,
            },
          })
        }

        return success
      }),
      catchError(() => {
        this.router.navigate(['/auth/login'], {
          queryParams: {
            redirectTo: state.url,
          },
        })
        return of(null)
      }),
    )
  }
}

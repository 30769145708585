import { Routes } from '@angular/router'

import { AuthGuard, ClinicExistsGuard } from './core'

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: '/auth/login',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'popup',
    loadChildren: () =>
      import('./popup/popup.module').then((m) => m.PopupModule),
    canActivate: [ClinicExistsGuard, AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [ClinicExistsGuard, AuthGuard],
  },
  {
    path: '',
    canActivate: [ClinicExistsGuard, AuthGuard],
    loadChildren: () =>
      import('./clinician/clinician.module').then((m) => m.ClinicianModule),
  },
  {
    path: 'link',
    canActivate: [ClinicExistsGuard, AuthGuard],
    loadChildren: () => import('./link/link.module').then((m) => m.LinkModule),
  },
  {
    path: 'ie',
    loadChildren: () => import('./ie/ie.module').then((m) => m.IEPageModule),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./logout/logout.module').then((m) => m.LogoutPageModule),
  },
  {
    path: 'outage',
    loadChildren: () =>
      import('./outage/outage.module').then((m) => m.OutagePageModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./notfound/notfound.module').then((m) => m.NotFoundPageModule),
  },
]

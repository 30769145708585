import { SelectOption } from '../../classes'
import { ResourceType } from '../resource.model'

export const resourceTypeOptions: SelectOption<ResourceType>[] = [
  {
    value: 'room',
    viewValue: 'dashboard.column.room',
  },
  {
    value: 'equipment',
    viewValue: 'dashboard.column.equipment',
  },
  {
    value: 'provider',
    viewValue: 'dashboard.column.provider',
  },
  {
    value: 'modality',
    viewValue: 'dashboard.column.modality',
  },
  {
    value: 'bodyPart',
    viewValue: 'common.body-part',
  },
  {
    value: 'other',
    viewValue: 'common.other',
  },
]
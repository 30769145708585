import { Injectable } from '@angular/core'
import { Facility } from '@mmx/shared'
import { orderBy } from 'lodash'
import { BehaviorSubject } from 'rxjs'

import { ClinicService } from './clinic.service'

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  static STORAGE_KEY = 'pp-clinic-location-id'

  public facilities$ = new BehaviorSubject<Facility[]>([])
  public allFacilities: Facility[] = []
  public facilities: Facility[] = []
  public current$ = new BehaviorSubject<Facility>(null)

  private current: Facility

  constructor(protected clinicService: ClinicService) {
    this.clinicService
      .getLocations({ visibleForClinician: true })
      .subscribe((facilities) => {
        this.allFacilities = orderBy(facilities, ['status', 'shortName'])
        this.facilities = this.allFacilities.filter((facility) => !facility.disabled)
        this.facilities$.next(this.facilities)

        const facilityId = localStorage.getItem(FacilityService.STORAGE_KEY)
        let facility: Facility | undefined

        if (facilityId) {
          facility = this.facilities.find((f) => f.id === facilityId)
        }

        if (!facility) {
          facility = this.facilities[0]
        }

        this.setFacility(facility)
      })
  }

  public updateSelectedFacility(facility: Facility) {
    if (!this.current || this.current.id !== facility.id) {
      localStorage.setItem(FacilityService.STORAGE_KEY, facility.id)
      this.setFacility(facility)
    }
  }

  private setFacility(facility: Facility) {
    this.current = facility
    this.current$.next(facility)
  }

  public currentTimezone() {
    const tz = this.current.timezone ?? this.clinicService.clinic.settings.defaultTimezone ?? 'America/New_York'
    return tz
  }
}

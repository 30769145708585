import { AUTHORIZATION_STATUS, BaseModelData, CRUD, CrudModel, INSURANCE_PROVIDER_LOOKUP_TYPE } from '@mmx/shared'
import * as moment from 'moment-timezone'

import { Transaction } from './transaction.model'

export interface WorkflowData extends BaseModelData {
  name: string
  status: Workflow.STATUS
  action: Workflow.ACTION
  type: Workflow.TYPE
  facilities?: string[]
  productCategories?: string[]
  products?: string[]
  delayMinutes?: number
  daysOffset?: number
  daysOffsetType?: Workflow.DAYS_OFFSET_TYPE
  whenToCheckEligibility?: Workflow.WHEN_TO_CHECK_ELIGIBILITY
  timingFunction?: Workflow.TIMING_FUNCTION
  timeOffset?: number
  timeOfDay?: number
  daysOfWeek?: string[]
  transactionTypes?: string[]
  transactionStatuses?: string[]
  transactionPaymentType?: Workflow.TRANSACTION_PAYMENT_TYPE
  waitListOptions?: Workflow.WaitListOption
  messageProtocol?: string
  internalMessage?: boolean
  messageIgnoreDNC?: boolean
  nextAppointmentOnly?: boolean
  webhookEndpoint?: string
  webhookSecret?: string
  destination?: string
  authorizationStatuses?: AUTHORIZATION_STATUS[]
}

@CRUD({
  base: '/workflows',
})
export class WorkflowModel extends CrudModel {
  name: string
  status: Workflow.STATUS
  action: Workflow.ACTION
  type: Workflow.TYPE
  facilities?: string[]
  productCategories?: string[]
  excludedProductCategories?: string[]
  products?: string[]
  excludedProducts?: string[]
  insuranceTypes?: INSURANCE_PROVIDER_LOOKUP_TYPE[]
  excludedInsuranceTypes?: INSURANCE_PROVIDER_LOOKUP_TYPE[]
  insuranceCarriers?: string[]
  excludedInsuranceCarriers?: string[]
  delayMinutes?: number
  daysOffset?: number
  daysOffsetType?: Workflow.DAYS_OFFSET_TYPE
  whenToCheckEligibility?: Workflow.WHEN_TO_CHECK_ELIGIBILITY
  confirmationResponseType?: boolean
  timingFunction?: Workflow.TIMING_FUNCTION
  timeOffset?: number
  timeOfDay?: number
  daysOfWeek?: string[]
  transactionTypes?: Transaction.TYPE[]
  transactionStatuses?: Transaction.STATUS[]
  transactionPaymentType?: Workflow.TRANSACTION_PAYMENT_TYPE
  waitListOptions?: Workflow.WaitListOption
  messageProtocol?: Workflow.PROTOCOL
  internalMessage?: boolean
  messageIgnoreDNC?: boolean
  mailType?: string
  customMailTemplate?: boolean
  postcardSize?: string
  postcardFront?: string
  postcardBack?: string
  webhookEndpoint?: string
  webhookSecret?: string
  destination?: string
  nextAppointmentOnly?: boolean
  isDeleted?: boolean
  authorizationStatuses?: AUTHORIZATION_STATUS[]

  get sortKey(): string {
    return this.name ? this.name.toLowerCase() : undefined
  }

  get link(): string {
    return ['', 'settings', 'workflows', this.id, 'info'].join('/')
  }

  get needsTemplate(): boolean {
    return (
      this.action === Workflow.ACTION.MESSAGE_PATIENT &&
      (this.needsTextTemplate ||
        this.needsHtmlTemplate ||
        this.needsPhoneTemplate)
    )
  }

  get needsHtmlTemplate(): boolean {
    return ['auto', 'auto+phone', 'email', 'brute'].includes(
      this.messageProtocol,
    )
  }

  get needsTextTemplate(): boolean {
    return this.messageProtocol === 'sms' || this.needsHtmlTemplate
  }

  get needsPhoneTemplate(): boolean {
    return ['auto+phone', 'phone'].includes(this.messageProtocol)
  }

  constructor(data?: WorkflowData) {
    super(data, true)
  }

  getSummary() {
    let summary = ''
    let time = this.type === Workflow.TYPE.APPOINTMENT_PAST ? 'past' : 'before'

    if (this.isReminderType() && this.daysOffset != null) {
      if (this.daysOffset === 0) {
        summary += ' day of '
      } else if (this.daysOffset > 0) {
        summary += ` ${this.daysOffset} days ${time} `
      }
      if (this.timingFunction !== 'relative' && this.timeOfDay != null) {
        const localized = moment.utc(`${this.timeOfDay}:00`, 'HH:mm').local()
        const tz = moment.tz(moment.tz.guess()).format('z')
        summary += ` at ${this.timeOfDay}:00 UTC (${localized
          .format('LT')
          .replace(':00', '')} ${tz})`
      } else if (
        this.timingFunction === 'relative' &&
        this.timeOffset != null
      ) {
        summary += ` ${this.timeOffset} hours ${time} `
      }
    }

    return summary.replace(/\s+/g, ' ')
  }

  isReminderType() {
    const type = this.type
    return (
      type === Workflow.TYPE.APPOINTMENT_CONFIRMATION ||
      type === Workflow.TYPE.APPOINTMENT_PAST ||
      type === Workflow.TYPE.INVOICE_PAST_DUE ||
      type.endsWith('reminder')
    )
  }

  toJSON() {
    return {
      id: this.id,
      status: this.status,
      name: this.name,
      action: this.action,
      type: this.type,
      facilities: this.facilities,
      productCategories: this.productCategories,
      excludedProductCategories: this.excludedProductCategories,
      products: this.products,
      excludedProducts: this.excludedProducts,
      insuranceTypes: this.insuranceTypes,
      excludedInsuranceTypes: this.excludedInsuranceTypes,
      insuranceCarriers: this.insuranceCarriers,
      excludedInsuranceCarriers: this.excludedInsuranceCarriers,
      delayMinutes: this.delayMinutes,
      daysOffset: this.daysOffset,
      daysOffsetType: this.daysOffsetType,
      whenToCheckEligibility: this.whenToCheckEligibility,
      confirmationResponseType: this.confirmationResponseType,
      timingFunction: this.timingFunction,
      timeOffset: this.timeOffset,
      timeOfDay: this.timeOfDay,
      daysOfWeek: this.daysOfWeek,
      transactionTypes: this.transactionTypes,
      transactionStatuses: this.transactionStatuses,
      transactionPaymentType: this.transactionPaymentType,
      waitListOptions: this.waitListOptions,
      messageProtocol: this.messageProtocol,
      internalMessage: this.internalMessage,
      messageIgnoreDNC: this.messageIgnoreDNC,
      webhookEndpoint: this.webhookEndpoint,
      webhookSecret: this.webhookSecret,
      destination: this.destination,
      nextAppointmentOnly: this.nextAppointmentOnly,
      authorizationStatuses: this.authorizationStatuses,
    }
  }
}

export namespace Workflow {
  export type PROTOCOL =
    | 'auto'
    | 'auto+phone'
    | 'sms'
    | 'email'
    | 'brute'
    | 'phone'
    | 'postcard'
    | 'letter'

  export type TIMING_FUNCTION = 'fixed' | 'relative' | 'workingDays'

  export type DAYS_OFFSET_TYPE = 'within' | 'further_out'

  export type WHEN_TO_CHECK_ELIGIBILITY = 'daysBefore' | 'dayOfMonth'

  export enum STATUS {
    ENABLED = 'enabled',
    DISABLED = 'disabled',
    TEST = 'test',
  }

  export enum TRANSACTION_PAYMENT_TYPE {
    INVOICE = 'invoice',
    NO_INVOICE = 'no_invoice',
    PAYMENT_PLAN = 'payment_plan',
  }

  export enum TYPE {
    APPOINTMENT_NEW = 'appointment.created',
    APPOINTMENT_RESCHEDULED = 'appointment.rescheduled',
    APPOINTMENT_CANCELLED = 'appointment.cancelled',
    APPOINTMENT_CONFIRMATION = 'appointment.confirmation',
    APPOINTMENT_CONFIRMATION_RECEIVED = 'appointment.confirmation-received',
    APPOINTMENT_REMINDER = 'appointment.reminder',
    APPOINTMENT_STARTED = 'appointment.started',
    APPOINTMENT_COMPLETED = 'appointment.completed',
    APPOINTMENT_PAST = 'appointment.past',
    APPOINTMENT_NEEDS_RESCHEDULE = 'appointment.needs-reschedule',
    APPOINTMENT_NO_SHOW = 'appointment.no-show',
    APPOINTMENT_RECOMMENDATION_CREATED = 'appointment-recommendation.created',
    APPOINTMENT_RECOMMENDATION_REMINDER = 'appointment-recommendation.reminder',
    APPOINTMENT_REQUEST_CREATED = 'appointment-request.created',
    APPOINTMENT_REQUEST_REMINDER = 'appointment-request.reminder',
    APPOINTMENT_REQUEST_SUBMITTED = 'appointment-request.submitted',
    ELIGIBILITY_APPOINTMENT_PRE = 'eligibility.appointment-pre',
    INTAKE_REMINDER = 'intake.reminder',
    INTAKE_CONFIRMED = 'intake.confirmed',
    INVOICE_CONFIRMED = 'invoice.confirmed',
    INVOICE_REMINDER = 'invoice.reminder',
    INVOICE_PAST_DUE = 'invoice.past-due',
    INVOICE_PAYMENT = 'invoice.payment',
    INVOICE_PAID = 'invoice.paid',
    POST_APPOINTMENT_SURVEY = 'surveys.post-appointment',
    ORDER_CREATED = 'order.created',
    TRANSACTION_CREATED = 'transaction.created',
    RESULTS_AVAILABLE = 'results.available',
    WAIT_LIST_REACH_OUT = 'wait-list.reach-out',
    WORK_LIST_AUTOMATION = 'work-list.automation',
  }

  export enum ACTION {
    MESSAGE_PATIENT = 'patient.message',
    NOTIFY_CLINICIANS = 'clinicians.notify',
    PUSH_WEBHOOK = 'webhook.push',
    EMAIL = 'email',
    FAX = 'fax',
    INSURANCE_VERIFY = 'insurance.verify',
    INSURANCE_DISCOVER = 'insurance.discover',
  }

  export interface Attachment {
    name: string
    label: string
  }

  export interface WaitListOption {
    timeSlotLength: number
    timeSlotOpen: number
    limitPatients: number
  }

  export enum STATS_TYPE {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
  }
}

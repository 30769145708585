import { HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { PagedRestResponse } from '@mmx/shared'
import { CacheEntry } from 'app/core'
import { filter } from 'rxjs'

export interface ResponseParams {
  startAt?: string
}

@Injectable({
  providedIn: 'root',
})
export class PaginationCache {
  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.cache = new Map()
      })
  }

  cache: Map<string, CacheEntry> = new Map()

  get(req: HttpRequest<any>): CacheEntry {
    const url = req.urlWithParams
    return this.cache.get(url)
  }

  put(req: HttpRequest<any>, body: PagedRestResponse<any>): void {
    const url = req.urlWithParams
    const entry = this.createEntry(url, body)
    this.cache.set(url, entry)
  }

  bust(req: HttpRequest<any>): boolean {
    const url = req.urlWithParams
    return this.cache.delete(url)
  }

  private createEntry(key: string, body: PagedRestResponse<any>) {
    let entry =
      this.cache.get(key) || new CacheEntry({ data: [], total: body.total })
    entry.appendData(body.data, body.startAt)
    return entry
  }
}

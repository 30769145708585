/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core'
import { WebSocketMessage } from '@mmx/shared'
import { environment } from 'environments/environment'
import {
  BehaviorSubject,
  distinctUntilKeyChanged,
  filter,
  interval,
  map,
  merge,
  mergeWith,
  Observable,
  of,
  retry,
  share,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError,
  timer,
} from 'rxjs'
import {
  webSocket,
  WebSocketSubject,
  WebSocketSubjectConfig,
} from 'rxjs/webSocket'

import { ParamsService } from '..'
import { AuthService } from './auth.service'
import { Initializer } from './initializer'

function closeEventMessageObject(event: CloseEvent): CloseEventMessage {
  return {
    code: event.code,
    reason: event.reason,
    wasClean: event.wasClean,
  }
}

function logMessage(message: string) {
  return (event?: Event | CloseEventMessage | WebSocketMessage) => {
    // eslint-disable-next-line no-console
    console.debug(`[DataService]: ${message}`, event)
  }
}

const INITIAL_RECONNECT_INTERVAL = 1000
const RECONNECT_ATTEMPTS = 10
const HEART_BEAT_INTERVAL = 5 * 60000 // 5min

type CloseEventMessage = { code: number; reason: String; wasClean: boolean }

@Injectable({
  providedIn: 'root',
})
export class WebSocketService extends Initializer {
  private openSubject = new Subject<Event>()
  private closeSubject = new Subject<CloseEvent>()

  private _websocketSubject: WebSocketSubject<WebSocketMessage>
  private get websocketSubject(): WebSocketSubject<WebSocketMessage> {
    return this._websocketSubject
  }
  private set websocketSubject(wsSubject: WebSocketSubject<WebSocketMessage>) {
    this._websocketSubject = wsSubject
    this._websocketSubject.subscribe(logMessage('message received'))
  }

  private clinician$ = this.auth.changes.pipe(
    distinctUntilKeyChanged('id'),
    shareReplay(1),
  )

  private clinicianTopic$ = this.clinician$.pipe(
    map((clinician) => clinician && `clinician.${clinician.id}`),
  )

  private patientTopic$: Observable<string> = this.paramsService.patientDefined$.pipe(
    map((id) => `patient.${id}`),
  )

  private appointmentTopic$: Observable<string> = this.paramsService.appointmentId$.pipe(
    map(([, appointmentId]) => appointmentId ? `appointment.${appointmentId}` : undefined),
  )

  private socketConfig = new BehaviorSubject<
    WebSocketSubjectConfig<WebSocketMessage>
  >({
    url: environment.wss,
    openObserver: this.openSubject,
    closeObserver: this.closeSubject,
  })

  private connectionError$ = this.closeSubject.pipe(
    switchMap((event) => throwError(() => event)),
  )

  /**
   * Emit config on every connection error, until openSubject emits
   */
  private retryConnection = this.socketConfig.pipe(
    takeUntil(this.openSubject),
    mergeWith(this.connectionError$),
    retry({
      count: RECONNECT_ATTEMPTS,
      delay: (_, count) => {
        return timer(count * INITIAL_RECONNECT_INTERVAL)
      },
    }),
  )

  private websocketInit$ = this.initSubject.pipe(
    switchMap((_) => this.retryConnection),
  )

  /**
   * Send a message over the WebSocket connection.
   *
   * If the socket is not currently open, the message will be queued up in memory.
   * This was historically a ReplaySubject, but that results in memory issues
   * (keeps the full history of all sent messages in memory) and results in old
   * messages being retried if the web socket reconnects (which happens).
   *
   * Example:
   *  this.ws.messageQueue.next({
   *    action: 'eligibilities.create',
   *    ...body,
   *  })
   */
  messageQueue = new Subject<WebSocketMessage>()

  /**
   * Stream of WebSocket messages sent to all clinicians (reports, notifications, integrations)
   */
  clinicMessage$ = this.openSubject.pipe(
    switchMap((_) =>
      of('clinic').pipe(
        switchMap((topic) =>
          this.multiplex(topic).pipe(takeUntil(this.closeSubject)),
        ),
      ),
    ),
    share(),
  )

  /**
   * Stream of WebSocket messages sent to currently logged-in clinician
   */
  clinicianMessage$ = this.openSubject.pipe(
    switchMap((_) =>
      this.clinicianTopic$.pipe(
        filter(Boolean),
        switchMap((topic) =>
          this.multiplex(topic).pipe(takeUntil(this.closeSubject)),
        ),
      ),
    ),
    share(),
  )

  /**
   * Stream of WebSocket messages sent to a patient, by patient ID (chat)
   */
  patientMessage$ = this.openSubject.pipe(
    switchMap((_) =>
      this.patientTopic$.pipe(
        switchMap((topic) =>
          this.multiplex(topic).pipe(
            takeUntil(merge(this.paramsService.patientUndefined$, this.closeSubject)),
          ),
        ),
      ),
    ),
    share(),
  )

  /**
   * Stream of WebSocket messages sent to current appointment on updates.
   */
  appointment$ = this.openSubject.pipe(
    switchMap((_) =>
      this.appointmentTopic$.pipe(
        filter(Boolean),
        switchMap((topic) =>
          this.multiplex(topic).pipe(takeUntil(this.closeSubject)),
        ),
      ),
    ),
    share(),
  )

  private closingMessage$ = this.closeSubject.pipe(
    map(closeEventMessageObject),
    tap(logMessage('connection closed')),
  )

  private message$ = merge(
    this.clinicMessage$,
    this.clinicianMessage$,
    this.patientMessage$,
    this.appointment$,
  ).pipe(tap(logMessage('topic message received')))

  errorMessages$ = this.message$.pipe(
    filter((message: WebSocketMessage) => message.error),
  )

  constructor(private auth: AuthService, private paramsService: ParamsService) {
    super()

    let queue: WebSocketMessage[] = []
    this.messageQueue.subscribe(message => queue.push(message))

    // when a new web socket opens, emit an auth message
    this.openSubject.pipe(map(this.authMessage)).subscribe(message => {
      this.websocketSubject.next(this.addAuth(message))
    })

    // when a new web socket opens, start a heart beat monitor
    this.openSubject.pipe(
      switchMap(() => interval(HEART_BEAT_INTERVAL)),
      map(this.heartBeat),
    ).subscribe(message => {
      this.websocketSubject.next(this.addAuth(message))
    })

    // when a new web socket opens, if there is anything in queue, send them
    this.openSubject.subscribe(() => {
      for (const message of queue) {
        this.websocketSubject.next(this.addAuth(message))
      }
      queue = []
    })

    // when a new web socket opens, listen for new messages
    this.openSubject.pipe(
      switchMap(() => this.messageQueue),
    ).subscribe((message) => {
      const queueIndex = queue.findIndex(m => m == message)
      if (queueIndex > -1) {
        queue = queue.splice(queueIndex, 1)
      }

      this.websocketSubject.next(this.addAuth(message))
    })

    merge(this.closingMessage$, this.message$).subscribe()

    this.websocketInit$.subscribe(
      (config) => (this.websocketSubject = webSocket(config)),
    )
  }

  private addAuth(body: WebSocketMessage) {
    return {
      action: body.action,
      body,
      headers: {
        Authorization: this.auth.sessionToken,
      },
    }
  }

  private authMessage = () =>
    <WebSocketMessage>{
      type: 'hello',
      text: this.auth.sessionToken,
    }

  private heartBeat = () => <WebSocketMessage>{ type: 'echo' }

  private multiplex(topic: string) {
    return this.websocketSubject.multiplex(
      () => ({ subscribe: topic }),
      () => ({ unsubscribe: topic }),
      (message) => message.topic === topic,
    )
  }
}

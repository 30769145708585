import { BaseModel, BaseModelData } from '@mmx/shared'

export interface NoteData extends BaseModelData {
  patientId: string
  title?: string
  text: string
  clinicianId?: string
  clinician?: {
    title?: string
    name?: string
    abbreviation?: string
  }
  appointmentId?: string
}

export class Note extends BaseModel {
  patientId: string
  title: string
  text: string
  clinicianId: string
  clinician?: {
    title?: string
    name?: string
    abbreviation?: string
  }
  appointment?: {
    eid: string
    startAt: string
  }
  appointmentId?: string
  autoGenerated?: boolean

  constructor(data: NoteData) {
    super(data, true)
  }
}

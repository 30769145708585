import { OverlayModule } from '@angular/cdk/overlay'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from 'environments/environment'

import { AppComponent } from './app.component'
import { AppRoutes } from './app.routing'
import { CoreModule } from './core/core.module'
import { MMXIconModule } from './mmx-icon'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes, {
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
    }),
    OverlayModule,
    ServiceWorkerModule.register('/custom-service-worker.js', {
      enabled: environment.production,
    }),

    // this is the only custom module included, everything else should exist within lazy-loaded modules
    CoreModule,
    MMXIconModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core'
import { CrudModel, CrudOptions, ParamsObject } from '@mmx/shared'
import { pick } from 'lodash'
import { map, Observable } from 'rxjs'

import { AuthenticatedRestService } from './auth-rest.service'

type ExtraBody = Record<string, any>

/**
 * A simple CRUD handler for saving simple models
 */
@Injectable({
  providedIn: 'root',
})
export class CrudService {
  constructor(protected api: AuthenticatedRestService) {}

  getOptions<T extends CrudModel>(model: T): CrudOptions {
    const modelClass: typeof CrudModel = model.constructor as any
    return modelClass.crudOptions!
  }

  getBase<Model extends CrudModel>(model: Model) {
    return this.getOptions(model).base
  }

  save<Model extends CrudModel>(model: Model, params?: ParamsObject, extraBody?: ExtraBody): Observable<Model> {
    return model.id
      ? this.update(model, params, extraBody)
      : this.create(model, params, extraBody)
  }

  index<ModelClass extends typeof CrudModel>(modelClass: ModelClass, params?: ParamsObject): Observable<InstanceType<ModelClass>[]> {
    const route = modelClass.crudOptions!.base
    return this.api.get<any[]>(route, this.api.convertToParams(params)).pipe(
      map((resp) => {
        return resp.data.map((object) => new modelClass(object) as InstanceType<ModelClass>)
      }),
    )
  }

  create<Model extends CrudModel>(model: Model, params?: ParamsObject, extraBody?: ExtraBody): Observable<Model> {
    const route = this.getBase(model)
    const body = {
      ...model.toJSON(),
      ...extraBody,
    }
    return this.api.post(route, body, {
      params: this.api.convertToParams(params),
    }).pipe(map((resp) => {
      const modelClass = model.constructor as typeof CrudModel
      return new modelClass(resp.data) as Model
    }))
  }

  read<ModelClass extends typeof CrudModel>(modelClass: ModelClass, id: string): Observable<InstanceType<ModelClass>> {
    const route = modelClass.getObjectEndpoint(id)
    return this.api.get<any>(route).pipe(
      map((resp) => {
        return new modelClass(resp.data) as InstanceType<ModelClass>
      }),
    )
  }

  update<Model extends CrudModel>(model: Model, params?: ParamsObject, extraBody?: ExtraBody): Observable<Model> {
    const route = model.getObjectEndpoint()
    const body = {
      ...model.toJSON(),
      ...extraBody,
    }
    return this.api.put(route, body, {
      params: this.api.convertToParams(params),
    }).pipe(map((resp) => {
      const modelClass = model.constructor as typeof CrudModel
      return new modelClass(resp.data) as Model
    }))
  }

  delete<Model extends CrudModel>(model: Model, params?: ParamsObject) {
    const route = model.getObjectEndpoint()
    const body = model.toJSON()
    return this.api.delete(route, {
      params: this.api.convertToParams(params),
      body,
    })
  }

  patch<Model extends CrudModel>(model: Model, fields: Array<keyof Model>, params?: ParamsObject): Observable<Model> {
    const route = model.getObjectEndpoint()
    const body = pick(model.toJSON(), fields)
    return this.api.patch(route, body, {
      params: this.api.convertToParams(params),
    }).pipe(map((resp) => {
      const modelClass = model.constructor as typeof CrudModel
      return new modelClass(resp.data) as Model
    }))
  }

  batchDelete<Model extends CrudModel>(...models: Model[]) {
    const route = this.getBase(models[0])
    const ids = models.map((model) => typeof model === 'string' ? model : model.id)
    return this.api.delete(route, {
      body: { ids },
    })
  }
}

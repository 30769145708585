import { BaseModel, BaseModelData, Patient } from '@mmx/shared'

import { getFacilityAndLocalTime } from '../utils/facility-and-local-time'
import { formatPhoneNumber } from '../utils/phone'
import { Clinic } from './clinic'

export class MessageHistoryRecordModel extends BaseModel {
  text: string
  translatedText?: string
  detectedLanguage?: string
  method: string
  direction: MessageHistoryRecord.DIRECTION
  internal?: boolean
  appointmentId?: string
  patientName: string
  patientId: string
  status: string
  from: string
  to: string
  sid?: string
  workflowId?: string
  attachments?: string[]
  clinician?: {
    title?: string
    name?: string
    abbreviation?: string
  }

  constructor(data: MessageHistoryRecordData) {
    super(data, true)
    this.text = data.text
    this.method = data.method
    this.direction = data.direction
    this.appointmentId = data.appointmentId
    this.status = data.status
    this.from = data.from
    this.to = data.to
    this.sid = data.sid
    this.workflowId = data.workflowId
    this.internal = data.internal

    if (data.patient) {
      const patient = new Patient(data.patient)
      this.patientName = patient.standardName
      this.patientId = patient.id
    }
  }

  get date() {
    return this.createdAt.format('LL')
  }

  get methodIcon() {
    switch (this.method) {
      case 'sms':
        return 'phone'
      case 'email':
        return 'mail'
      default:
        throw new Error('Unknown message source type')
    }
  }

  get directionName() {
    switch (this.direction) {
      case MessageHistoryRecord.DIRECTION.OUTGOING:
        return 'Outgoing'
      case MessageHistoryRecord.DIRECTION.INCOMING:
        return 'Incoming'
      default:
        throw new Error('Unknown message direction type')
    }
  }

  getTooltip(clinic: Clinic) {
    const dt = getFacilityAndLocalTime(this.createdAt, { clinic }) ?? this.createdAt.local().format('L LT')
    let text: string

    const securely = this.internal ? ' securely' : ''

    if (this.direction === MessageHistoryRecord.DIRECTION.OUTGOING && this.to) {
      text = `Message sent${securely} to ${
        this.method === 'sms' ? formatPhoneNumber(this.to) : this.to
      } at ${dt}`
    } else if (
      this.direction === MessageHistoryRecord.DIRECTION.INCOMING &&
      this.from
    ) {
      const from = this.internal
        ? 'patient'
        : (this.method === 'sms' ? formatPhoneNumber(this.from) : this.from)
      text = `Message received${securely} from ${from} at ${dt}`
    }

    if (this.clinician?.name) {
      text += ` by ${this.clinician.name}`
    }

    return text
  }
}

export namespace MessageHistoryRecord {
  export enum DIRECTION {
    OUTGOING = '1',
    INCOMING = '2',
  }
}

export interface MessageHistoryRecordData extends BaseModelData {
  text: string
  method: string
  direction: MessageHistoryRecord.DIRECTION
  appointmentId?: string
  patient?: Patient
  status?: string
  from: string
  to: string
  sid?: string
  workflowId?: string
  attachments?: string[]
  internal?: boolean
}

import { AbstractControl } from '@angular/forms'

export function urlValidator(control: AbstractControl) {
  if (control.value && control.value.length) {
    const domain = control.value
    const domainRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    const forbidden = !domainRegex.test(domain)

    return forbidden ? { invalidUrl: true } : null
  }

  return null
}

import { AUTHORIZATION_STATUS, BaseModel, BaseModelData, Facility, FacilityData, INSURANCE_PROVIDER_LOOKUP_TYPE, SchedulingRuleGroupSettings } from '@mmx/shared'

import { APPOINTMENT_REQUEST_TYPE } from '../appointment-request.model'

export enum SCHEDULING_RULE_GROUP_TYPE {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

export interface SchedulingRuleGroupQuestions {
  schedulingQuestion: string
  schedulingQuestionAnswer: string
}

export interface SchedulingRuleGroupData extends BaseModelData {
  name: string
  weight: number
  type: SCHEDULING_RULE_GROUP_TYPE
  facilityIds?: string[]
  facilities?: FacilityData[]
  insuranceTypes?: INSURANCE_PROVIDER_LOOKUP_TYPE[]
  authorizationStatuses?: AUTHORIZATION_STATUS[]
  appointmentRequestTypes?: APPOINTMENT_REQUEST_TYPE[]
  categoryIds?: string[]
  schedulingQuestions?: SchedulingRuleGroupQuestions[]
  settings: SchedulingRuleGroupSettings
}

export class SchedulingRuleGroup extends BaseModel {
  name: string
  weight: number
  type: SCHEDULING_RULE_GROUP_TYPE
  facilityIds?: string[]
  facilities?: Facility[]
  insuranceTypes?: INSURANCE_PROVIDER_LOOKUP_TYPE[]
  authorizationStatuses?: AUTHORIZATION_STATUS[]
  appointmentRequestTypes?: APPOINTMENT_REQUEST_TYPE[]
  categoryIds?: string[]
  schedulingQuestions?: SchedulingRuleGroupQuestions[]
  settings: SchedulingRuleGroupSettings

  public get isDefault(): boolean {
    return this.type === SCHEDULING_RULE_GROUP_TYPE.DEFAULT
  }

  constructor(data: SchedulingRuleGroupData) {
    super(data)

    this.name = data.name
    this.weight = data.weight
    this.type = data.type
    this.facilityIds = data.facilityIds
    this.facilities = (data.facilities || []).map(facility => new Facility(facility))
    this.insuranceTypes = data.insuranceTypes
    this.authorizationStatuses = data.authorizationStatuses
    this.appointmentRequestTypes = data.appointmentRequestTypes
    this.categoryIds = data.categoryIds
    this.schedulingQuestions = data.schedulingQuestions
    this.settings = data.settings
  }
}

import { Injectable } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'

@Injectable()
export class SvgIconsService {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry
      .addSvgIcon(
        'intake',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons-svg/intake.svg'),
      )
      .addSvgIcon(
        'authorization',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons-svg/authorization.svg',
        ),
      )
      .addSvgIcon(
        'payment',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons-svg/payment.svg',
        ),
      )
      .addSvgIcon(
        'estimates',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons-svg/estimates.svg',
        ),
      )
      .addSvgIcon(
        'todo_notes',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons-svg/todo-notes.svg',
        ),
      )
      .addSvgIcon(
        'today',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons-svg/today.svg'),
      )
      .addSvgIcon(
        'tomorrow',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons-svg/tomorrow.svg',
        ),
      )
      .addSvgIcon(
        'week',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons-svg/week.svg'),
      )
      .addSvgIcon(
        'incomplete',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons-svg/incomplete.svg',
        ),
      )
      .addSvgIcon(
        'complete',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons-svg/complete.svg',
        ),
      )
      .addSvgIcon(
        'star',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons-svg/star.svg'),
      )
      .addSvgIcon(
        'check_circle',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons-svg/check-circle.svg',
        ),
      )
      .addSvgIcon(
        'warning_circle',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons-svg/warning-circle.svg',
        ),
      )
      .addSvgIcon(
        'cancel_circle',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons-svg/cancel-circle.svg',
        ),
      )
  }
}

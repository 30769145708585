import { CRUD, CrudModel } from '@mmx/shared'

import { PortalData } from './portal.data'

@CRUD({
  base: '/crud/portal',
})
export class PortalModel extends CrudModel {
  public name!: string
  public url!: string

  constructor(data: PortalData) {
    super(data, true)
  }

  override toJSON() {
    return {
      ...super.toJSON(),
      name: this.name,
      url: this.url,
    }
  }
}

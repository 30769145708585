import { Injectable } from '@angular/core'
import { ClinicModulesKeys, WebsocketAction } from '@mmx/shared'
import { filterAction, WebSocketService } from 'app/core'
import { BehaviorSubject, map, Observable, scan, startWith } from 'rxjs'

type MenuItemType = 'link' | 'icon' | 'separator' | 'extLink' | 'dropDown'

export interface IChildItem {
  type?: MenuItemType
  /**
   * Used as display text for item and title for separator type
   */
  name?: string
  state?: string // Router state
  /**
   * If this menu item supports having a badge for unread notifications/alerts,
   * this will be the key for retreiving the alert count from the NavigationAlerts
   * object.
   */
  alerts?: keyof NavigationAlerts
  icon?: string
  sub?: IChildItem[]
  modules?: ClinicModulesKeys[]
  permission?: string
}

interface IMenuItem extends IChildItem {
  type: MenuItemType | 'dropDown'
  /**
   * If true, item will not be appeared in sidenav.
   */
  disabled?: boolean
  sub?: IChildItem[]
  modules?: ClinicModulesKeys[]
  permission?: string
}

export interface NavigationAlerts {
  appointmentRequests?: number
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [
    {
      type: 'link',
      name: 'navigation.dashboard',
      icon: 'home',
      state: 'dashboard',
      permission: 'appointment.view',
    },
    {
      type: 'link',
      name: 'navigation.inventory-dashboard',
      icon: 'dataset',
      state: 'inventory-dashboard',
      permission: 'appointment.view',
    },
    {
      type: 'link',
      name: 'navigation.worklist',
      icon: 'work_history',
      state: 'worklist',
      modules: [ClinicModulesKeys.WORKLIST],
      permission: 'work-list.view',
    },
    {
      type: 'link',
      name: 'navigation.waiting-list',
      icon: 'today',
      state: 'wait-list',
      modules: [ClinicModulesKeys.APPOINTMENT_WAIT_LIST],
      permission: 'appointment.waiting-list.view',
    },
    {
      type: 'link',
      name: 'navigation.appointment-requests',
      icon: 'book_online',
      state: 'appointment-requests',
      alerts: 'appointmentRequests',
      modules: [ClinicModulesKeys.APPOINTMENT_REQUESTS],
      permission: 'appointment.request.view',
    },
    {
      type: 'link',
      name: 'navigation.patients',
      icon: 'people', // 'person'
      state: 'patients',
      permission: 'patient.view',
    },
    {
      type: 'link',
      name: 'navigation.billing',
      icon: 'receipt',
      state: 'billing',
      permission: 'invoice.view',
      modules: [ClinicModulesKeys.PAYMENT],
    },
    {
      type: 'link',
      name: 'navigation.transactions',
      icon: 'payments',
      state: 'transactions',
      modules: [ClinicModulesKeys.PAYMENT],
      permission: 'transaction.view',
    },
    {
      type: 'link',
      name: 'navigation.reports',
      icon: 'folder',
      state: 'reports',
      permission: 'report.view',
    },
    {
      type: 'dropDown',
      name: 'navigation.utilities',
      icon: 'construction',
      sub: [
        {
          type: 'link',
          name: 'navigation.eligibility-benefits',
          icon: 'calculate',
          state: 'procedures-estimate',
          modules: [ClinicModulesKeys.PROCEDURES_ESTIMATE],
          permission: 'procedures.estimate',
        },
        {
          type: 'link',
          name: 'navigation.notifications',
          icon: 'notifications',
          state: 'notifications',
          permission: 'notification.view',
        },
        {
          type: 'link',
          name: 'navigation.message-history',
          icon: 'message',
          state: 'message-history',
          permission: 'message.view',
        },
        {
          type: 'link',
          name: 'navigation.manually-import',
          icon: 'import_export',
          state: 'import',
          permission: 'import.create',
        },
      ],
    },
    {
      type: 'link',
      name: 'navigation.system-adm',
      icon: 'settings',
      state: 'settings',
      permission: 'setting.view',
    },
  ]

  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu)
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable()

  constructor(private ws: WebSocketService) {
    this.ws.messageQueue.next({
      action: WebsocketAction.ALERTS,
    })
  }

  alerts$: Observable<NavigationAlerts> = this.ws.clinicMessage$.pipe(
    filterAction(WebsocketAction.ALERTS),
    scan((acc, message) => {
      if (message.appointmentRequests) {
        acc.appointmentRequests = message.appointmentRequests
      }

      return acc
    }, {} as NavigationAlerts),
    startWith({}),
  )

  alertCount$: Observable<number> = this.alerts$.pipe(
    map(alerts => {
      return (alerts.appointmentRequests ?? 0)
    }),
  )
}

import { Clinician, ClinicianData, Patient, PatientData } from '@mmx/shared'
import { cloneDeep, filter, forEach, last } from 'lodash'

import { Difference, getDifference } from '../utils/diff'

export interface IEventActor {
  type: 'patient' | 'clinician' | 'patientpal' | 'unidentified'
  id?: string
  ip?: string
}

export interface IEventChange {
  attribute: string
  outdated?: any
  updated?: any
  diff?: Difference
}

export interface EventRecordData {
  type: string // message-history.created
  tableName: string // "message-history",
  recordId: string
  changes: null | IEventChange[]
  data: any
  actor: IEventActor
  created: number // 1603295573578
  expires: number // 1611071573
  patient: PatientData
  clinician?: ClinicianData
}

const BLACKLISTED_ATTRIBUTES = [
  'clinicId',
  'created',
  'createdAt',
  'id',
  'numberOfIntakeRetries',
  'seenCount',
  'updatedAt',
  'carrierLookupId',
]

export class EventRecord {
  type: string
  actionType: 'created' | 'updated' | 'deleted' | 'error' | string
  tableName: string
  recordId: string
  changes: null | IEventChange[]
  data: any
  actor: IEventActor
  created: number
  expires: number
  patient: Patient
  clinician?: Clinician
  expanded?: boolean

  constructor(data: EventRecordData) {
    this.recordId = data.recordId
    this.tableName = data.tableName
    this.type = data.type
    this.actionType = last<string>(this.type.split('.'))
    this.actor = data.actor
    this.created = data.created
    this.expires = data.expires

    if (data.data) {
      this.data = data.data
    }

    if (data.patient) {
      this.patient = new Patient(data.patient)
    }

    if (data.clinician) {
      this.clinician = new Clinician(data.clinician)
    }

    if (data.changes) {
      this.changes = filter(data.changes.map((change) => {
        if (BLACKLISTED_ATTRIBUTES.includes(change.attribute)) {
          return
        }

        if (change.outdated != null && change.updated != null) {
          change.diff = getDifference(change.outdated, change.updated)
        }

        return change
      }))
    } else {
      const changes: IEventChange[] = []

      forEach(data.data, (value, attribute) => {
        if (!BLACKLISTED_ATTRIBUTES.includes(attribute)) {
          changes.push({
            attribute,
            outdated: null,
            updated: value,
          })
        }
      })

      this.changes = changes
    }
  }

  get logModel() {
    switch (this.tableName) {
      case 'patient':
        return 'profile'
      case 'transaction':
        return 'payment'
      case 'card':
        return 'credit card'
      case 's3':
        return `${this.recordId.split('.')[0]} photo ID`
      default:
        return this.tableName
    }
  }

  get url(): string | undefined {
    if (!this.data || !this.data.patientId) {
      return
    }

    switch (this.tableName) {
      case 'appointment':
        return `/patient/${this.data.patientId}/appointment/${this.recordId}`
      case 'intake':
        return `/patient/${this.data.patientId}/intake/${this.recordId}`
      case 'transaction':
        return `/patient/${this.data.patientId}/transaction/${this.recordId}`
      case 'eligibility':
        return `/patient/${this.data.patientId}/eligibility/${this.recordId}`
    }
  }

  get setAttributes(): IEventChange[] | undefined {
    if (this.changes) {
      return this.changes.filter((change) => change.updated != null && change.outdated == null)
    }
  }

  get updatedAttributes(): IEventChange[] | undefined {
    if (this.changes) {
      return this.changes.filter((change) => change.updated != null && change.outdated != null)
    }
  }

  get deletedAttributes(): string[] | undefined {
    if (this.changes) {
      return this.changes.filter((change) => change.updated == null).map((change) => change.attribute)
    }
  }

  toJSON() {
    return {
      type: this.type,
      actor: this.actor,
      recordId: this.recordId,
      tableName: this.tableName,
      data: cloneDeep(this.data),
      changes: this.changes,
    }
  }
}

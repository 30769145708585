export enum COLOR {
  GREEN = 'green',
  GREY = 'grey',
  RED = 'red',
  YELLOW = 'yellow',
  FLASHING_YELLOW = 'flashing yellow',
  FLASHING_GREEN = 'flashing green',
  FLASHING_RED = 'flashing red',
  DEFAULT = '',
}

import { BaseModel, BaseModelData, Facility, FacilityData, Patient } from '@mmx/shared'

import { AppointmentData, AppointmentModel } from './appointment.model'

export interface NotificationData extends BaseModelData {
  locationId?: string
  location?: FacilityData
  patient: Patient
  appointment: AppointmentData
  title: string
  body: string
  status: string
  type: string
  link: string
}

export class NotificationModel extends BaseModel {
  locationId?: string
  location?: Facility
  patient: Patient
  appointment: AppointmentModel
  title: string
  body: string
  status: string
  type: string
  link: string

  constructor(data: NotificationData) {
    super(data, true)

    this.id =
      typeof data.createdAt === 'string'
        ? data.createdAt
        : data.createdAt.toISOString()

    if (data.patient) {
      this.patient = new Patient(data.patient)
    }

    if (data.appointment) {
      this.appointment = new AppointmentModel(data.appointment)
    }

    if (data.location) {
      this.location = new Facility(data.location)
    }

    if (typeof data.link === 'string') {
      this.link = data.link.replace(/\/clinician\//, '/')
    }
  }

  get statusDisplay(): string {
    switch (this.status) {
      case Notification.STATUS.OPEN:
        return 'Open'
      case Notification.STATUS.IGNORED:
        return 'Ignored'
      case Notification.STATUS.COMPLETED:
        return 'Completed'
    }
    return ''
  }

  get typesDisplay(): string {
    switch (this.type) {
      case Notification.TYPE.DISPUTES:
        return 'Disputes'
      case Notification.TYPE.EB:
        return 'E&B'
      case Notification.TYPE.INCORRECT_DOB:
        return 'Incorrect DOB'
      case Notification.TYPE.INCORRECT_MEMBER_ID:
        return 'Incorrect Member ID'
      case Notification.TYPE.INCORRECT_PATIENT_NAME:
        return 'Incorrect Patient name'
      case Notification.TYPE.INCORRECT_PHONE_NUMBER:
        return 'Incorrect Phone Number'
      case Notification.TYPE.INTAKE_SUBMITTED:
        return 'Intake Submitted'
      case Notification.TYPE.MESSAGES:
        return 'Messages'
      case Notification.TYPE.NEGATIVE_APPOINTMENT_CONFIRMATION:
        return 'Rejected Confirmation'
      case Notification.TYPE.PAYMENTS:
        return 'Payments'
      case Notification.TYPE.WORKFLOW:
        return 'Workflow'
    }
    return ''
  }

  get date() {
    return this.updatedAt.format('LL')
  }

  get from() {
    return this.createdAt ? this.createdAt.fromNow() : ''
  }
}

export namespace Notification {
  export enum STATUS {
    OPEN = 'O',
    IGNORED = 'I',
    COMPLETED = 'D',
  }

  export enum TYPE {
    DISPUTES = 'D',
    EB = 'E',
    INCORRECT_DOB = 'INC.DOB',
    INCORRECT_MEMBER_ID = 'INC.MID',
    INCORRECT_PATIENT_NAME = 'INC.NAME',
    INCORRECT_PHONE_NUMBER = 'INC.PHONE',
    INCORRECT_EMAIL = 'INC.EMAIL',
    INTAKE_SUBMITTED = 'INT',
    MESSAGES = 'M',
    NEGATIVE_APPOINTMENT_CONFIRMATION = 'NAC',
    PAYMENTS = 'P',
    WORKFLOW = 'W',
    PATIENT_MISSING_FROM_EMR = 'PAT.MIS',
  }
}

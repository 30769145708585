import { BaseModel, BaseModelData } from '@mmx/shared'

export type MessageTemplateMultiLingual<T = string> = T | { [key: string]: T }

export interface MessageTemplateData extends BaseModelData {
  path?: string
  name?: string
  type?: MessageTemplate.TYPE
  workflowId?: string
  emailSubject?: MessageTemplateMultiLingual
  htmlTemplate?: MessageTemplateMultiLingual
  textTemplate?: MessageTemplateMultiLingual
  phoneTemplate?: MessageTemplateMultiLingual
  emailAttachments?: MessageTemplateMultiLingual<string[]>
  isHtmlTemplateEnabled?: boolean
  isTextTemplateEnabled?: boolean
  isPhoneTemplateEnabled?: boolean
}

export class MessageTemplateModel extends BaseModel {
  path?: string
  name?: string
  type?: MessageTemplate.TYPE
  workflowId?: string
  emailSubject?: MessageTemplateMultiLingual
  htmlTemplate?: MessageTemplateMultiLingual
  textTemplate?: MessageTemplateMultiLingual
  phoneTemplate?: MessageTemplateMultiLingual
  emailAttachments?: MessageTemplateMultiLingual<string[]>
  isHtmlTemplateEnabled: boolean
  isTextTemplateEnabled: boolean
  isPhoneTemplateEnabled: boolean

  get sortKey(): string {
    return this.name ? this.name.toLowerCase() : undefined
  }

  get link(): string {
    return [
      '',
      'settings',
      'message-templates',
      this.id || this.type,
      'info',
    ].join('/')
  }

  constructor(data?: MessageTemplateData) {
    super(data, true)

    if (
      this.name == null &&
      this.type &&
      this.type !== MessageTemplate.TYPE.CUSTOM
    ) {
      this.name = this.type
    }

    this.emailSubject = data?.emailSubject || {}
    this.htmlTemplate = data?.htmlTemplate || {}
    this.textTemplate = data?.textTemplate || {}
    this.phoneTemplate = data?.phoneTemplate || {}
    this.emailAttachments = data?.emailAttachments || {}
  }

  toJSON() {
    return {
      id: this.id,
      path: this.path,
      type: this.type,
      name: this.name,
      workflowId: this.workflowId,
      emailSubject: this.emailSubject,
      htmlTemplate: this.htmlTemplate,
      textTemplate: this.textTemplate,
      phoneTemplate: this.phoneTemplate,
      emailAttachments: this.emailAttachments,
      isHtmlTemplateEnabled: this.isHtmlTemplateEnabled,
      isTextTemplateEnabled: this.isTextTemplateEnabled,
      isPhoneTemplateEnabled: this.isPhoneTemplateEnabled,
    }
  }
}

export namespace MessageTemplate {
  export enum TYPE {
    APPOINTMENT_CONFIRMATION = 'appointment-confirmation',
    APPOINTMENT_REQUEST = 'appointment-request',
    PATIENT_VERIFY = 'patient-verify',
    INTAKE_LINK = 'intake-link',
    INVOICE_LINK = 'invoice-link',
    INVOICE_PDF = 'invoice-pdf',
    RESULTS_LINK = 'results-link',
    SURVEY_LINK = 'survey-link',
    WAIT_LIST_REACH_OUT = 'wait-list-reach-out',
    TWILIO_AUTOMATE = 'twilio.automate',
    TWILIO_APPOINTMENT_CANCEL = 'twilio.appointment-cancel',
    TWILIO_APPOINTMENT_CONFIRMATION = 'twilio.appointment-confirmation',
    TWILIO_PHONE_WRONG = 'twilio.phone-wrong',
    TWILIO_PHONE_UNSUBSCRIBE = 'twilio.phone-unsubscribe',
    TWILIO_PHONE_SUBSCRIBE = 'twilio.phone-subscribe',
    CUSTOM = 'custom',
  }
}

import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { RestService } from '@mmx/shared'

import { Translation } from '../models'
import { ClinicService } from './clinic.service'

@Injectable({
  providedIn: 'root',
})
export class TranslationService {

  constructor(
    private restService: RestService,
    private clinicService: ClinicService,
  ) {
  }

  getTranslations(lang?: string) {
    const route = `/clinics/${this.clinicService.clinicId}/i18n`
    let params = new HttpParams()

    if (lang) {
      params = params.set('lang', lang)
    }

    return this.restService.get<any[]>(route, params)
  }

  updateTranslation(translation: Translation) {
    const route = `/clinics/${this.clinicService.clinicId}/i18n`
    return this.restService.put<any[]>(route, translation)
  }

  deleteTranslation(translation: Translation) {
    const route = `/clinics/${this.clinicService.clinicId}/i18n/${translation.id}`
    return this.restService.delete<any[]>(route)
  }

  generateTranslations() {
    const route = `/clinics/${this.clinicService.clinicId}/i18n/generate`
    return this.restService.get<any[]>(route)
  }
}

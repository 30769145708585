import { COLOR } from '../data'

export const getWorstStatus = <T extends string>(
  statusColors: Record<T, COLOR>,
  statuses: T[],
): T | undefined => {
  const statusRank = Object.keys(statusColors) as T[]
  if (!statuses.length) {
    return undefined
  }

  let worstStatus = statusRank[statusRank.length - 1]

  for (const status of statuses) {
    if (statusRank.indexOf(status) < statusRank.indexOf(worstStatus)) {
      worstStatus = status
    }
  }

  return worstStatus
}

import { SelectOption } from '@mmx/shared'

export const DaysOfWeek: SelectOption<number>[] = [
  { viewValue: 'Sunday', value: 7 },
  { viewValue: 'Monday', value: 1 },
  { viewValue: 'Tuesday', value: 2 },
  { viewValue: 'Wednesday', value: 3 },
  { viewValue: 'Thursday', value: 4 },
  { viewValue: 'Friday', value: 5 },
  { viewValue: 'Saturday', value: 6 },
]

import { SelectOption } from '@mmx/shared'

import {} from '..'

export const PlaceOfServiceTypes: SelectOption<string>[] = [
  { value: 'ambulance_air_or_water', viewValue: 'Ambulance Air Or Water' },
  { value: 'ambulance_land', viewValue: 'Ambulance Land' },
  {
    value: 'ambulatory_substance_abuse',
    viewValue: 'Ambulatory Substance Abuse',
  },
  { value: 'assisted_living', viewValue: 'Assisted Living' },
  { value: 'birthing_center', viewValue: 'Birthing Center' },
  { value: 'custodial', viewValue: 'Custodial' },
  { value: 'end_stage_renal', viewValue: 'End Stage Renal' },
  { value: 'er_hospital', viewValue: 'Hospital or Emergency Room' },
  { value: 'federal_qualified', viewValue: 'Federal Qualified' },
  { value: 'group_home', viewValue: 'Group Home' },
  { value: 'home', viewValue: 'Home' },
  { value: 'hospice', viewValue: 'Hospice' },
  { value: 'ihs_freestanding', viewValue: 'IHS Freestanding' },
  { value: 'ihs_provider', viewValue: 'IHS Provider' },
  { value: 'immunization', viewValue: 'Immunization' },
  { value: 'independent_clinic', viewValue: 'Independent Clinic' },
  { value: 'independent_lab', viewValue: 'Independent Lab' },
  { value: 'indian_freestanding', viewValue: 'Indian Freestanding' },
  { value: 'indian_provider', viewValue: 'Indian Provider' },
  { value: 'inpatient_hospital', viewValue: 'Inpatient Hospital' },
  { value: 'inpatient_psych', viewValue: 'Inpatient Psych' },
  { value: 'inpatient_rehab', viewValue: 'Inpatient Rehab' },
  { value: 'mental_health_center', viewValue: 'Mental Health Center' },
  { value: 'mentally_retarded', viewValue: 'Mentally Retarded' },
  { value: 'military', viewValue: 'Military' },
  { value: 'mobile_unit', viewValue: 'Mobile Unit' },
  { value: 'nursing', viewValue: 'Nursing' },
  {
    value: 'off_campus_outpatient_hospital',
    viewValue: 'Off Campus Outpatient Hospital',
  },
  { value: 'office', viewValue: 'Office' },
  {
    value: 'on_campus_outpatient_hospital',
    viewValue: 'On Campus Outpatient Hospital',
  },
  { value: 'other', viewValue: 'Other' },
  { value: 'outpatient_hospital', viewValue: 'Outpatient Hospital' },
  { value: 'outpatient_rehab', viewValue: 'Outpatient Rehab' },
  { value: 'pharmacy', viewValue: 'Pharmacy' },
  { value: 'prison', viewValue: 'Prison' },
  { value: 'psych_partial_hospital', viewValue: 'Psych Partial Hospital' },
  { value: 'psych_residential', viewValue: 'Psych Residential' },
  { value: 'public_clinic', viewValue: 'Public Clinic' },
  {
    value: 'residential_substance_abuse',
    viewValue: 'Residential Substance Abuse',
  },
  { value: 'rural_clinic', viewValue: 'Rural Clinic' },
  { value: 'school', viewValue: 'School' },
  { value: 'shelter', viewValue: 'Shelter' },
  { value: 'skilled_nursing', viewValue: 'Skilled Nursing' },
  { value: 'surgical_center', viewValue: 'Surgical Center' },
  { value: 'telehealth', viewValue: 'Telehealth' },
  { value: 'temp_lodging', viewValue: 'Temp Lodging' },
  { value: 'tribal_638_freestanding', viewValue: 'Tribal 638 Freestanding' },
  { value: 'tribal_638_provider', viewValue: 'Tribal 638 Provider' },
  { value: 'urgent_care', viewValue: 'Urgent Care' },
  { value: 'walkin_clinic', viewValue: 'Walkin Clinic' },
  { value: 'worksite', viewValue: 'Worksite' },
]

import { HttpParams } from '@angular/common/http'
import { EventEmitter, Injectable } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { Clinician, Consent, ConsentData, IQuestionMap, ISection, PatientSexes, RestResponse } from '@mmx/shared'
import {
  ApiService,
  AppointmentModel,
  ClinicService,
  Eligibility,
  Intake,
  IntakeModel,
} from 'app/core'
import { AuthenticatedRestService } from 'app/core/services/auth-rest.service'
import { IntakeSection } from 'app/shared'
import { IImageCacheItem, ImageCache } from 'app/shared/utils/image-cache'
import { find, map as _map, orderBy, sortBy } from 'lodash'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { map } from 'rxjs/operators'

import { PatientIntakeFormData } from '../../patient-intake/classes/intake-form-data.class'

@Injectable({
  providedIn: 'root',
})
export class IntakeService {
  intakeId: string
  appointmentId: string
  isQuickEditing = false

  // TODO load results from the server
  data: PatientIntakeFormData
  imageCache = new ImageCache()
  imageCacheInitializationPromise: Promise<ImageCache>
  onImageCacheLoadedSubject = new Subject<ImageCache>()

  // Observables to return
  onChangeSubject = new Subject<void>()
  onChange = this.onChangeSubject.asObservable()
  onIntakeChange = new BehaviorSubject<IntakeModel>(null)
  onFileUpload = new BehaviorSubject<string>(null)
  onSaveAll = new BehaviorSubject<boolean>(false)

  onNext = new EventEmitter<boolean>()
  onPrev = new EventEmitter<boolean>()
  acceptedTerms: EventEmitter<boolean> = new EventEmitter()

  clinicians: Clinician[]
  consents: Consent[]
  intake: IntakeModel
  eligibility?: Eligibility
  appointment: AppointmentModel
  sections: IntakeSection[] = []

  insuranceError: Observable<string>
  insuranceErrorObserver: any

  constructor(
    private apiService: ApiService,
    private clinicService: ClinicService,
    private restService: AuthenticatedRestService,
  ) {
    this.insuranceError = new Observable((observer) => {
      this.insuranceErrorObserver = observer
    })
    this.onIntakeChange.subscribe((intake) => {
      if (intake) {
        this.intake = intake
      }
    })
  }

  saveToImageCache(id: string, url: string) {
    this.imageCache.set(id, { url })
  }

  reset() {
    this.data = new PatientIntakeFormData()
  }

  setInsuranceError(err: string) {
    this.insuranceErrorObserver.next(err)
  }

  getErrorMessage(controlName: string, formGroup: any): string {
    const control: UntypedFormControl = controlName
      ? formGroup.controls[controlName]
      : formGroup.controls['value']

    if (!control) {
      return ''
    }

    if (control.hasError('email')) {
      return 'patient-intake.custom-question.error.email'
    } else if (control.hasError('oneOf')) {
      return 'patient-intake.custom-question.error.oneOf'
    } else if (
      control.hasError('phone') ||
      control.hasError('validatePhoneNumber')
    ) {
      return 'patient-intake.custom-question.error.phone'
    } else if (controlName === 'zip' && control.hasError('pattern')) {
      return 'patient-intake.custom-question.error.zip'
    } else if (controlName === 'city' && control.hasError('pattern')) {
      return 'patient-intake.custom-question.error.city'
    } else if (control.hasError('min')) {
      return 'patient-intake.custom-question.error.min'
    } else if (control.hasError('max')) {
      return 'patient-intake.custom-question.error.max'
    } else if (control.hasError('minDate')) {
      return 'patient-intake.custom-question.error.minDate'
    } else if (control.hasError('maxDate')) {
      return 'patient-intake.custom-question.error.maxDate'
    } else if (control.hasError('ssn')) {
      return 'patient-intake.custom-question.error.ssn'
    } else if (control.hasError('required')) {
      return 'patient-intake.custom-question.error.required'
    }
  }

  async saveFiles(): Promise<string[] | void> {
    const uploadPromises: Promise<string>[] = []
    let id = ''
    this.imageCache.data.forEach(
      (item: IImageCacheItem, questionId: string) => {
        if (!item.isUploaded) {
          const uploadPromise = this.apiService
            .uploadPatientFiles(this.intake.patientId, [
              {
                fileName: 'file-' + questionId,
                intakeId: this.intakeId,
                intakeFile: questionId,
                content: item.url,
              },
            ])
            .toPromise()
            .then((res) => {
              const url = res.data as string
              item.isUploaded = true
              id = questionId
              return url
            })
          uploadPromises.push(uploadPromise)
        }
      },
    )

    const result = await Promise.all(uploadPromises)
      .then((urls) => urls)
      .catch(() => {})

    if (
      this.intake.isConfirmed ||
      this.intake.stage === Intake.STAGE.TECH_REVIEW
    ) {
      this.onFileUpload.next(id)
    }

    return result
  }

  load(intakeId: string): Promise<void> {
    this.intake = null
    this.data = new PatientIntakeFormData()

    this.imageCache.clear()
    return new Promise((resolve, reject) => {
      const route = '/intakes/' + intakeId
      this.restService.get<any>(route).subscribe(
        async (res) => {
          this.intakeId = intakeId
          this.appointmentId = res.data.appointmentId
          this.intake = new IntakeModel(res.data)
          this.data.fromJson(res.data)
          this.isQuickEditing = false
          this.imageCacheInitializationPromise = this.tryRebuildImageCache()

          await this.getPatientAppointment()
          await this.getPatientEligibility()
          await this.getConsents()

          this.onIntakeChange.next(this.intake)
          resolve()
        },
        (err) => {
          reject(err)
        },
      )
    })
  }

  getQuestions(params?: {
    appointment?: string
    includeDeleted?: boolean
    includeDisabled?: boolean
    includeHidden?: boolean
    sex?: PatientSexes | PatientSexes[]
    intake?: string
  }): Observable<RestResponse<IQuestionMap>> {
    const httpParams = new HttpParams({ fromObject: params as any })
    const route = `/clinics/${this.clinicService.clinicId}/questions`
    return this.restService.get<IQuestionMap>(route, httpParams)
  }

  getIntakeSections(input?: {
    appointment?: string
    intake?: string
    includeHidden?: boolean | 'Y'
    includePdfConfig?: boolean
  }): Observable<RestResponse<ISection[]>> {
    const params = this.restService.convertToParams(input)
    const route = `/clinics/${this.clinicService.clinicId}/sections`
    return this.restService.get<ISection[]>(route, params)
  }

  getConsents(input?: {
    type?: string
    intake?: string
    appointment?: string
    excludeRecentlyConsented?: boolean,
    includeIntakeConsented?: boolean,
  }): Observable<Consent[]> {
    if (this.intake) {
      input = {
        intake: this.intake.id,
        appointment: this.appointmentId,
        excludeRecentlyConsented: true,
        includeIntakeConsented: true,
      }
    }

    const params = this.restService.convertToParams(input)
    const route = `/clinics/${this.clinicService.clinicId}/consents`

    return this.restService.get<ConsentData[]>(route, params).pipe(
      map((resp) => {
        if (resp.success) {
          return sortBy(
            _map(resp.data, (consentData) => new Consent(consentData)),
            'order',
          )
        } else {
          return []
        }
      }),
    )
  }

  private getPatientAppointment(): Promise<void> {
    if (this.intake) {
      return new Promise((resolve) => {
        this.apiService
          .getAppointment(this.appointmentId)
          .subscribe((appointment) => {
            this.appointment = appointment
            resolve()
          })
      })
    }
  }

  private getPatientEligibility(): Promise<void> {
    if (this.intake) {
      return new Promise((resolve) => {
        this.apiService
          .getPatientEligibilities(this.intake.patientId)
          .subscribe((eligibilities) => {
            // Select eligibility for current appointment
            this.eligibility = find(
              eligibilities,
              (e) => e.id === this.appointment.eligibilityPrimaryId,
            )
            resolve()
          })
      })
    }
  }

  private async tryRebuildImageCache(): Promise<ImageCache> {
    if (this.intake) {
      return new Promise((resolve) => {
        this.apiService
          .getPatientUploadedFiles(this.intake.patientId, this.intakeId)
          .subscribe({
            next: (resp) => {
              // we sort to force the current intake ID to the bottom of the files, this ensures files
              // uploaded for this intake takes priority
              const files = orderBy(
                resp.data,
                (f) => f.intakeId === this.intakeId,
              )
              files.forEach((photoId) => {
                const key = photoId.baseName
                if (!this.imageCache.get(key)) {
                  this.imageCache.set(key, {
                    url: photoId.signedUrl,
                    isUploaded: true,
                  })
                }
              })
              resolve(this.imageCache)
            },
            error: () => {
              resolve(this.imageCache)
            },
          })
      })
    }
  }
}

import { SelectOption } from '@mmx/shared'

export enum PortalRuleType {
  AUTHORIZATION = 'authorization',
  ELIGIBILITY = 'eligibility',
}

export const PortalRuleTypes: SelectOption<string>[] = [
  {
    value: PortalRuleType.AUTHORIZATION,
    viewValue: 'portal.rule.type.authorization',
  },
  {
    value: PortalRuleType.ELIGIBILITY,
    viewValue: 'portal.rule.type.eligibility',
  },
]

import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'

import { AppConfirmComponent } from './app-confirm.component'

interface ConfirmData {
  message: string
  html?: boolean
  icon?: string
  iconColor?: 'primary' | 'warn' | 'accent'
  yesBtnLabel?: string
  noBtnLabel?: string
  hideNoButton?: boolean
}

@Injectable()
export class AppConfirmService {
  constructor(private dialog: MatDialog) { }

  public confirm(data: string | ConfirmData = 'common.are-you-sure'): Observable<boolean> {
    if (typeof data === 'string') {
      data = { message: data }
    }

    data.yesBtnLabel = data.yesBtnLabel || 'common.yes'
    data.noBtnLabel = data.noBtnLabel || 'common.no'
    data.html = Boolean(data.html)
    data.hideNoButton = Boolean(data.hideNoButton)
    const dialogRef: MatDialogRef<AppConfirmComponent> = this.dialog.open(AppConfirmComponent, {
      width: '380px',
      disableClose: true,
      data,
      panelClass: 'dialog-form',
    })
    return dialogRef.afterClosed()
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { RestService } from '@mmx/shared'
import { concatMap } from 'rxjs/operators'

import { AuthResponse, AuthService } from './auth.service'
import { ClinicService } from './clinic.service'

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedRestService extends RestService {
  constructor(
    protected http: HttpClient,
    protected clinicService: ClinicService,
    protected authService: AuthService,
  ) {
    super(http)
  }

  checkAuth(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (
        this.authService.hasSessionToken() &&
        !this.authService.isUserLoggedIn()
      ) {
        this.clinicService
          .clinicId$
          .pipe(concatMap(() => this.get<AuthResponse>('/auth')))
          .subscribe({
            next: (result) => {
              this.authService.updateFromAuthRequest(result.data)
              resolve(this.authService.checkAuthorization())
            },
            error: (err) => {
              // reset the session token if the token was invalid
              this.authService.sessionToken = ''
              reject(err)
            },
          })
      } else {
        resolve(this.authService.checkAuthorization())
      }
    })
  }

  getUser() {
    return this.authService.changes
  }

  protected getDefaultHeaders(headers?: HttpHeaders): HttpHeaders {
    headers = super.getDefaultHeaders(headers)

    if (this.authService.sessionToken) {
      headers = headers.append('Authorization', this.authService.sessionToken)
    }

    if (this.clinicService.clinic) {
      headers = headers.append('WWW-Clinic-ID', this.clinicService.clinicId)
    }

    return headers
  }
}

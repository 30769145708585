// convert phone number to standard format: +12345678901
export function standardizePhone(phoneNumber?: string): string {
  const stripped = (phoneNumber || '').replace(/[^0-9]+/g, '')

  if (stripped.length === 10) {
    return '+1' + stripped
  } else if (stripped.length > 10) {
    return '+' + stripped
  } else {
    return stripped
  }
}

export function stripPhone(phoneNumber: string): string {
  let stripped = phoneNumber
  if (stripped.trim().startsWith('+1')) {
    stripped = stripped.slice(2)
  }

  return stripped.replace(/[^0-9]+/g, '')
}

export function formatPhoneNumber(
  phoneNumber: string,
  format: '(111) 222-3333' | '111-222-3333' = '(111) 222-3333',
) {
  if (typeof phoneNumber === 'string') {
    const s = phoneNumber.replace(/\D/g, '')

    if (s.length === 11 && phoneNumber.startsWith('+1')) {
      const m = s.match(/^\d(\d{3})(\d{3})(\d{4})$/)

      if (m) {
        switch (format) {
          case '(111) 222-3333':
            return `(${m[1]}) ${m[2]}-${m[3]}`
          case '111-222-3333':
            return `${m[1]}-${m[2]}-${m[3]}`
        }
      }
    } else if (s.length === 12) {
      // example: +52 999 925 1544
      const m = s.match(/^\d\d(\d{3})(\d{3})(\d{4})$/)

      if (m) {
        switch (format) {
          case '(111) 222-3333':
            return `(${m[1]}) ${m[2]}-${m[3]}`
          case '111-222-3333':
            return `${m[1]}-${m[2]}-${m[3]}`
        }
      }
    }
  }

  return phoneNumber
}

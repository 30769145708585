import { SelectOption } from '@mmx/shared'

import { Appointment } from '../models/appointment.model'

export const AppointmentStatuses: SelectOption<Appointment.STATUS>[] = [
  {
    value: Appointment.STATUS.OPEN,
    viewValue: 'statuses.appointments.open',
  },
  {
    value: Appointment.STATUS.HOLD,
    viewValue: 'statuses.appointments.hold',
  },
  {
    value: Appointment.STATUS.ARRIVED,
    viewValue: 'statuses.appointments.patient-arrived',
  },
  {
    value: Appointment.STATUS.CHECKING_IN,
    viewValue: 'statuses.appointments.patient-checking-in',
  },
  {
    value: Appointment.STATUS.CHECKED_IN,
    viewValue:
      'statuses.appointments.patient-checking-in-awaiting',
  },
  {
    value: Appointment.STATUS.IN_PROGRESS,
    viewValue: 'statuses.appointments.service-in-progress',
  },
  {
    value: Appointment.STATUS.COMPLETED,
    viewValue: 'statuses.appointments.service-completed',
  },
  {
    value: Appointment.STATUS.CANCELLED,
    viewValue: 'statuses.appointments.cancelled',
  },
  {
    value: Appointment.STATUS.RESCHEDULE,
    viewValue: 'statuses.appointments.needs-rescheduling',
  },
  {
    value: Appointment.STATUS.ABORTED,
    viewValue: 'statuses.appointments.aborted',
  },
  {
    value: Appointment.STATUS.NO_SHOW,
    viewValue: 'statuses.appointments.no-show',
  },
  {
    value: Appointment.STATUS.RESULTS_READY,
    viewValue: 'statuses.appointments.results-ready',
  },
]

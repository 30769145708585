import { QuestionType } from '../model'

export const isStopQuestionType = (type: QuestionType) => {
  const StopQuestionTypes = [
    QuestionType.Boolean,
    QuestionType.Checkbox,
    QuestionType.Dropdown,
    QuestionType.Radio,
  ]

  return StopQuestionTypes.includes(type)
}

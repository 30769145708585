import { AbstractControl } from '@angular/forms'

export function domainValidator(control: AbstractControl) {
  if (control.value && control.value.length) {
    const domain = control.value
    const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
    const forbidden = !domainRegex.test(domain)

    return forbidden ? { invalidDomain: true } : null
  }

  return null
}
